import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { Country } from "../interfaces/Country";
import { CountryService } from "../services/CountryService";

@Injectable()
export class CountryListResolver implements Resolve<Country[]> {

  constructor(
    private countryService: CountryService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Country[] | Observable<Country[]> | Promise<Country[]> {
    const { countryService } = this;
    return countryService.all(true);
  }

}
