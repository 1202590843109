import { Injectable } from "@angular/core";
import { from, Observable, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { LoginResponse } from "../interfaces/Response";
import { TwoFADialogService } from "./dialog/TwoFADialogService";

declare type ActionCallback = (args: LoginResponse) => Observable<any>;

interface AccountActions {
  [key: string]: ActionCallback[];
}

const AccountActionMap: AccountActions = {};

@Injectable({
  providedIn: 'root'
})
export class AccountActionService {

  constructor(
    private twoFADialogService: TwoFADialogService
  ) {
    this.initialize();
  }

  private initialize() {
    const { twoFADialogService } = this;

    this.add('login', (resp: LoginResponse) => {
      if ('2FA' in resp.actions && resp.actions['2FA'] === true) {
        return twoFADialogService.authorize(resp.aplc);
      }

      return of(null);
    });
  }

  add(name: string, callback: ActionCallback) {
    if (!(name in AccountActionMap)) {
      AccountActionMap[name] = [];
    }

    const length = AccountActionMap[name].push(callback);

    return length - 1;
  }

  remove(name: string, index: number) {
    if (name in AccountActionMap) {
      return AccountActionMap[name].splice(index, 1);
    }

    return false;
  }

  run(name: string, arg: LoginResponse) {
    let result = of(arg);

    if (name in AccountActionMap) {
      for (const callback of AccountActionMap[name]) {
        result = result.pipe(
          switchMap(resp => callback(arg)),
          map(resp => arg)
        );
      }
    }

    return result;
  }

}
