import { Directive, Input } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from "@angular/forms";

@Directive({
  selector: '[username]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: UsernameValidationDirective,
      multi: true
    }
  ]
})
export class UsernameValidationDirective implements Validator {

  constructor() {}

  validate(control: AbstractControl): ValidationErrors {
    const error = { username: true };

    const value = control.value;
    if (!value) return null as any;

    if (typeof (value) !== 'string') return error;

    const lower = value.toLowerCase();
    if (value !== lower) return error;

    const expr = /^[a-z0-9]+$/;
    if (!expr.test(value)) return error;

    return null as any;
  }

}
