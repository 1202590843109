import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Observable, of, throwError } from "rxjs";
import { catchError, map, shareReplay, switchMap, tap } from "rxjs/operators";
import { ShippingAddDialog } from "../../dialog/shipping/add/add.dialog";
import { ShippingLabelsDialog } from "../../dialog/shipping/labels/labels.dialog";
import { Account } from "../../interfaces/Account";
import { Shipping, ShippingDialogOptions, ShippingLabelsDialogOptions } from "../../interfaces/Shipping";
import { InvoiceDialogService } from "./InvoiceDialogService";
import { InvoiceService } from "../InvoiceService";
import { LoadingService } from "../LoadingService";
import { MessageService } from "../MessageService";
import { ShippingService } from "../ShippingService";

@Injectable()
export class ShippingDialogService {

  constructor(
    private dialog: MatDialog,
    private loadingService: LoadingService,
    private messageService: MessageService,
    private shippingService: ShippingService,
    private invoiceService: InvoiceService,
    private invoiceDialogService: InvoiceDialogService
  ) {  }

  add(options?: ShippingDialogOptions): Observable<Shipping> {
    const dialog = this.dialog.open(ShippingAddDialog, {
      width: '90vw',
      maxWidth: '1200px',
      disableClose: true,
      data: options || {}
    });

    return dialog.afterClosed();

    const { shippingService, invoiceDialogService } = this;

    let value: Shipping = null as any;

    const result = dialog.afterClosed().pipe(
      tap((resp: Shipping) => value = resp || null),

      switchMap(resp => {
        if (!resp) return of(null);
        return invoiceDialogService.openForShipping(resp.id as any);
      }),

      switchMap(resp => {
        if (!value) return of(value);
        return this.labels({ id: value.id as number });
      }),

      map(resp => value),

      shareReplay(1)
    );

    result.subscribe();

    return result;
  }

  labels(data: ShippingLabelsDialogOptions): Observable<Shipping> {
    const { loadingService, shippingService, messageService } = this;

    const result = of(data).pipe(
      switchMap(resp => {
        loadingService.start();

        return shippingService.getLabels(resp.id as number).pipe(
          tap(resp => {
            data.shipping = resp;
            loadingService.stop();
          }),

          switchMap(resp => {
            if (!resp.labels) return throwError('No label found.');
            return of(resp);
          })
        );
      }),

      switchMap(resp => {
        const dialog = this.dialog.open(ShippingLabelsDialog, {
          width: '90vw',
          maxWidth: '700px',
          disableClose: true,
          data
        });

        return dialog.afterClosed();
      }),

      catchError(error => {
        messageService.handle(error);
        return of(null as any);
      }),

      shareReplay(1)
    );

    result.subscribe();

    return result;
  }

  log() {
    //
  }

}
