import { HttpClient } from "@angular/common/http";
import { Inject, Injectable, inject } from "@angular/core";
import { URLHelper } from "../helpers/URLHelper";
import { tap } from "rxjs";


@Injectable()
export class WalletEntryService {

  private readonly http = inject(HttpClient);
  private readonly urlHelper = inject(URLHelper);

  get() {
    const { urlHelper } = this;
    return urlHelper.endpoint(`/wallet/entries`);
  }

}
