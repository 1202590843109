<agc-container [loading]="loading">
  <p>Two-Factor Authentication (2FA) adds an extra layer of security to your account, making it extremely difficult for intruders to access your account.</p>

  <div class="text-center" *ngIf="loading">
    <p>Just a moment please</p>
  </div>

  <div class="form-group" *ngIf="!loading">
    <div class="text-left">
      <label for="" class="text-bold">Status</label>
      <span>&nbsp;&nbsp;</span>
      <span class="text-success" *ngIf="enabled">Active</span>
      <span class="text-danger" *ngIf="!enabled">Inactive</span>
    </div>

    <div class="text-center">
      <button class="btn btn-danger btn-sm" *ngIf="enabled" (click)="disable()">Disable 2FA</button>
      <span *ngIf="enabled">&nbsp;&nbsp;&nbsp;&nbsp;</span>
      <button class="btn btn-primary btn-sm" (click)="manage()">{{ (enabled) ? 'Manage' : 'Enable' }} 2FA</button>
    </div>
  </div>
</agc-container>
