<agc-container dialog [loading]="loading">
  <h1 mat-dialog-title>
    <span>{{ title }}</span>

    <small *ngIf="subtitle">
      &nbsp;
      <i class="icofont icofont-double-right"></i>
      &nbsp;
      <span>{{ subtitle }}</span>
    </small>
  </h1>

  <mat-dialog-content class="mat-typography">
    <agc-pagination [data]="records" (callback)="fetch($event)">
      <div class="text-center" *ngIf="!records?.total">No state/province found</div>

      <table class="table table-striped table-hover table-text" *ngIf="records?.total">
        <thead>
          <tr class="footable-header">
            <th>ID</th>
            <th>Name</th>
            <th *ngIf="toggle">Enabled</th>
            <th>Created</th>
            <th>Updated</th>
            <th *ngIf="actions?.length"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of records.data">
            <td>{{ data.id }}</td>
            <td>{{ data.name }}</td>
            <td *ngIf="toggle">
              <agc-switch [value]="data.isEnabled" (click)="handleToggle($event, data)"></agc-switch>
            </td>
            <td>{{ data.createdAt | date:'short' }}</td>
            <td>{{ data.updatedAt | date:'short' }}</td>
            <td class="text-right" *ngIf="actions?.length">
              <a href="javascript:;" class="label" [ngClass]="action.cssClass" (click)="action.callback($event, data, dialogRef)" *ngFor="let action of actions">
                <i [ngClass]="action.icon" *ngIf="action.icon"></i>
                <span *ngIf="action.text">&nbsp; {{ action.text }}</span>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </agc-pagination>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button class="btn btn-default btn-md waves-effect waves-light" (click)="cancel()">Close</button>
  </mat-dialog-actions>
</agc-container>
