<agc-container dialog [loading]="loading">
  <h1 mat-dialog-title>{{ (role) ? 'EDIT' : 'ADD NEW' }} ROLE</h1>

  <mat-dialog-content class="mat-typography">
    <agc-form [formGroup]="form" (submit)="submit()">
      <div class="row">
        <div class="col-md-6">
          <agc-form-field>
            <label for="">Name <span class="text-danger">*</span></label>
            <input type="text" formControlName="name" class="form-control" required>
            <agc-message when="required">Role Name is required.</agc-message>
          </agc-form-field>

          <agc-form-field>
            <label for="">Description <span class="text-danger">*</span></label>
            <textarea formControlName="description" class="form-control description" required></textarea>
            <agc-message when="required">Role Description is required.</agc-message>
          </agc-form-field>

          <agc-form-field>
            <label for="restricted">
              <input type="checkbox" id="restricted" formControlName="isRestricted">
              &nbsp;&nbsp;
              <span>Restricted Access</span>
            </label>
            <div class="text-warning" *ngIf="!isRestricted">
              <span>This role would have the same permissions as the Super Administrator. Sure you want to do this?</span>
            </div>
          </agc-form-field>
        </div>

        <div class="col-md-6" formGroupName="permissions">
          <h3>Permissions <small *ngIf="!isRestricted">(disabled)</small></h3>

          <fieldset [disabled]="!isRestricted">
            <div *ngFor="let item of data.permissions">
              <label [for]="item.alias">
                <input type="checkbox" [id]="item.alias" [formControlName]="item.alias">
                &nbsp;&nbsp;
                <span>{{ item.name }}</span>
              </label>
            </div>
          </fieldset>
        </div>
      </div>
    </agc-form>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button class="btn btn-default btn-md waves-effect waves-light" (click)="cancel()">Cancel</button>
    <button cdkFocusInitial class="btn btn-primary btn-md ml-2 waves-effect waves-light" (click)="formRef?.doSubmit()">Submit</button>
  </mat-dialog-actions>
</agc-container>
