import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { MenuService } from "../services/MenuService";

@Injectable()
export class CanActivateMenu implements CanActivateChild {

  constructor(
    private router: Router,
    private menuService: MenuService
  ) {}

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    const { router, menuService } = this;

    return menuService.all().pipe(
      map(resp => true)
    );
  }

}
