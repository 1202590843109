import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { map, share, shareReplay } from "rxjs/operators";
import { URLHelper } from "../helpers/URLHelper";
import { AGCModuleConfig } from "../interfaces/AGCModuleConfig";
import { Country, CountryPagination } from "../interfaces/Country";
import { StatePagination } from "../interfaces/State";

@Injectable()
export class CountryService {

  private xAllCountries: Observable<Country[]> = null as any;

  private xCountryList: Country[] = [];

  private xCountry: Observable<Country>;
  private xCountrySub: BehaviorSubject<Country>;

  get country() { return this.xCountry; }

  constructor(
    private http: HttpClient,
    private urlHelper: URLHelper
  ) {
    this.xCountrySub = new BehaviorSubject<Country>(null as any);
    this.xCountry = this.xCountrySub.asObservable();
  }

  setCountry(value: Country | null) {
    this.xCountrySub.next(value as any);
  }

  all(refresh: boolean = false) {
    const { xCountryList } = this;

    if (!xCountryList.length || refresh) {
      const url = this.urlHelper.url(`/countries`);

      return this.http.get<Country[]>(url).pipe(
        map(resp => {
          this.xCountryList = resp;
          return resp;
        })
      );
    }

    return of(xCountryList);
  }

  getAllEnabled() {
    const url = this.urlHelper.url(`/countries`);
    return this.http.get<Country[]>(url);
  }

  getAll() {
    if (!this.xAllCountries) {
      const url = this.urlHelper.endpoint(`/countries/all`);

      this.xAllCountries = this.http.get<Country[]>(url).pipe(
        shareReplay(1)
      );
    }

    return this.xAllCountries;
  }

  search(page: number = 1) {
    const params = new URLSearchParams();
    params.append('page', page.toString());
    const url = this.urlHelper.endpoint(`/countries?${params.toString()}`);
    return this.http.get<CountryPagination>(url);
  }

  toggle(id: number) {
    const url = this.urlHelper.endpoint(`/countries/toggle`);
    return this.http.post<Country>(url, { id });
  }

  find(id: number | string) {
    const url = this.urlHelper.endpoint(`/countries/${id}`);
    return this.http.get<Country>(url);
  }

  add(data: Country) {
    const url = this.urlHelper.endpoint(`/countries`);
    return this.http.post<Country>(url, data);
  }

  update(id: number | string, data: Country) {
    const url = this.urlHelper.endpoint(`/countries/${id}`);
    return this.http.patch<Country>(url, data);
  }

  getStates(countryId: number) {
    const url = this.urlHelper.endpoint(`/countries/${countryId}/states`);
    return this.http.get<StatePagination>(url);
  }

}
