import { Component } from "@angular/core";

@Component({
  selector: 'app-dialog-transaction-view',
  templateUrl: 'view.dialog.html',
  styleUrls: ['view.dialog.scss']
})
export class TransactionViewDialog {

  constructor() { }

}
