import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { TwoFAManageDialog } from "../../dialog/2fa/manage/manage.dialog";
import { TwoFAAuthorizeDialog } from "../../dialog/2fa/authorize/authorize.dialog";

@Injectable({
  providedIn: 'root'
})
export class TwoFADialogService {

  constructor(
    private dialog: MatDialog
  ) {}

  manage() {
    const dialog = this.dialog.open(TwoFAManageDialog, {
      width: '90vw',
      maxWidth: '1000px',
      disableClose: true
    });

    return dialog.afterClosed();
  }

  authorize(aplc: string) {
    const dialog = this.dialog.open(TwoFAAuthorizeDialog, {
      width: '90vw',
      maxWidth: '450px',
      disableClose: true,
      data: { aplc }
    });

    return dialog.afterClosed();
  }

}
