import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, of, Subscriber, Subscription } from "rxjs";
import { URLHelper } from "../helpers/URLHelper";
import { BaseMenu, LinkMenuMap, Menu, MenuAttribute } from "../interfaces/Menu";
import { finalize, share, shareReplay, tap } from "rxjs/operators";


@Injectable({
  providedIn: 'root'
})
export class MenuService {

  private xMenuSub: BehaviorSubject<Menu[]>;
  readonly menuList: Observable<Menu[]>;

  private xMap: LinkMenuMap = {};
  get map() { return this.xMap; }

  private xMenuAll: Observable<Menu[]> = null as any;

  constructor(
    private http: HttpClient,
    private urlHelper: URLHelper
  ) {
    this.xMenuSub = new BehaviorSubject<Menu[]>([]);
    this.menuList = this.xMenuSub.asObservable();
  }

  clear() {
    this.xMenuSub.next([]);
    this.xMap = {};
  }

  private remap(menuList: Menu[]) {
    const map: LinkMenuMap = {};

    for (let menu of menuList) {
      const permission = menu.permission || '';

      const attr: MenuAttribute = {
        parent: true,
        permission: permission
      };

      map[menu.link] = attr;

      if (menu.children) {
        for (let submenu of menu.children) {
          const attr: MenuAttribute = {
            parent: false,
            permission: submenu.permission || permission,
          };

          map[submenu.link] = attr;
        }
      }
    }

    return map;
  }

  all() {
    const { xMenuSub } = this;

    if (!this.xMenuAll) {
      const url = this.urlHelper.endpoint(`/menu`);

      this.xMenuAll = this.http.get<Menu[]>(url).pipe(
        tap(resp => this.xMap = this.remap(resp)),
        tap(resp => xMenuSub.next(resp)),
        share()
      );
    }

    return this.xMenuAll;
  }

}
