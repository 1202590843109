import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Observable, of, throwError } from "rxjs";
import { catchError, share, switchMap, tap } from "rxjs/operators";
import { AdminDialog } from "../../dialog/admins/admin.dialog";
import { Admin, AdminDialogOptions } from "../../interfaces/Admin";
import { LoadingService } from "../LoadingService";
import { MessageService } from "../MessageService";
import { RoleService } from "../RoleService";

@Injectable()
export class AdminDialogService {

  constructor(
    private dialog: MatDialog,
    private loadingService: LoadingService,
    private messageService: MessageService,
    private roleService: RoleService
  ) {  }

  add(admin: Admin = null as any): Observable<Admin> {
    const { loadingService, messageService, roleService } = this;
    const data: AdminDialogOptions = { admin } as any;

    loadingService.start();

    const result = of(admin).pipe(
      switchMap(resp => roleService.all()),

      tap(resp => {
        loadingService.stop();
        data.roles = resp;
      }),

      catchError(error => {
        loadingService.stop();
        return throwError(error);
      }),

      switchMap(resp => {
        const dialog = this.dialog.open(AdminDialog, {
          width: '90vw',
          maxWidth: '700px',
          disableClose: true,
          data
        });

        return dialog.afterClosed();
      }),

      share()
    );

    result.subscribe();

    return result;
  }

}
