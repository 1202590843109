import { AfterViewInit, ChangeDetectorRef, Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Country } from "../../interfaces/Country";
import { State, StateListDialogOptions, StatePagination } from "../../interfaces/State";
import { LoadingService } from "../../services/LoadingService";
import { MessageService } from "../../services/MessageService";
import { StateService } from "../../services/StateService";

@Component({
  selector: 'app-dialog-states',
  templateUrl: 'states.dialog.html',
  styleUrls: ['states.dialog.scss']
})
export class StateListDialog implements AfterViewInit {

  private xLoading: boolean = false;
  get loading() { return this.xLoading; }

  set loading(value: boolean) {
    this.xLoading = value;
    this.cd.detectChanges();
  }

  private xRecords: StatePagination = null as any;
  get records() { return this.xRecords; }

  get title() { return this.data.title; }
  get subtitle() { return this.data.subtitle; }
  get toggle() { return this.data.toggle; }
  get country() { return this.data.country; }
  get actions() { return this.data.actions; }

  get dialogRef() { return this.xDialogRef; }

  constructor(
    private cd: ChangeDetectorRef,
    private loadingService: LoadingService,
    private messageService: MessageService,
    private stateService: StateService,
    private xDialogRef: MatDialogRef<StateListDialog>,
    @Inject(MAT_DIALOG_DATA) private data: StateListDialogOptions
  ) {  }

  ngAfterViewInit() {
    this.fetch();
  }

  cancel() {
    this.dialogRef.close();
  }

  fetch(page: number = 1) {
    const { country, loadingService,  stateService, messageService } = this;

    if (!country) return;

    this.loading = true;

    stateService.search(<string>country.code, page).subscribe({
      next: resp => {
        this.xRecords = resp;
      },

      error: error => {
        messageService.handle(error, false);
        this.loading = false;
      },

      complete: () => {
        this.loading = false;
      }
    });
  }

  handleToggle(e: Event, data: State) {
    e.preventDefault();
    e.stopPropagation();

    const { stateService, loadingService, messageService } = this;

    loadingService.start();

    stateService.toggle(<number>data.id).subscribe({
      next: resp => {
        Object.assign(data, resp);
      },

      error: messageService.handle,

      complete: () => {
        loadingService.stop();
      }
    });
  }

}
