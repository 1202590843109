<div class="wrapper">
  <div class="type">
    <div class="row">
      <div class="col-sm-auto xlabel">
        <label for="">Target:</label>
      </div>

      <div class="col ans">
        <label for="rcp-type-admin">
          <input type="radio" name="target" value="ADMIN" [(ngModel)]="target" (ngModelChange)="handleValueChange()" id="rcp-type-admin" required>
          <span>Admins</span>
        </label>

        <label for="rcp-type-agent">
          <input type="radio" name="target" value="AGENT" [(ngModel)]="target" (ngModelChange)="handleValueChange()" id="rcp-type-agent" required>
          <span>Agents</span>
        </label>

        <label for="rcp-type-business">
          <input type="radio" name="target" value="BUSINESS" [(ngModel)]="target" (ngModelChange)="handleValueChange()" id="rcp-type-business" required>
          <span>Businesses</span>
        </label>

        <label for="rcp-type-user">
          <input type="radio" name="target" value="USER" [(ngModel)]="target" (ngModelChange)="handleValueChange()" id="rcp-type-user" required>
          <span>Customers</span>
        </label>
      </div>
    </div>
  </div>

  <div class="account">
    <div class="row">
      <div class="col-sm-auto xlabel">
        <label for="">Status:</label>
      </div>

      <div class="col ans">
        <label for="rcp-to-all">
          <input type="radio" name="status" value="ALL" [(ngModel)]="status" (ngModelChange)="handleValueChange()" id="rcp-to-all" required>
          <span>All {{ name }}</span>
        </label>

        <label for="rcp-to-active">
          <input type="radio" name="status" value="ACTIVE" [(ngModel)]="status" (ngModelChange)="handleValueChange()" id="rcp-to-active" required>
          <span>All <span class="text-success">Active</span> {{ name }}</span>
        </label>

        <label for="rcp-to-inactive">
          <input type="radio" name="status" value="INACTIVE" [(ngModel)]="status" (ngModelChange)="handleValueChange()" id="rcp-to-inactive" required>
          <span>All <span class="text-danger">Inactive</span> {{ name }}</span>
        </label>
      </div>
    </div>
  </div>

  <div class="location">
    <div class="row">
      <div class="col-sm-auto xlabel">
        <label for="">Location:</label>
      </div>

      <div class="col ans">
        <div>
          <label for="rcp-to-location">
            <input type="checkbox" name="location" value="LOCATION" [(ngModel)]="location" (ngModelChange)="handleValueChange()" id="rcp-to-location" required>
            <span>Target {{ name }} by location</span>
          </label>
        </div>

        <div class="row" style="margin-top: 15px;" *ngIf="location">
          <div class="col-sm-4 loc">
            <agc-country-input [ngModel]="country?.code" [(data)]="country" (dataChange)="handleValueChange()"></agc-country-input>
          </div>

          <div class="col-sm-4 loc">
            <agc-state-input [countryCode]="country?.code" [ngModel]="state?.id" [(data)]="state" (dataChange)="handleValueChange()"></agc-state-input>
          </div>

          <div class="col-sm-4 loc">
            <agc-region-input [stateId]="state?.id" [ngModel]="region?.id" [(data)]="region" (dataChange)="handleValueChange()"></agc-region-input>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
