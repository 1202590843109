import { Directive, Input } from "@angular/core";
import { AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors } from "@angular/forms";
import { Observable, of } from "rxjs";
import { catchError, delay, map, switchMap } from "rxjs/operators";
import { AccountService } from "../services/AccountService";

@Directive({
  selector: '[emailAvailable]',
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: AccountEmailAvailableValidationDirective,
      multi: true
    }
  ]
})
export class AccountEmailAvailableValidationDirective implements AsyncValidator {

  @Input('emailAvailable') prefix: string = '';
  @Input() ignore: string = '';

  constructor(
    private accountService: AccountService,
  ) { }

  validate(control: AbstractControl): Promise<ValidationErrors> | Observable<ValidationErrors> {
    const { prefix, accountService } = this;
    const error = { emailAvailable: true };

    if (!control.value) return of(null as any);

    const ignore = this.ignore.toLowerCase();
    const value = (control.value as string).toLowerCase();

    if (value === ignore) {
      return of(null as any);
    }

    return of(null).pipe(
      delay(1000),

      switchMap(() => accountService.emailAvailable(value, prefix)),

      map(resp => {
        if (resp.available) return null as any;
        return error;
      }),

      catchError(resp => of(error))
    );
  }

}
