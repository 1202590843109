import { Component, Input } from "@angular/core";

@Component({
  selector: 'agc-parcel-icon',
  templateUrl: 'icon.component.html',
  styleUrls: ['icon.component.scss']
})
export class ParcelIconComponent {

  @Input() name: string = '';

  constructor() {}

}
