import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { AccountService, LoadingService } from '@core';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  private xLoading: boolean = false;
  get loading() { return this.xLoading; }
  set loading(value: boolean) {
    this.xLoading = value;
    try {
      // this.cdr.detectChanges();
    } catch (e) {
      // Do nothing
    }
  }

  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
    private loadingService: LoadingService,
    private accountService: AccountService,
  ) {
    loadingService.value.subscribe(value => {
      this.loading = value;
    });

    const navStart = filter(nav => nav instanceof NavigationStart);
    router.events.pipe(navStart).subscribe(nav => {
      loadingService.start();
    });

    const navEnd = filter(nav => nav instanceof NavigationEnd);
    router.events.pipe(navEnd).subscribe(nav => {
      loadingService.stop();
    });

    const navCancel = filter(nav => nav instanceof NavigationCancel);
    router.events.pipe(navCancel).subscribe(nav => {
      loadingService.stop();
    });

    const navError = filter(nav => nav instanceof NavigationError);
    router.events.pipe(navError).subscribe(nav => {
      loadingService.stop();
    });
  }

}
