import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, inject, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Subscription } from "rxjs";
import { map, tap } from "rxjs/operators";
import { Country } from "../../interfaces/Country";
import { CountryService } from "../../services/CountryService";
import { MessageService } from "../../services/MessageService";

interface CountryHash {
  [key: string]: Country;
}

@Component({
  selector: 'agc-country-input',
  templateUrl: 'country-input.component.html',
  styleUrls: ['country-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CountryInputComponent),
      multi: true
    }
  ]
})
export class CountryInputComponent implements ControlValueAccessor, OnInit, AfterViewInit {

  private readonly countryService = inject(CountryService);
  private readonly messageService = inject(MessageService);
  private readonly rootRef: ElementRef<HTMLElement> = inject(ElementRef<HTMLElement>);

  private xHash: CountryHash = {};

  private xCountryList: Country[] = [];

  get countryList() { return this.xCountryList; }

  @Input() placeholder: string = '';
  @Input() id: string = '';
  @Input() value: string = '';

  @Input() data?: Country;
  @Output() dataChange: EventEmitter<Country>;

  @ViewChild('input') inputRef: ElementRef<HTMLSelectElement> = null as any;

  private xAll: boolean = false;

  get all() { return this.xAll; }

  @Input('all') set all(value: boolean) {
    if (typeof (value) !== 'boolean') value = false;
    this.xAll = value;
    this.load();
  }

  private xLoading: boolean = false;

  get loading() { return this.xLoading; }

  private xSub: Subscription = null as any;

  constructor() {
    const { rootRef: { nativeElement: root } } = this;
    root.tabIndex = 1;

    this.dataChange = new EventEmitter<Country>();
  }

  onChange = (value: string) => {};

  writeValue(value: string): void {
    this.value = value = value || '';
    this.onCountryChange(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    // throw new Error("Method not implemented.");
  }

  setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }

  ngOnInit() {
    this.load();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const { nativeElement: input } = this.inputRef;
      const { nativeElement: root } = this.rootRef;

      root.addEventListener('focus', (e: FocusEvent) => {
        input.focus();
      });
    });
  }

  onCountryChange(value: string, write: boolean = false) {
    const { xHash, dataChange } = this;

    if (write === true) {
      this.onChange(value);
    }

    if (value in xHash) {
      dataChange.emit(xHash[value]);
    } else {
      dataChange.emit();
    }
  }

  private api() {
    this.xHash = {};

    const { countryService } = this;

    const request = (this.all) ?
      countryService.getAll() :
      countryService.getAllEnabled();

    return request.pipe(
      tap(resp => {
        for (const country of resp) {
          if (!country.code) continue;
          this.xHash[country.code] = country;
        }
      })
    );
  }

  private load() {
    const { xSub, messageService } = this;

    if (xSub) xSub.unsubscribe();

    this.xLoading = true;

    this.xSub = this.api().subscribe({
      next: resp => {
        const { value } = this;
        this.xCountryList = resp;
        this.onCountryChange(value);
      },

      error: error => {
        messageService.handle(error);
        this.xLoading = false;
        this.xSub = null as any;
      },

      complete: () => {
        this.xLoading = false;
        this.xSub = null as any;
      }
    });
  }

}
