import { Directive, ElementRef, OnInit } from "@angular/core";

@Directive({
  selector: '.pcoded-hasmenu'
})
export class PcodedHasMenu implements OnInit {

  constructor(private el: ElementRef<HTMLElement>) {}

  ngOnInit() {
    const { nativeElement: element } = this.el;

    element.setAttribute('dropdown-icon', 'style1');
    element.setAttribute('subitem-icon', 'style1');

    const alink = element.querySelector(':scope > a') as HTMLElement;

    if (alink) {
      alink.addEventListener('click', function (e: MouseEvent) {
        e.preventDefault();

        if (element.classList.contains('active')) return;

        if (element.classList.contains('pcoded-trigger')) {
          element.classList.remove('pcoded-trigger');
        } else {
          element.classList.add('pcoded-trigger');
        }
      });
    }
  }

}
