import { Component } from "@angular/core";

@Component({
  selector: 'agc-frame-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss']
})
export class FrameHeaderComponent {

  constructor() {}

}
