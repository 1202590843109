<agc-container dialog>
  <h1 mat-dialog-title>{{ (data) ? 'EDIT' : 'ADD NEW' }} PARCEL</h1>

  <mat-dialog-content class="mat-typography">
    <agc-form [formGroup]="form" (submit)="submit()">
      <div class="row">
        <div class="col-md-8">
          <agc-form-field>
            <label for="">Name <span class="text-danger">*</span></label>
            <input type="text" formControlName="name" class="form-control" required>
            <agc-message when="required">Name is required.</agc-message>
          </agc-form-field>

          <agc-form-field>
            <label for="">Description <span class="text-danger">*</span></label>
            <textarea formControlName="description" class="form-control" required></textarea>
            <agc-message when="required">Description is required.</agc-message>
          </agc-form-field>

          <agc-form-field>
            <label for="">Note</label>
            <textarea formControlName="note" class="form-control"></textarea>
            <agc-message when="required">Note is required.</agc-message>
          </agc-form-field>
        </div>

        <div class="col-md-4">
          <agc-form-field>
            <label for="">Length (cm)</label>
            <input type="number" formControlName="length" class="form-control">
            <agc-message when="required">Length is required.</agc-message>
          </agc-form-field>

          <agc-form-field>
            <label for="">Width (cm)</label>
            <input type="number" formControlName="width" class="form-control">
            <agc-message when="required">Width is required.</agc-message>
          </agc-form-field>

          <agc-form-field>
            <label for="">Height (cm)</label>
            <input type="number" formControlName="height" class="form-control">
            <agc-message when="required">Height is required.</agc-message>
          </agc-form-field>

          <agc-form-field>
            <label for="">Weight (kg) <span class="text-danger">*</span></label>
            <input type="number" formControlName="weight" class="form-control" required>
            <agc-message when="required">Weight is required.</agc-message>
          </agc-form-field>

          <agc-form-field>
            <label for="">Quantity <span class="text-danger">*</span></label>
            <input type="number" formControlName="quantity" class="form-control" required>
            <agc-message when="required">Quantity is required.</agc-message>
          </agc-form-field>
        </div>
      </div>
    </agc-form>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button class="btn btn-default btn-md waves-effect waves-light" (click)="cancel()">Cancel</button>
    <button cdkFocusInitial class="btn btn-primary btn-md ml-2 waves-effect waves-light" (click)="formRef?.doSubmit()">Submit</button>
  </mat-dialog-actions>
</agc-container>
