import { Inject, Injectable, inject } from '@angular/core';
import { AGCModuleConfig, AGC_CONFIG } from '../interfaces/AGCModuleConfig';
import { Search } from '../interfaces/Search';

@Injectable({
  providedIn: 'root'
})
export class URLHelper {

  private readonly agc = inject(AGC_CONFIG);

  private sanitize(path: string = ''): string {
    path = path.trim();

    if (path && path.substring(0, 1) === '/') {
      path = path.substring(1);
    }

    return path;
  }

  endpointObject(path: string = '', prefix: string = '', args?: Search): URL {
    return new URL(this.endpoint(path, prefix, args));
  }

  endpoint(path: string = '', prefix: string = '', args?: Search): string {
    const { type, environment } = this.agc;

    let endpoint = `${environment.api}/${type}`;

    path = this.sanitize(path);
    prefix = this.sanitize(prefix);

    if (prefix) {
      endpoint += `/${prefix}`;
    }

    if (path) {
      endpoint += `/${path}`;
    }

    if (args) {
      const params = this.search(args);
      endpoint += '?' + params.toString();
    }

    return endpoint;
  }

  urlObject(path: string = ''): URL {
    return new URL(this.url(path));
  }

  url(path: string = ''): string {
    const { environment } = this.agc;

    let url = environment.api;

    path = this.sanitize(path);

    if (path) {
      url += `/${path}`;
    }

    return url;
  }

  search(args?: Search): URLSearchParams {
    const params = new URLSearchParams();

    if (!args) args = { page: 1 };
    if (!args.page) args.page = 1;

    // tslint:disable-next-line: forin
    for (const key in args) {
      params.append(key, String(args[key]));
    }

    return params;
  }

}
