import { ChangeDetectorRef, Component, Inject, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AGCFormComponent } from "../../../components/form/form.component";
import { Matrix, MatrixAlgorithm, MatrixAlgorithmDialogOptions, MatrixAlgorithmFormData, MatrixEntryDialogOptions } from "../../../interfaces/Matrix";
import { MatrixService } from "../../../services/MatrixService";
import { MessageService } from "../../../services/MessageService";

@Component({
  selector: 'app-dialog-matrix-algorithm',
  templateUrl: 'algorithm.dialog.html',
  styleUrls: ['algorithm.dialog.scss']
})
export class MatrixAlgorithmDialog {

  loading: boolean = false;

  @ViewChild(AGCFormComponent) formRef: AGCFormComponent = null as any;

  form: FormGroup = new FormGroup({
    algorithm: new FormControl('FLAT_RANGE_WE'),
  });

  constructor(
    private cd: ChangeDetectorRef,
    private matrixService: MatrixService,
    private messageService: MessageService,
    private dialogRef: MatDialogRef<MatrixAlgorithmDialog>,
    @Inject(MAT_DIALOG_DATA) public data: MatrixAlgorithmDialogOptions
  ) {
    if (data.algorithm) {
      this.form.patchValue({
        algorithm: data.algorithm
      });
    }
  }

  submit() {
    const { form, data, matrixService, messageService, dialogRef } = this;

    const formData = form.value as MatrixAlgorithmFormData;
    formData.fromStateId = data.fromState.id as number;
    formData.toStateId = data.toState.id as number;

    this.loading = true;

    matrixService.setAlgorithm(formData).subscribe({
      next: resp => {
        this.loading = false;
        dialogRef.close(resp);
      },

      error: error => {
        messageService.handle(error, false);
        this.loading = false;
      }
    });
  }

  close() {
    this.dialogRef.close(false);
  }

}

