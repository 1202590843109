import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { MatrixEntryDialog } from "../../dialog/matrix/entry/entry.dialog";
import { Matrix, MatrixEntryDialogOptions, } from "../../interfaces/Matrix";

@Injectable()
export class MatrixEntryDialogService {

  constructor(
    private dialog: MatDialog
  ) {  }

  open(data: MatrixEntryDialogOptions): Observable<Matrix> {
    const dialog = this.dialog.open(MatrixEntryDialog, {
      width: '90vw',
      maxWidth: '900px',
      disableClose: true,
      data
    });

    return dialog.afterClosed();
  }
}
