import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { BusinessesDialog } from "../../dialog/businesses/businesses.dialog";
import { Business } from "../../interfaces/Business";

@Injectable()
export class BusinessDialogService {

  constructor(
    private dialog: MatDialog
  ) {  }

  add(data: Business = null as any): Observable<Business> {
    const dialog = this.dialog.open(BusinessesDialog, {
      width: '90vw',
      maxWidth: '700px',
      disableClose: true,
      data
    });

    return dialog.afterClosed();
  }

}
