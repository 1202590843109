import { HttpClient } from "@angular/common/http";
import { Inject, Injectable, inject } from "@angular/core";
import { BehaviorSubject, Observable, tap } from "rxjs";

import { URLHelper } from "../helpers/URLHelper";
import { Wallet } from "../interfaces/Wallet";
import { Transaction } from "../interfaces/Transaction";


@Injectable()
export class WalletService {

  private readonly http = inject(HttpClient);
  private readonly helper = inject(URLHelper);

  private readonly xWallets = new BehaviorSubject<Wallet[]>([]);
  readonly wallets: Observable<Wallet[]>;

  constructor() {
    this.wallets = this.xWallets.asObservable();
  }

  get() {
    const { xWallets } = this;

    return this.forAccount().pipe(
      tap(resp => xWallets.next(resp))
    );
  }

  forAccount(accountId?: number) {
    const { helper, http } = this;

    const url = helper.endpointObject(`/wallet`);

    if (accountId) {
      url.searchParams.set('accountId', String(accountId));
    }

    return http.get<Wallet[]>(url.toString());
  }

  pay(invoiceId: number): Observable<Transaction> {
    const { helper, http } = this;
    const url = helper.endpoint(`/wallet/pay`);
    return http.post<Transaction>(url, { invoiceId });
  }

}
