<div class="card">
  <div class="card-header b-b">
    <h2 *ngIf="titleText">{{ titleText }}</h2>

    <div>
      <ng-content></ng-content>
    </div>
  </div>

  <div class="card-body">
    <agc-pagination [data]="records" (callback)="fetch($event)">
      <div class="text-center" *ngIf="!records?.total">No country found</div>

      <table class="table table-striped table-hover table-text" *ngIf="records?.total">
        <thead>
          <tr class="footable-header">
            <th>ID</th>
            <th>Name</th>
            <th>ISO Code</th>
            <th>Phone Code</th>
            <th *ngIf="toggle">Enabled</th>
            <th>Created</th>
            <th>Updated</th>
            <th *ngIf="actions?.length"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of records.data">
            <td>{{ data.id }}</td>
            <td>{{ data.name }}</td>
            <td>{{ data.code }}</td>
            <td>{{ data.prefix }}</td>
            <td *ngIf="toggle">
              <agc-switch [value]="data.isEnabled" (click)="handleToggle($event, data)"></agc-switch>
            </td>
            <td>{{ data.createdAt | date:'short' }}</td>
            <td>{{ data.updatedAt | date:'short' }}</td>
            <td class="text-right" *ngIf="actions?.length">
              <a href="javascript:;" class="label" [ngClass]="action.cssClass" (click)="action.callback($event, data)" *ngFor="let action of actions">
                <i [ngClass]="action.icon" *ngIf="action.icon"></i>
                <span *ngIf="action.text">&nbsp; {{ action.text }}</span>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </agc-pagination>
  </div>
</div>
