import { Component, Inject, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { AGCFormComponent } from "../../../components/form/form.component";
import { Country } from "../../../interfaces/Country";
import { RoutingMilestone } from "../../../interfaces/Routing";
import { State } from "../../../interfaces/State";

@Component({
  selector: 'app-dialog-routing-milestone',
  templateUrl: 'milestone.dialog.html',
  styleUrls: ['milestone.dialog.scss']
})
export class RoutingMilestoneDialog {

  @ViewChild(AGCFormComponent) formRef?: AGCFormComponent;

  form: FormGroup = new FormGroup({
    countryCode: new FormControl(),
    stateId: new FormControl(),
    unitPrice: new FormControl()
  });

  loading: boolean = false;

  country?: Country;
  state?: State;

  priceForm: any = {
    length: 0,
    width: 0,
    height: 0,
    weight: 0,
    price: 0,
  };

  get unitPrice() {
    const { form } = this;
    const ctrl = form.get('unitPrice');
    if (!ctrl) return 0;
    return ctrl.value;
  }

  constructor(
    private dialogRef: MatDialogRef<RoutingMilestoneDialog>,
    @Inject(MAT_DIALOG_DATA) public data: RoutingMilestone
  ) {
    if (data) {
      this.form.patchValue(data);

      this.country = data.country as Country;
      this.state = data.state as State;
    }
  }

  onCountryChange(country: Country) {
    this.country = country;
  }

  onStateChange(state: State) {
    this.state = state;
  }

  submit() {
    const { form, dialogRef, country, state } = this;

    const data = form.value as RoutingMilestone;
    data.country = country;
    data.state = state;

    dialogRef.close(data);
  }

  cancel() {
    const { dialogRef } = this;
    dialogRef.close();
  }

  onPriceChange(key: string, value: number) {
    const { priceForm } = this;
    priceForm[key] = value;

    const { height, length, price, weight, width } = priceForm;
    const ctrl = this.form.get('unitPrice');

    if (!height || !length || !price || !weight || !width) {
      ctrl && ctrl.setValue(null);
    } else {
      const unitPrice = price / (height * length * weight * width);
      ctrl && ctrl.setValue(unitPrice.toFixed(6));
    }
  }

}
