import { ChangeDetectorRef, Component, Inject, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AGCFormComponent } from "../../../components/form/form.component";
import { Matrix, MatrixEntryDialogOptions } from "../../../interfaces/Matrix";
import { MatrixService } from "../../../services/MatrixService";
import { MessageService } from "../../../services/MessageService";

@Component({
  selector: 'app-dialog-matrix-entry',
  templateUrl: 'entry.dialog.html',
  styleUrls: ['entry.dialog.scss']
})
export class MatrixEntryDialog {

  loading: boolean = false;

  @ViewChild(AGCFormComponent) formRef: AGCFormComponent = null as any;

  form: FormGroup = new FormGroup({
    type: new FormControl('RANGE'),
    minWeight: new FormControl(0),
    maxWeight: new FormControl(0),
    minLength: new FormControl(0),
    maxLength: new FormControl(0),
    minHeight: new FormControl(0),
    maxHeight: new FormControl(0),
    minWidth: new FormControl(0),
    maxWidth: new FormControl(0),
    amount: new FormControl(0),
    duration: new FormControl(0),
    durationType: new FormControl('D'),
  });

  constructor(
    private cd: ChangeDetectorRef,
    private matrixService: MatrixService,
    private messageService: MessageService,
    private dialogRef: MatDialogRef<MatrixEntryDialog>,
    @Inject(MAT_DIALOG_DATA) public data: MatrixEntryDialogOptions
  ) {
    if (data.matrix) {
      this.form.patchValue(data.matrix);
    }
  }

  submit() {
    const { form, data } = this;

    const formData = form.value as Matrix;

    formData.fromStateId = data.fromState.id;
    formData.fromCountryCode = data.fromState.countryCode;

    formData.toStateId = data.toState.id;
    formData.toCountryCode = data.toState.countryCode;

    if (data.matrix) {
      this.edit(formData);
    } else {
      this.add(formData);
    }
  }

  private add(data: Matrix) {
    const { matrixService, messageService, dialogRef } = this;

    this.loading = true;

    matrixService.add(data).subscribe({
      next: resp => {
        this.loading = false;
        dialogRef.close(resp);
      },

      error: error => {
        messageService.handle(error, false);
        this.loading = false;
      }
    });
  }

  private edit(data: Matrix) {
    const { matrixService, messageService, dialogRef } = this;
    const { matrix } = this.data;

    this.loading = true;

    matrixService.edit(matrix?.id as any, data).subscribe({
      next: resp => {
        this.loading = false;
        dialogRef.close(data);
      },

      error: error => {
        messageService.handle(error, false);
        this.loading = false;
      }
    });
  }

  close() {
    this.dialogRef.close(false);
  }

}

