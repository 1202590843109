<div class="top">
  <agc-pagination-info [data]="data" (callback)="navigate($event)"></agc-pagination-info>
</div>

<div class="content table-responsive">
  <ng-content></ng-content>
</div>

<div class="bottom">
  <agc-pagination-info [data]="data" (callback)="navigate($event)"></agc-pagination-info>
</div>
