import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";
import { Subscription } from "rxjs";
import { Country, CountryListAction, CountryPagination } from "../../interfaces/Country";
import { CountryService } from "../../services/CountryService";
import { LoadingService } from "../../services/LoadingService";
import { MessageService } from "../../services/MessageService";

@Component({
  selector: 'agc-country-list',
  templateUrl: 'country-list.component.html',
  styleUrls: ['country-list.component.scss']
})
export class CountryListComponent {

  private xRecords: CountryPagination = null as any;
  get records() { return this.xRecords; }

  private xToggle: boolean = false;
  get toggle() { return this.xToggle; }

  @Input() set toggle(value: boolean) {
    if (typeof (value) !== 'boolean') value = false;
    this.xToggle = value;
  }

  @Input() titleText: string = '';

  @Input() actions: CountryListAction[] = [];

  constructor(
    private cd: ChangeDetectorRef,
    private loadingService: LoadingService,
    private messageService: MessageService,
    private countryService: CountryService
  ) {
    this.fetch();
  }

  // ngAfterViewInit() {
  //   this.fetch();
  // }

  fetch(page: number = 1) {
    const { cd, loadingService, countryService, messageService } = this;

    loadingService.start();

    countryService.search(page).subscribe({
      next: resp => {
        this.xRecords = resp;
      },

      error: messageService.handle,

      complete: () => {
        loadingService.stop();
      }
    });
  }

  handleToggle(e: Event, data: Country) {
    e.preventDefault();
    e.stopPropagation();

    const { countryService, loadingService, messageService } = this;

    loadingService.start();

    countryService.toggle(data.id!).subscribe({
      next: resp => {
        Object.assign(data, resp);
      },

      error: messageService.handle,

      complete: () => {
        loadingService.stop();
      }
    });
  }

}
