<agc-container dialog [loading]="loading">
  <h1 mat-dialog-title>ADD NEW ROUTING</h1>

  <mat-dialog-content class="mat-typography">
    <agc-form [formGroup]="form" (submit)="submit()">
      <div class="row">
        <div class="col-md-6">
          <h2>Shipping From</h2>

          <agc-form-field>
            <label for="">Country</label>
            <div class="form-control agc-disabled">{{ data.country.name }}</div>
          </agc-form-field>

          <agc-form-field>
            <label for="">State/Province</label>
            <div class="form-control agc-disabled">{{ data.state.name }}</div>
          </agc-form-field>
        </div>

        <div class="col-md-6">
          <h2>Destination</h2>

          <agc-form-field>
            <label for="">Country <span class="text-danger">*</span></label>
            <agc-country-input [all]="true" formControlName="toCountryCode" [(data)]="country" required></agc-country-input>
            <agc-message when="required">Destination Country is required.</agc-message>
          </agc-form-field>

          <agc-form-field>
            <label for="">State/Province <span class="text-danger">*</span></label>
            <agc-state-input [countryCode]="country?.code" formControlName="toStateId" required></agc-state-input>
            <agc-message when="required">Destination State/Province is required.</agc-message>
          </agc-form-field>
        </div>
      </div>
    </agc-form>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button class="btn btn-default btn-md waves-effect waves-light" (click)="cancel()">Cancel</button>
    <button cdkFocusInitial class="btn btn-primary btn-md ml-2 waves-effect waves-light" (click)="formRef?.doSubmit()">Add New Routing</button>
  </mat-dialog-actions>
</agc-container>
