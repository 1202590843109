import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from "@angular/core";

@Component({
  selector: 'agc-morris-chart',
  templateUrl: 'morris-chart.component.html',
  styleUrls: ['morris-chart.component.scss']
})
export class MorrisChartComponent implements AfterViewInit {

  @Input() width: string = '100%';
  @Input() height: string = '350px';

  private xData: any = null;

  get data() { return this.xData; }

  @Input() set data(value: any) {
    this.xData = value;
    this.cd.detectChanges();

    if (this.containerRef) {
      this.plot();
    }
  }

  @ViewChild('container') containerRef: ElementRef<HTMLDivElement> = null as any;

  private xChart: any = null;

  constructor(
    private cd: ChangeDetectorRef
  ) {}

  ngAfterViewInit() {
    this.plot();
    this.cd.detectChanges();
  }

  private plot() {
    const { Morris } = window as any;
    const { nativeElement: container } = this.containerRef;

    if (!this.data) return;

    if (this.xChart) {
      //
    } else {
      this.xChart = Morris.Line({
        element: container,
        ...this.data
      });
    }
  }

}
