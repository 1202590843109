import { AfterViewInit, ChangeDetectorRef, Component, forwardRef, OnDestroy } from "@angular/core";
import { AbstractControl, ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from "@angular/forms";
import { Country, CountryListAction } from "../../interfaces/Country";
import { State } from "../../interfaces/State";
import { Region } from "../../interfaces/Region";
import { MessageRecipients } from "../../interfaces/Messaging";
import { Subscription } from "rxjs";

type OnChangeCallback = (value: MessageRecipients) => {};

interface AccountMap {
  [key: string]: string;
}

const AccountTypeMap: AccountMap = {
  ADMIN: 'Admins',
  AGENT: 'Agents',
  BUSINESS: 'Businesses',
  USER: 'Customers',
};

@Component({
  selector: 'agc-recipients-input',
  templateUrl: 'recipients-input.component.html',
  styleUrls: ['recipients-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RecipientsInputComponent),
      multi: true
    }
  ]
})
export class RecipientsInputComponent implements ControlValueAccessor {

  target: string = 'USER';
  status: string = 'ALL';
  location: boolean = false;
  country?: Country;
  state?: State;
  region?: Region;

  onChange: OnChangeCallback = null as any;

  get name() {
    const { target } = this;
    return AccountTypeMap[target];
  }

  constructor(
    private cd: ChangeDetectorRef
  ) { }

  writeValue(value: MessageRecipients): void {
    this.parseValue(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;

    setTimeout(() => {
      this.handleValueChange();
    }, 200);
  }

  registerOnTouched(fn: any): void {
    // throw new Error("Method not implemented.");
  }

  private parseValue(value: MessageRecipients) {
    if (!value) {
      this.target = 'USER';
      this.status = 'ALL';
      this.location = false;
      this.country = null as any;
      this.state = null as any;
      this.region = null as any;
    }
  }

  handleValueChange() {
    const { target, status, location, country, state, region } = this;

    try {
      if (!target) throw 'Invalid target';
      if (!status) throw 'Invalid Status';

      if (location && !country) {
        throw 'Invalid Country';
      }

      const value: MessageRecipients = { target, status, location } as any;

      if (location) {
        value.countryCode = country!.code;

        if (state && state.countryCode === country!.code) {
          value.stateId = state.id;
        }

        if (region && region.stateId === state?.id) {
          value.regionId = region.id;
        }
      }

      this.onChange(value);
    } catch (error) {
      this.onChange(null as any);
    }
  }

}
