import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { URLHelper } from "../helpers/URLHelper";
import { TwoFABackup, TwoFAInfo, TwoFAStatus } from "../interfaces/TwoFA";

@Injectable({
  providedIn: 'root'
})
export class TwoFAService {

  constructor(
    private http: HttpClient,
    private urlHelper: URLHelper
  ) {}

  authorize(data: any) {
    const url = this.urlHelper.endpoint('/authorize/2fa');
    return this.http.post<any>(url, data);
  }

  status() {
    const url = this.urlHelper.endpoint('/2fa/status');
    return this.http.get<TwoFAStatus>(url);
  }

  get() {
    const url = this.urlHelper.endpoint('/2fa');
    return this.http.get<TwoFAInfo>(url);
  }

  enable(data: any) {
    const url = this.urlHelper.endpoint('/2fa');
    return this.http.post<any>(url, data);
  }

  backup() {
    const url = this.urlHelper.endpoint('/2fa');
    return this.http.patch<TwoFABackup>(url, {});
  }

  disable() {
    const url = this.urlHelper.endpoint('/2fa');
    return this.http.delete<any>(url);
  }

}
