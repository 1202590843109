import { AfterContentInit, AfterViewInit, ChangeDetectorRef, Component, ContentChildren, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChild, inject } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { FormFieldComponent } from '../form-field/form-field.component';

@Component({
  selector: 'agc-form',
  templateUrl: 'form.component.html',
  styleUrls: ['form.component.scss']
})
export class AGCFormComponent implements AfterViewInit, OnDestroy {

  private readonly cd = inject(ChangeDetectorRef);

  readonly directive = inject(FormGroupDirective);

  @Input() formGroup!: FormGroup;

  @Output('submit') onSubmit = new EventEmitter<FormGroup>();

  @ViewChild('form') formRef: ElementRef<HTMLFormElement> = null as any;

  @ContentChildren(FormFieldComponent, {
    descendants: true
  }) fields: QueryList<FormFieldComponent> = null as any;

  // private xSubmitted: boolean = false;
  // get submitted() { return this.xSubmitted };

  private xSub: Subscription = null as any;

  readonly submitted: Observable<boolean>;
  private xSubmitted = new BehaviorSubject(false);

  get isSubmitted() { return this.xSubmitted.value; }

  constructor() {
    this.submitted = this.xSubmitted.asObservable();
  }

  ngOnDestroy() {
    if (this.xSub) this.xSub.unsubscribe();
  }

  ngAfterViewInit() {
    const { nativeElement: form } = this.formRef;

    form.addEventListener('reset', e => {
      this.xSubmitted.next(false);
    });

    this.cd.detectChanges();
  }

  submit() {
    const { nativeElement: form } = this.formRef;
    form.requestSubmit();
  }

  doSubmit() {
    this.submit();
  }

  onFormSubmit(e: Event) {
    e.preventDefault();
    e.stopPropagation();

    const { formGroup, onSubmit } = this;

    this.xSubmitted.next(true);

    if (formGroup.invalid) {
      const { nativeElement: form } = this.formRef;

      const input = form.querySelector('.ng-invalid') as HTMLElement;
      if (input) input.focus();

      return;
    }

    onSubmit.emit(formGroup);
  }

}
