import { AfterViewInit, Component, ElementRef, forwardRef, OnDestroy, ViewChild } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

// import tinymce, { Editor, EditorEvent } from 'tinymce';

declare const tinymce: any;
type OnChangeHandler = (value: any) => void;


@Component({
  selector: 'agc-editor',
  templateUrl: 'editor.component.html',
  styleUrls: ['editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditorComponent),
      multi: true
    }
  ]
})
export class EditorComponent implements ControlValueAccessor, AfterViewInit, OnDestroy {

  @ViewChild('input') inputRef?: ElementRef<HTMLElement>;

  private value: any;
  private onChange?: OnChangeHandler;

  private $editor: any;

  constructor() {
    this.onEditorInput = this.onEditorInput.bind(this);
  }

  ngAfterViewInit() {
    const { nativeElement } = this.inputRef!;

    (async () => {
      const editors = await tinymce.init({
        target: nativeElement,
        skin: false,
        content_css: ['ui-oxide-content.min.css', 'default-content.min.css'],
        // content_style: contentUiCss + "\n" + contentCss,
        menubar: false,
        branding: false,
        plugins: 'autoresize advlist autolink link image lists charmap preview help quickbars wordcount table',
        toolbar: [
          'undo redo | formatselect | bold italic underline strikethrough backcolor forecolor fontsize | alignleft aligncenter alignright alignjustify | superscript subscript | bullist numlist outdent indent | removeformat',
          'table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | help',
        ],
        resize: false,
        min_height: 300,
        toolbar_sticky: true,
        toolbar_sticky_offset: 56
      });

      if (editors.length) {
        const $editor = this.$editor = editors[0];

        $editor.on('input', this.onEditorInput);
        $editor.on('Change', this.onEditorInput);
        $editor.on('NodeChange', this.onEditorInput);
        $editor.on('Undo', this.onEditorInput);
        $editor.on('Redo', this.onEditorInput);
      }
    })();
  }

  ngOnDestroy() {
    this.$editor.destroy();
  }

  private onEditorInput(e: any) {
    const { $editor } = this;

    if (this.onChange) {
      this.onChange($editor.getContent());
    }
  }

  writeValue(value: any) {
    this.value = value;

    if (this.$editor) {
      this.$editor.setContent(value);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    // throw new Error("Method not implemented.");
  }

}
