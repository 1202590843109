import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Observable, of, throwError } from "rxjs";
import { catchError, share, switchMap, tap } from "rxjs/operators";
import { RoleDialog } from "../../dialog/roles/role.dialog";
import { Role, RoleDialogOptions } from "../../interfaces/Role";
import { LoadingService } from "../LoadingService";
import { MessageService } from "../MessageService";
import { RoleService } from "../RoleService";

@Injectable()
export class RoleDialogService {

  constructor(
    private dialog: MatDialog,
    private roleService: RoleService,
    private loadingService: LoadingService,
    private messageService: MessageService
  ) {  }

  open(role: Role = null as any): Observable<Role> {
    const { roleService, loadingService, messageService } = this;
    const data: RoleDialogOptions = { role, permissions: [] };

    loadingService.start();

    const result = of(role).pipe(
      switchMap(resp => roleService.permissions()),

      tap(resp => {
        data.permissions = resp;
        loadingService.stop();
      }),

      catchError(error => {
        messageService.handle(error);
        return throwError(error);
      }),

      switchMap(resp => {
        const dialog = this.dialog.open(RoleDialog, {
          width: '90vw',
          maxWidth: '700px',
          disableClose: true,
          data
        });

        return dialog.afterClosed();
      }),

      share()
    );

    result.subscribe();

    return result;
  }

}
