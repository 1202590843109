import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { URLHelper } from "../../helpers/URLHelper";
import { BankDetails } from "../../interfaces/Bank";
import { PaystackAccountResolveForm, PaystackAccountResolveResponse, PaystackBank, PaystackBankListResponse } from "../../interfaces/Paystack";

@Injectable()
export class PaystackService {

  constructor(
    private http: HttpClient,
    private urlHelper: URLHelper
  ) {  }

  getAllBanks() {
    const url = this.urlHelper.endpoint('/payment/paystack/banks');
    return this.http.get<PaystackBankListResponse>(url);
  }

  resolveBankAccount(data: PaystackAccountResolveForm) {
    const url = this.urlHelper.endpoint('/payment/paystack/resolve');
    return this.http.post<PaystackAccountResolveResponse>(url, data);
  }

  getBankDetails() {
    const url = this.urlHelper.endpoint('/payment/paystack');
    return this.http.get<BankDetails>(url);
  }

  saveBankDetails(data: BankDetails) {
    const url = this.urlHelper.endpoint('/payment/paystack');
    return this.http.post<BankDetails>(url, data);
  }

}
