<agc-container dialog [loading]="loading">
  <h1 mat-dialog-title>{{ (data.title) ? data.title : 'PREVIEW SHIPMENT' }}</h1>

  <mat-dialog-content class="mat-typography">
    <div class="row">
      <div class="col-md-6">
        <h2>Shipper's Details</h2>

        <table class="table table-striped">
          <tr>
            <th>Full Name</th>
            <td>{{ shipping.account | fullname }}</td>
          </tr>
          <tr>
            <th>Email Address</th>
            <td>{{ shipping.account?.email || '-' }}</td>
          </tr>
          <tr>
            <th>Username</th>
            <td>{{ shipping.account?.username }}</td>
          </tr>
          <tr>
            <th>Phone</th>
            <td>{{ shipping.account?.phone || '-' }}</td>
          </tr>
          <tr>
            <th>Country</th>
            <td>{{ shipping.fromCountry?.name }}</td>
          </tr>
          <tr>
            <th>State/Province</th>
            <td>{{ shipping.fromState?.name }}</td>
          </tr>
          <tr>
            <th>Region/City/LGA</th>
            <td>{{ shipping.fromRegion?.name }}</td>
          </tr>
          <tr>
            <th>Zip/Postal</th>
            <td>{{ shipping.fromZipPostal || '-' }}</td>
          </tr>
          <tr>
            <th>Address 1</th>
            <td>{{ shipping.fromAddress1 }}</td>
          </tr>
          <tr>
            <th>Address 2</th>
            <td>{{ shipping.fromAddress2 || '-' }}</td>
          </tr>
        </table>
      </div>

      <div class="col-md-6">
        <h2>Receiver's Details</h2>

        <table class="table table-striped">
          <tr>
            <th>Contact Name</th>
            <td>{{ shipping.contactName }}</td>
          </tr>
          <tr>
            <th>Contact Email</th>
            <td>{{ shipping.contactEmail || '-' }}</td>
          </tr>
          <tr>
            <th>Contact Phone</th>
            <td>{{ shipping.contactPhone }}</td>
          </tr>
          <tr>
            <th>Country</th>
            <td>{{ shipping.toCountry?.name }}</td>
          </tr>
          <tr>
            <th>State/Province</th>
            <td>{{ shipping.toState?.name }}</td>
          </tr>
          <tr>
            <th>Region/City/LGA</th>
            <td>{{ shipping.toRegion?.name }}</td>
          </tr>
          <tr>
            <th>Zip/Postal</th>
            <td>{{ shipping.toZipPostal || '-' }}</td>
          </tr>
          <tr>
            <th>Address 1</th>
            <td>{{ shipping.toAddress1 }}</td>
          </tr>
          <tr>
            <th>Address 2</th>
            <td>{{ shipping.toAddress2 || '-' }}</td>
          </tr>
          <tr>
            <th>Landmark</th>
            <td>{{ shipping.toLandmark || '-' }}</td>
          </tr>
        </table>
      </div>
    </div>

    <hr>

    <h2>Parcels</h2>

    <div class="pb-3">
      <agc-parcel [data]="item" *ngFor="let item of shipping.items"></agc-parcel>
    </div>

  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button class="btn btn-secondary btn-sm waves-effect waves-light" (click)="cancel()">Close</button>

    @if (shipping.id) {
      @switch (shipping.status) {
        @case ('DRAFT') {@if (invoice) {
          <button cdkFocusInitial class="btn btn-primary btn-sm ml-2 waves-effect waves-light" (click)="viewInvoice()">View Invoice</button>
        } @else {
          <button cdkFocusInitial class="btn btn-primary btn-sm ml-2 waves-effect waves-light" (click)="createInvoice()">Create Invoice</button>
        }}
      }
    } @else {
      <button cdkFocusInitial class="btn btn-primary btn-sm ml-2 waves-effect waves-light" (click)="create()">Create Shipment</button>
    }
  </mat-dialog-actions>
</agc-container>
