<!-- Pre-loader start -->
<div class="theme-loader" [hidden]="!loading">
  <div class="ball-scale">
    <div class='contain'>
      <div class="ring"><div class="frame"></div></div>
      <div class="ring"><div class="frame"></div></div>
      <div class="ring"><div class="frame"></div></div>
      <div class="ring"><div class="frame"></div></div>
      <div class="ring"><div class="frame"></div></div>
      <div class="ring"><div class="frame"></div></div>
      <div class="ring"><div class="frame"></div></div>
      <div class="ring"><div class="frame"></div></div>
      <div class="ring"><div class="frame"></div></div>
      <div class="ring"><div class="frame"></div></div>
    </div>
  </div>
</div>
<!-- Pre-loader end -->

<fieldset [disabled]="loading">
  <router-outlet></router-outlet>
</fieldset>
