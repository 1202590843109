import { Injectable, inject } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { catchError, finalize, map, Observable, of, switchMap, tap } from "rxjs";
import { WalletDialog } from "../../dialog/wallet/wallet.dialog";
import { WalletService } from "../WalletService";
import { LoadingService } from "../LoadingService";
import { MessageService } from "../MessageService";

@Injectable()
export class WalletDialogService {

  private readonly dialog = inject(MatDialog);
  private readonly service = inject(WalletService);
  private readonly loading = inject(LoadingService);
  private readonly message = inject(MessageService);

  constructor() {  }

  open(): Observable<any> {
    const { dialog, service, loading, message } = this;

    loading.start();

    return service.get().pipe(
      finalize(() => loading.stop()),

      map(data => dialog.open(WalletDialog, {
        width: '90vw',
        maxWidth: '500px',
        disableClose: true,
        data,
      })),

      switchMap(dlg => dlg.afterClosed()),

      catchError(error => {
        message.handle(error);
        return of(null);
      })
    );
  }

}
