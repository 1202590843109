import { Component, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

type OnChangeCallback = (value: string) => {};

@Component({
  selector: 'agc-sms-input',
  templateUrl: 'sms-input.component.html',
  styleUrls: ['sms-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SMSInputComponent),
      multi: true
    }
  ]
})
export class SMSInputComponent implements ControlValueAccessor {

  value: string = '';

  charCount: number = 0;
  pageCount: number = 0;

  constructor() { }

  onChange: OnChangeCallback = null as any;

  writeValue(value: string): void {
    this.parseMessageInfo(value || '');
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    // throw new Error("Method not implemented.");
  }

  onValueChange(value: string) {
    this.onChange(value);
    this.parseMessageInfo(value);
  }

  private parseMessageInfo(value: string) {
    const length = value.length;

    // Count Characters
    const newLines = value.match(/\n/g)?.length || 0;
    this.charCount = length + newLines;

    // Count Pages
    if (!this.charCount) {
      this.pageCount = 0;
    } else if (this.charCount <= 160) {
      this.pageCount = 1;
    } else {
      this.pageCount = Math.ceil(this.charCount / 153);
    }
  }

}
