import { Injectable, inject } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";

import { Invoice, Transaction } from "../../interfaces/Transaction";
import { PaymentDialog } from "../../dialog/payment/payment.dialog";

@Injectable()
export class PaymentDialogService {

  private readonly dialog = inject(MatDialog);

  constructor() {  }

  open(invoice: Invoice, enableWallet?: boolean): Observable<Transaction | null> {
    const dialog = this.dialog.open(PaymentDialog, {
      width: '90vw',
      maxWidth: '500px',
      disableClose: true,
      data: { invoice, enableWallet },
    });

    return dialog.afterClosed();
  }

}
