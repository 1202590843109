import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, inject, Inject, OnDestroy, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { Invoice } from "../../interfaces/Transaction";
import { InvoiceService } from "../../services/InvoiceService";
import { MessageService } from "../../services/MessageService";
import { AccountService } from "../../services/AccountService";
import { Account } from "../../interfaces/Account";

import { PaymentService } from "../../services/PaymentService";
import { PaymentDialogService } from "../../services/dialog/PaymentDialogService";

type Options = {
  invoice: Invoice;
  enableWallet?: boolean;
};

@Component({
  selector: 'app-dialog-invoice',
  templateUrl: 'invoice.dialog.html',
  styleUrls: ['invoice.dialog.scss']
})
export class InvoiceDialog implements AfterViewInit, OnDestroy {

  private readonly cdr = inject(ChangeDetectorRef);
  private readonly invoiceService = inject(InvoiceService);
  private readonly messageService = inject(MessageService);
  private readonly accountService = inject(AccountService);
  private readonly dialogRef: MatDialogRef<InvoiceDialog> = inject(MatDialogRef);
  private readonly paymentService = inject(PaymentService);
  private readonly paymentDialog = inject(PaymentDialogService);

  private readonly options = inject<Options>(MAT_DIALOG_DATA);

  readonly enableWallet: boolean;
  readonly invoice: Invoice;

  loading: boolean = false;

  get isReady() { return this.invoice.status === 'READY' && this.template; };
  get isAdmin() { return this.account?.type === 'ADMIN'; }

  private xTemplate: string = '';

  get template() { return this.xTemplate; }

  @ViewChild('iframe') iframeRef: ElementRef<HTMLIFrameElement> = null as any;

  private xObserver: MutationObserver = null as any;

  account?: Account;
  private xAccountSub: Subscription;

  constructor() {
    const { options, accountService } = this;

    this.enableWallet = options.enableWallet !== false;
    this.invoice = options.invoice;

    this.xAccountSub = accountService.account.subscribe(account => {
      this.account = account;
    });
  }

  ngOnDestroy() {
    this.xAccountSub.unsubscribe();
  }

  ngAfterViewInit() {
    window.setTimeout(() => {
      const { nativeElement: iframe } = this.iframeRef;
      const { contentDocument: $document } = iframe;

      if ($document) {
        this.xObserver = new MutationObserver((mutationsList, observer) => {
          const height = $document.documentElement.scrollHeight;
          iframe.style.height = `${height}px`;
        });

        this.xObserver.observe($document.documentElement, {
          childList: true,
          attributes: true,
          subtree: true
        });
      }


      this.loadView();
    });
  }

  loadView() {
    const { invoice, invoiceService, messageService, iframeRef } = this;
    const { nativeElement: iframe } = iframeRef;
    const { contentDocument: $document } = iframe;

    this.loading = true;

    invoiceService.getView(invoice.id as number).subscribe({
      next: template => {
        if (!$document) return;
        this.xTemplate = template;
        $document.documentElement.innerHTML = template;
      },

      error: error => {
        messageService.handle(error, false);
        this.loading = false;
      },

      complete: () => {
        this.loading = false;
      }
    });
  }

  submit() {
    const { invoice, enableWallet, paymentDialog, dialogRef } = this;

    paymentDialog.open(invoice, enableWallet).subscribe(resp => {
      if (resp) dialogRef.close(resp.invoice);
    });
  }

  // submit() {
  //   const { data, paymentService, messageService, dialogRef } = this;

  //   paymentService.pay(data).subscribe({
  //     next: tranx => {
  //       messageService.alert('', 'Transaction Successful!', 'success').then(() => {
  //         dialogRef.close(tranx.invoice);
  //       });
  //     },

  //     error: error => {
  //       messageService.handle(error, false);
  //     }
  //   });
  // }

  cancel() {
    const { dialogRef } = this;
    dialogRef.close();
  }

}
