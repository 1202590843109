<agc-container dialog [loading]="loading">
  <h1 mat-dialog-title>Two-Factor Authentication</h1>

  <mat-dialog-content class="mat-typography">
    <p>Two-Factor Authentication, or 2FA, significantly improves login security for your website. AGC Courier's 2FA works with a number of TOTP-based apps like <a target="_blank" href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2">Google Authenticator</a>, <a target="_blank" href="https://freeotp.github.io/">FreeOTP</a>, and <a target="_blank" href="https://authy.com/">Authy</a>.</p>

    <div class="text-center" *ngIf="enabled">
      <h2 class="text-success">Awesome!!!</h2>
      <p class="text-success">2FA security is currently enabled for your account.</p>

      <div class="mt-5">
        <div *ngIf="!backupList.length">
          <h3>Misplaced your backup codes?</h3>

          <div class="text-center">
            <button class="btn btn-info" (click)="backup()">
              <span>Generate New Backup Codes</span>
            </button>
          </div>
        </div>

        <div *ngIf="backupList.length">
          <div class="mb-3">
            <ul class="backup">
              <li *ngFor="let backup of backupList">{{ backup }}</li>
            </ul>
          </div>

          <div class="text-center mb-3">
            <button class="btn btn-warning" (click)="download(backupList)">
              <span>Download</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!enabled">
      <div class="text-center p-4" *ngIf="!data">
        <span>Just a moment please</span>
      </div>

      <div class="row" *ngIf="data">
        <div class="col-md-6">
          <div class="card">
            <div class="card-header">
              <div class="card-header-left">
                <h5>1. Scan Code or Enter Key</h5>
              </div>
            </div>

            <div class="card-body pt-0">
              <p>Scan the code below with your authenticator app to add this account. Some authenticator apps also allow you to type in the text version instead.</p>

              <div class="mt-4">
                <img [src]="data.qrCode" alt="2FA QRCode" class="qrcode">
              </div>

              <div class="mt-5">
                <input type="text" [value]="data.secret" class="form-control text-center" readonly>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="card">
            <div class="card-header">
              <div class="card-header-left">
                <h5>2. Enter Code from Authenticator App</h5>
              </div>
            </div>

            <div class="card-body pt-0">
              <p>Download Recovery Codes</p>
              <p>Use one of these 5 codes to log in if you lose access to your authenticator device. Codes are 16 characters long plus optional spaces. Each one may be used only once.</p>

              <div class="mt-3 mb-3">
                <ul class="backup">
                  <li *ngFor="let backup of data.backup">{{ backup }}</li>
                </ul>
              </div>

              <div class="text-center mb-3">
                <button class="btn btn-warning" (click)="download(data.backup)">
                  <span>Download</span>
                </button>
              </div>

              <p>Enter the code from your authenticator app below to verify and activate two-factor authentication for this account.</p>

              <div class="otp mt-3">
                <input type="text" #input class="form-control text-center" [(ngModel)]="otp" placeholder="123456">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="false" class="btn btn-default">Cancel</button>

    <div class="flex" *ngIf="!enabled"></div>

    <button mat-button class="btn btn-success" *ngIf="!enabled" (click)="submit()">Verify and Enable 2FA</button>
  </mat-dialog-actions>
</agc-container>
