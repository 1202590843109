import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Observable, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { RoutingsAddDialog } from "../../dialog/routings/add/add.dialog";
import { RoutingsManageDialog } from "../../dialog/routings/manage/manage.dialog";
import { Country } from "../../interfaces/Country";
import { Routing } from "../../interfaces/Routing";
import { State } from "../../interfaces/State";
import { RoutingService } from "../RoutingService";

@Injectable()
export class RoutingDialogService {

  constructor(
    private dialog: MatDialog
  ) {  }

  add(country: Country, state: State): Observable<Routing> {
    const dialog = this.dialog.open(RoutingsAddDialog, {
      width: '90vw',
      maxWidth: '600px',
      disableClose: true,
      data: { country, state }
    });

    let value = null as any;

    return dialog.afterClosed().pipe(
      switchMap(resp => {
        value = resp;
        if (resp) return this.manage(resp);
        return of(resp);
      }),

      map(resp => value)
    );
  }

  manage(routing: Routing): Observable<Routing> {
    const dialog = this.dialog.open(RoutingsManageDialog, {
      width: '90vw',
      maxWidth: '800px',
      disableClose: true,
      data: routing
    });

    return dialog.afterClosed();
  }

}
