import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { ShippingPreviewDialog } from "../../dialog/shipping/preview/preview.dialog";
import { Shipping, ShippingDialogOptions } from "../../interfaces/Shipping";


@Injectable()
export class ShippingPreviewDialogService {

  constructor(
    private dialog: MatDialog
  ) {  }

  open(data: ShippingDialogOptions): Observable<Shipping> {
    const dialog = this.dialog.open(ShippingPreviewDialog, {
      width: '90vw',
      maxWidth: '800px',
      disableClose: true,
      data
    });

    return dialog.afterClosed();
  }

  openById(id: number) {
    //
  }

}
