<agc-container dialog [loading]="loading">
  <h1 mat-dialog-title>{{ (office) ? 'EDIT' : 'ADD NEW' }} OFFICE</h1>

  <mat-dialog-content class="mat-typography">
    <agc-form [formGroup]="form" (submit)="submit()">
      <div class="row">
        <div class="col-md-4">
          <agc-form-field>
            <label for="">Name <span class="text-danger">*</span></label>
            <input type="text" formControlName="name" class="form-control" required>
            <agc-message when="required">Office Name is required.</agc-message>
          </agc-form-field>

          <agc-form-field>
            <label for="">Country <span class="text-danger">*</span></label>
            <agc-country-input [all]="true" formControlName="countryCode" [(data)]="country" required></agc-country-input>
            <agc-message when="required">Destination Country is required.</agc-message>
          </agc-form-field>

          <agc-form-field>
            <label for="">State/Province</label>
            <agc-state-input [countryCode]="country?.code" formControlName="stateId" [(data)]="state"></agc-state-input>
          </agc-form-field>

          <agc-form-field>
            <label for="">Region/City/LGA</label>
            <agc-region-input [stateId]="state?.id" formControlName="regionId" [(data)]="region"></agc-region-input>
          </agc-form-field>

          <agc-form-field>
            <label for="">Address 1 <span class="text-danger">*</span></label>
            <input type="text" formControlName="address1" class="form-control" required>
            <agc-message when="required">Address 1 is required.</agc-message>
          </agc-form-field>

          <agc-form-field>
            <label for="">Address 2</label>
            <input type="text" formControlName="address2" class="form-control">
          </agc-form-field>
        </div>

        <div class="col-md-4">
          <agc-form-field>
            <label for="">Email</label>
            <input type="text" formControlName="email" class="form-control" email>
            <agc-message when="email">Email does not appear to be valid.</agc-message>
          </agc-form-field>

          <agc-form-field>
            <label for="">Map Position <span class="text-danger">*</span></label>
            <agc-map-input formControlName="coords" required></agc-map-input>
            <agc-message when="required">Map position is required.</agc-message>
          </agc-form-field>
        </div>

        <div class="col-md-4">
          <agc-form-field>
            <label for="">Phones</label>
            <agc-input-array formArrayName="phoneList" (add)="addPhone()">
              @for (item of phoneList.controls; track $index; let i = $index) {
                <agc-input-item (remove)="phoneList.removeAt(i)">
                  <agc-phone-input [country]="country" [formControl]="c(item)" phone></agc-phone-input>
                </agc-input-item>
              }
            </agc-input-array>
          </agc-form-field>

          <agc-form-field>
            <label for="">Business Hours</label>
            <agc-input-array formArrayName="hourList" (add)="addHours()">
              @for (item of hourList.controls; track $index; let i = $index) {
                <agc-input-item (remove)="hourList.removeAt(i)">
                  <agc-office-hour [formControl]="c(item)"></agc-office-hour>
                </agc-input-item>
              }
            </agc-input-array>
          </agc-form-field>

          <agc-form-field>
            <label for="">Services Available</label>
            <div class="services" formGroupName="serviceList">
              @for (item of serviceMap | keyvalue; track $index) {
                <div class="services-item">
                  <label for="srv-{{ item.key }}">
                    <input type="checkbox" [formControlName]="item.key" id="srv-{{ item.key }}">
                    <span>{{ item.value.name }}</span>
                  </label>
                </div>
              }
            </div>
          </agc-form-field>
        </div>
      </div>
    </agc-form>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button class="btn btn-default btn-md waves-effect waves-light" (click)="cancel()">Cancel</button>
    <button cdkFocusInitial class="btn btn-primary btn-md ml-2 waves-effect waves-light" (click)="formRef?.doSubmit()">Submit</button>
  </mat-dialog-actions>
</agc-container>
