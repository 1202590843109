import { inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { WinData } from "../data/WinData";
import { AGC_CONFIG } from "../interfaces/AGCModuleConfig";

type OffsetY = 'top' | 'center' | 'bottom';
type OffsetX = 'left' | 'center' | 'right';

type Options = {
  /**
   * A string, without whitespace, specifying the name of the browsing context the resource is being loaded into.
   * If the name doesn't identify an existing context, a new context is created and given the specified name.
   * The special target keywords, `_self`, `_blank` (default), `_parent`, `_top`, and `_unfencedTop` can also be used.
   * `_unfencedTop` is only relevant to fenced frames.
   *
   * This name can be used as the target attribute of `<a>` or `<form>` elements.
   */
  target?: string;

  /**
   * `width` or `innerWidth`
   *
   * Specifies the width of the content area, including scrollbars. The minimum required value is `100`
   */
  width?: number;

  /**
   * `height` or `innerHeight`
   *
   * Specifies the height of the content area, including scrollbars. The minimum required value is `100`
   */
  height?: number;

  /**
   * `left` or `screenX`
   *
   * Specifies the distance in pixels from the left side of the work area as defined by the user's operating
   * system where the new window will be generated.
   */
  left?: number;

  /**
   * `top` or `screenY`
   *
   * Specifies the distance in pixels from the top side of the work area as defined by the user's operating
   * system where the new window will be generated.
   */
  top?: number;

  offsetX?: OffsetX;

  offsetY?: OffsetY;
};

@Injectable()
export class PopupService {

  private readonly config = inject(AGC_CONFIG);

  payment<T = any>(url: string | URL) {
    return this.open<T>(url, {
      width: 500,
      height: 500,
      offsetX: 'center',
      offsetY: 'center',
    });
  }

  open<T = any>(url: string | URL, options?: Options) {
    const { config: { environment: { api } } } = this;

    let result: WinData<T>;

    return new Observable<WinData<T>>(sub => {
      try {
        const target = options?.target || '_blank';
        const win = window.open(url, target, features(options));

        if (!win) {
          sub.next();
          sub.complete();
          return;
        }

        const onMessage = (event: MessageEvent<any>) => {
          if (!api.startsWith(event.origin)) return;
          result = WinData.fromData<T>(event.data);
          win.close();
        };

        const resolve = (value?: WinData<T>) => {
          sub.next(value);
          sub.complete();
          clear();
        };

        const reject = (err?: any) => {
          sub.error(err);
          sub.complete();
          clear();
        };

        window.addEventListener('message', onMessage);

        const clear = () => {
          window.removeEventListener('message', onMessage);
        };

        let interval = window.setInterval(() => {
          if (!win.closed) return;

          window.clearInterval(interval);

          if (!result) {
            return reject('No result received.');
          }

          if (result.error) {
            return reject(result.message);
          }

          resolve(result);
        }, 100);
      } catch (error) {
        sub.error(error);
        sub.complete();
      }
    });
  }

}


const features = (options?: Options): string => {
  let result = ',popup';

  if (options) {
    if (options.width) {
      const width = Math.max(options.width, 500);
      result += `,width=${width}`;

      let left = 0;
      const xwidth = window.outerWidth;

      switch (options.offsetX) {
        case 'left': left = 0; break;

        case 'center': {
          left = (xwidth / 2) - (width / 2);
        } break;

        case 'right': {
          left = xwidth - width;
        } break;

        default: left = options.left || 0;
      }

      result += `,left=${left}`;
    }

    if (options.height) {
      const height = Math.max(options.height, 500);
      result += `,height=${height}`;

      let top = 0;
      const xheight = window.outerHeight;

      switch (options.offsetY) {
        case 'top': top = 0; break;

        case 'center': {
          top = (xheight / 2) - (height / 2);
        } break;

        case 'bottom': {
          top = xheight - height;
        } break;

        default: top = options.top || 0;
      }

      result += `,top=${top}`;
    }
  }

  if (result) result = result.substring(1);

  return result;
}
