import { ChangeDetectorRef, Component, Input } from "@angular/core";

@Component({
  selector: 'agc-container',
  templateUrl: 'container.component.html',
  styleUrls: ['container.component.scss']
})
export class ContainerComponent {

  private xLoading: boolean = false;

  get loading() { return this.xLoading; }

  @Input() set loading(value: boolean) {
    if (typeof (value) !== 'boolean') value = false;
    this.xLoading = value;
    this.cd.detectChanges();
  };

  constructor(
    private cd: ChangeDetectorRef
  ) {  }

}
