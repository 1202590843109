import { Component, OnDestroy } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Observable, of, Subscription, throwError } from "rxjs";
import { catchError, map, shareReplay, switchMap, tap } from "rxjs/operators";
import { BankDetails, BankFormComponent } from "../../../interfaces/Bank";
import { PaystackAccountResolveData, PaystackAccountResolveForm, PaystackBank, PaystackBankListResponse } from "../../../interfaces/Paystack";
import { LoadingService } from "../../../services/LoadingService";
import { MessageService } from "../../../services/MessageService";
import { PaystackService } from "../../../services/payment/PaystackService";

@Component({
  selector: 'agc-paystack-bank-details-form',
  templateUrl: 'paystack-bank-details-form.component.html',
  styleUrls: ['paystack-bank-details-form.component.scss']
})
export class PaystackBankDetailsFormComponent implements BankFormComponent, OnDestroy {

  loading: boolean = false;

  records: PaystackBankListResponse = null as any;

  info: PaystackAccountResolveData = null as any;
  bank: PaystackBank = null as any;

  form: FormGroup = new FormGroup({
    bankRef: new FormControl(''),
    bankAccountNumber: new FormControl()
  });

  private xSub: Subscription;

  constructor(
    private paystack: PaystackService,
    private loadingService: LoadingService,
    private messageService: MessageService
  ) {
    this.xSub = this.form.valueChanges.subscribe(value => {
      this.info = null as any;
    });

    this.initialize();
  }

  ngOnDestroy() {
    this.xSub.unsubscribe();
  }

  private initialize() {
    const result = this.loadBankList().pipe(
      switchMap(resp => this.initializeAccountDetails())
    );

    result.subscribe();

    return result;
  }

  private initializeAccountDetails() {
    const { form, paystack, messageService } = this;

    this.loading = true;

    const result: Observable<BankDetails> = paystack.getBankDetails().pipe(
      tap(resp => {
        form.patchValue(resp);
        this.loading = false;
      }),

      catchError(error => {
        this.loading = false;
        return of(null as any);
      }),

      shareReplay(1)
    );

    result.subscribe();

    return result;
  }

  private loadBankList() {
    const { paystack, messageService } = this;

    this.loading = true;

    const result = paystack.getAllBanks().pipe(
      tap(resp => {
        this.records = resp;
        this.loading = false;
      }),

      catchError(error => {
        messageService.handle(error, false);
        this.loading = false;
        return throwError(error);
      }),

      shareReplay(1)
    );

    result.subscribe();

    return result;
  }

  onBankChange(bankCode: string) {
    const { records } = this;
    this.bank = records?.data.find(bank => bank.code === bankCode) || null as any;
  }

  resolveAccountDetails() {
    const { form, paystack, loadingService, messageService } = this;
    const data = form.value as PaystackAccountResolveForm;

    this.loading = true;

    paystack.resolveBankAccount(data).subscribe({
      next: resp => {
        this.info = resp.data;
        this.loading = false;
      },

      error: error => {
        messageService.handle(error, false);
        this.loading = false;
      }
    });
  }

  submit() {
    if (!this.info) {
      this.resolveAccountDetails();
    } else {
      const { form, info, bank, paystack, loadingService, messageService } = this;

      const data = form.value as BankDetails;
      data.bankAccountName = info.account_name;
      data.bankName = bank.name;

      loadingService.start();

      paystack.saveBankDetails(data).subscribe({
        next: resp => {
          messageService.alert('', 'Bank Details saved successfully!', 'success');
          loadingService.stop();
          form.patchValue(resp);
        },

        error: error => {
          messageService.handle(error);
        }
      });
    }
  }

}
