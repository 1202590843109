<div class="wrapper">
  <select [(ngModel)]="data.startDayNum" (ngModelChange)="updateModelValue()" class="formdata">
    <option value=""></option>
    @for (day of days | keyvalue; track $index;) {
      <option [value]="day.key">{{ day.value }}</option>
    }
  </select>

  <div class="delim">
    <span>-</span>
  </div>

  <select [(ngModel)]="data.stopDayNum" (ngModelChange)="updateModelValue()" class="formdata">
    <option value=""></option>
    @for (day of days | keyvalue; track $index;) {
      <option [value]="day.key">{{ day.value }}</option>
    }
  </select>

  <div class="delim">
    <span>:</span>
  </div>

  <select [(ngModel)]="data.startTime" (ngModelChange)="updateModelValue()" class="formdata">
    <option value=""></option>
    @for (hour of hours | keyvalue; track $index;) {
      <option [value]="hour.key">{{ hour.value }}</option>
    }
  </select>

  <div class="delim">
    <span>-</span>
  </div>

  <select [(ngModel)]="data.stopTime" (ngModelChange)="updateModelValue()" class="formdata">
    <option value=""></option>
    @for (hour of hours | keyvalue; track $index;) {
      <option [value]="hour.key">{{ hour.value }}</option>
    }
  </select>
</div>
