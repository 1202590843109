import { Component, Inject, ViewChild } from "@angular/core";
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { first } from "rxjs/operators";
import { AGCFormComponent } from "../../components/form/form.component";
import { Role, RoleDialogOptions } from "../../interfaces/Role";
import { AccountService } from "../../services/AccountService";
import { MessageService } from "../../services/MessageService";
import { RoleService } from "../../services/RoleService";

interface PermissionHash {
  [key: string]: boolean;
}

@Component({
  selector: 'app-dialog-role',
  templateUrl: 'role.dialog.html',
  styleUrls: ['role.dialog.scss']
})
export class RoleDialog {

  loading: boolean = false;

  readonly role: Role;

  form: FormGroup = new FormGroup({
    name: new FormControl(),
    description: new FormControl(),
    isRestricted: new FormControl(true),
    permissions: new FormGroup({})
  });

  @ViewChild(AGCFormComponent) formRef?: AGCFormComponent;

  get isRestricted() { return this.form.controls['isRestricted'].value as boolean; }

  constructor(
    private roleService: RoleService,
    private accountService: AccountService,
    private messageService: MessageService,
    private dialogRef: MatDialogRef<RoleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: RoleDialogOptions
  ) {
    this.add = this.add.bind(this);
    this.edit = this.edit.bind(this);

    const role: Role = this.role = (data.role) ? Object.assign({}, data.role) : null as any;
    this.form.patchValue(role || {});

    const permMap: PermissionHash = (!role) ? {} : role.permissions.reduce((resp, item) => {
      resp[item.alias] = true;
      return resp;
    }, {} as PermissionHash);

    const permsGroup = this.form.get('permissions') as FormGroup;

    for (let permission of data.permissions) {
      const ctrl = new FormControl(permission.alias in permMap);
      permsGroup.addControl(permission.alias, ctrl);
    }
  }

  permissions() {
    const form = this.form.get('permissions') as FormGroup;
    const hash = form.value as PermissionHash;
    const value: string[] = [];

    for (const alias in hash) {
      if (hash[alias] !== true) continue;
      value.push(alias);
    }

    return value;
  }

  submit() {
    const { add, edit, form, messageService, dialogRef } = this;
    const submit = (this.role) ? edit : add;

    const data = form.value;
    data.permissions = this.permissions();

    this.loading = true;

    submit(data).subscribe({
      next: resp => {
        this.loading = false;
        dialogRef.close(resp);
      },

      error: error => {
        messageService.handle(error, false);
        this.loading = false;
      }
    });
  }

  private add(data: Role) {
    const { roleService } = this;
    return roleService.add(data);
  }

  private edit(data: Role) {
    const { role, roleService } = this;
    return roleService.update(role.id as any, data);
  }

  cancel() {
    this.dialogRef.close(false);
  }

}
