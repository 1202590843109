import { Directive, Input } from "@angular/core";
import { AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors } from "@angular/forms";
import { Observable, of, timer } from "rxjs";
import { catchError, debounce, delay, map, switchMap } from "rxjs/operators";
import { AccountService } from "../services/AccountService";

@Directive({
  selector: '[phoneAvailable]',
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: AccountPhoneAvailableValidationDirective,
      multi: true
    }
  ]
})
export class AccountPhoneAvailableValidationDirective implements AsyncValidator {

  @Input('phoneAvailable') prefix: string = '';
  @Input() ignore?: string = '';

  constructor(
    private accountService: AccountService,
  ) { }

  validate(control: AbstractControl): Promise<ValidationErrors> | Observable<ValidationErrors> {
    const { prefix = '', ignore = '', accountService } = this;
    const error = { phoneAvailable: true };

    if (!control.value) return of(null as any);

    if (control.value === ignore) {
      return of(null as any);
    }

    return of(null).pipe(
      delay(1000),

      switchMap(() => accountService.phoneAvailable(control.value, prefix)),

      map(resp => {
        if (resp.available) return null as any;
        return error;
      }),

      catchError(resp => of(error))
    );
  }

}
