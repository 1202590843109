<agc-container dialog [loading]="loading">
  <h1 mat-dialog-title>
    <span>{{ title }}</span>

    <small *ngIf="subtitle">
      &nbsp;
      <i class="icofont icofont-double-right"></i>
      &nbsp;
      <span>{{ subtitle }}</span>
    </small>
  </h1>

  <mat-dialog-content class="mat-typography">
    <agc-frame [stickyHeaderTop]="-20">
      <agc-frame-header>
        <div class="row align-items-center">
          <div class="col-auto">
            <h3>DESTINATION</h3>
          </div>
          <div class="col-auto" *ngIf="init">
            <agc-country-input [all]="true" (dataChange)="onCountryChange($event)"></agc-country-input>
          </div>
          <div class="col">
            <h3>State/Province: <u>{{ destState?.name }}</u></h3>
          </div>
        </div>
      </agc-frame-header>

      <agc-frame-sidebar>
        <h6>STATES/PROVINCES</h6>

        <input type="text" class="form-control" placeholder="Search States/Provinces" [(ngModel)]="text" [disabled]="!allStates.length">

        <div class="mt-3">
          <div class="text-center" *ngIf="!stateList.length">
            <span>No state/province found</span>
          </div>

          <ul class="agc-frame-menu" *ngIf="stateList.length">
            <li *ngFor="let state of stateList" [ngClass]="{'active': isDestinationState(state)}">
              <a href="" (click)="setDestinationState(state, $event)">{{ state.name }}</a>
            </li>
          </ul>
        </div>
      </agc-frame-sidebar>

      <agc-frame-content>
        <div class="text-center">
          <div><strong>{{ state.name }}, {{ country.name }}</strong></div>
          <div>to</div>
          <div><strong>{{ destState?.name }}, {{ destCountry?.name }}</strong></div>
        </div>

        <hr>

        <agc-container [loading]="loadingMatrix">
          <div class="text-right m-b-20">
            <button class="btn btn-primary" (click)="add()">
              <i class="fa fa-plus"></i> Add New Matrix
            </button>
          </div>

          <div class="text-center" *ngIf="!matrixList.length">No matrix found</div>

          <table class="table table-striped table-hover table-text" *ngIf="matrixList.length">
            <thead>
              <tr class="footable-header">
                <th>Type</th>
                <th>Weight (kg)</th>
                <th>Amount</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              <tr *ngFor="let data of matrixList">
                <td>{{ data.type }}</td>
                <td>{{ data.minWeight | number:'1.2-2' }} &harr; {{ data.maxWeight | number:'1.2-2' }}</td>
                <td>{{ data | matrixAmount:'/kg' }}</td>
                <td class="text-right">
                  <a href="javascript:;" (click)="edit(data)">
                    <i class="icofont icofont-ui-edit"></i>
                  </a>

                  &nbsp;&nbsp;

                  <a href="javascript:;" (click)="delete(data)">
                    <i class="icofont icofont-trash"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </agc-container>
      </agc-frame-content>
    </agc-frame>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button class="btn btn-default btn-md waves-effect waves-light" (click)="close()">Close</button>
  </mat-dialog-actions>
</agc-container>
