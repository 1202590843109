import { Directive, Input } from "@angular/core";
import { AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors } from "@angular/forms";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AccountService } from "../services/AccountService";

@Directive({
  selector: '[agentNameAvailable]',
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: AgentUsernameAvailableValidationDirective,
      multi: true
    }
  ]
})
export class AgentUsernameAvailableValidationDirective implements AsyncValidator {

  @Input('agentNameAvailable') ignore: string = '';

  constructor(
    private accountService: AccountService
  ) { }

  validate(control: AbstractControl): Promise<ValidationErrors> | Observable<ValidationErrors> {
    const { ignore = '', accountService } = this;
    const error = { agentNameAvailable: true };

    if (!control.value) return of(null as any);

    return accountService.usernameAvailable(control.value, ignore).pipe(
      map(resp => {
        if (resp.available) return null as any;
        return error;
      }),

      catchError(resp => of(error))
    );
  }

}
