import { Pipe, PipeTransform } from "@angular/core";
import { Matrix } from "../interfaces/Matrix";
import { DecimalPipe } from "@angular/common";

@Pipe({ name: 'matrixAmount' })
export class MatrixAmountPipe implements PipeTransform {

  transform(data?: Matrix, postfix?: string) {
    if (!data) return '';

    const decimal = new DecimalPipe('en-US');

    let amount = decimal.transform(data.amount, '1.2-4');
    if (!amount) return '';

    if (data.type !== 'FLAT') {
      amount += postfix;
    }

    return amount;
  }

}
