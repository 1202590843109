import { Component, EventEmitter, inject, Output } from "@angular/core";

import { InputArrayComponent } from "../input-array.component";

@Component({
  selector: 'agc-input-item',
  templateUrl: 'item.component.html',
  styleUrls: ['item.component.scss']
})
export class InputArrayItemComponent {

  private readonly parent = inject(InputArrayComponent);

  @Output() readonly remove = new EventEmitter();

}
