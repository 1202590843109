import { ChangeDetectorRef, Component, Inject, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AGCFormComponent } from "../../../components/form/form.component";
import { MatrixGenerateDialogOptions, MatrixGenerateFormData } from "../../../interfaces/Matrix";
import { CountryService } from "../../../services/CountryService";
import { MatrixService } from "../../../services/MatrixService";
import { MessageService } from "../../../services/MessageService";

@Component({
  selector: 'app-dialog-matrix-generate',
  templateUrl: 'generate.dialog.html',
  styleUrls: ['generate.dialog.scss']
})
export class MatrixGenerateDialog {

  loading: boolean = false;

  @ViewChild(AGCFormComponent) formRef: AGCFormComponent = null as any;

  form: FormGroup = new FormGroup({
    toCountryCode: new FormControl(),
    // type: new FormControl(),
    blocks: new FormControl(4),
    amount: new FormControl(0)
  });

  constructor(
    private cd: ChangeDetectorRef,
    private matrixService: MatrixService,
    private messageService: MessageService,
    private dialogRef: MatDialogRef<MatrixGenerateDialog>,
    @Inject(MAT_DIALOG_DATA) public data: MatrixGenerateDialogOptions
  ) {
    const toCountryCode = data.country.code;
    this.form.patchValue({ toCountryCode });
  }

  submit() {
    const { form, matrixService, messageService, dialogRef } = this;

    const data = form.value as MatrixGenerateFormData;
    data.fromCountryCode = this.data.country.code as string;

    this.loading = true;

    matrixService.generate(data).subscribe({
      next: resp => {
        this.loading = false;

        messageService.alert('', resp.message, 'success').then(() => {
          dialogRef.close(true);
        });
      },

      error: error => {
        messageService.handle(error, false);
        this.loading = false;
      }
    });
  }

  close() {
    this.dialogRef.close(false);
  }

}

