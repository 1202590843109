import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { from, Observable, of, throwError } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { MatrixGenerateDialog } from "../../dialog/matrix/generate/generate.dialog";
import { MatrixDialog } from "../../dialog/matrix/matrix.dialog";
import { MatrixDialogOptions, MatrixGenerateDialogOptions } from "../../interfaces/Matrix";
import { CountryService } from "../CountryService";
import { LoadingService } from "../LoadingService";
import { MatrixService } from "../MatrixService";
import { MessageService } from "../MessageService";
import { StateService } from "../StateService";

@Injectable()
export class MatrixDialogService {

  constructor(
    private dialog: MatDialog,
    private stateService: StateService,
    private matrixService: MatrixService,
    private messageService: MessageService,
    private loadingService: LoadingService,
    private countryService: CountryService
  ) {  }

  private initialize(data: MatrixDialogOptions): Observable<MatrixDialogOptions> {
    const { matrixService, loadingService, countryService, messageService } = this;

    return of(data).pipe(
      // Get Country
      switchMap(resp => {
        if (!data.country) {
          loadingService.start();

          return countryService.find(data.state.countryCode as string).pipe(
            map(resp => {
              data.country = resp;
              loadingService.stop();
              return data;
            }),

            catchError(error => {
              loadingService.stop();
              return throwError(error);
            })
          );
        }

        return of(data);
      })
    );
  }

  manage(data: MatrixDialogOptions): Observable<any> {
    const result = this.initialize(data).pipe(
      switchMap(data => {
        const dialog = this.dialog.open(MatrixDialog, {
          width: '90vw',
          maxWidth: '900px',
          disableClose: true,
          data
        });

        return dialog.afterClosed();
      })
    );

    result.subscribe();

    return result;
  }

  generate(data: MatrixGenerateDialogOptions) {
    const dialog = this.dialog.open(MatrixGenerateDialog, {
      width: '90vw',
      maxWidth: '700px',
      disableClose: true,
      data
    });

    return dialog.afterClosed();
  }

}
