<agc-container dialog [loading]="loading">
  <h1 mat-dialog-title>
    <span>Generate Cost Matrix</span>
  </h1>

  <mat-dialog-content class="mat-typography">
    <agc-form [formGroup]="form" (submit)="submit()">
      <div class="row">
        <!-- COLUMN 1 -->
        <div class="col-md-6">
          <agc-form-field>
            <label for="">From</label>
            <div class="form-control agc-disabled">{{ data.country.name }}</div>
          </agc-form-field>

          <agc-form-field>
            <label for="">To <small>(destination country)</small> <span class="text-danger">*</span></label>
            <agc-country-input [all]="true" formControlName="toCountryCode" required></agc-country-input>
            <agc-message when="required">Country is required.</agc-message>
          </agc-form-field>
        </div>

        <!-- COLUMN 2 -->
        <div class="col-md-6">
          <agc-form-field>
            <label for="">Blocks <small>(rows to generate)</small> <span class="text-danger">*</span></label>
            <input type="number" formControlName="blocks" class="form-control" required>
            <agc-message when="required">Number of blocks is required.</agc-message>
          </agc-form-field>

          <agc-form-field>
            <label for="">Amount <span class="text-danger">*</span></label>
            <input type="number" formControlName="amount" class="form-control">
            <agc-message when="required">Amount is required.</agc-message>
          </agc-form-field>
        </div>
      </div>
    </agc-form>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button class="btn btn-default btn-md waves-effect waves-light" (click)="close()">Close</button>
    <div class="flex"></div>
    <button class="btn btn-success btn-md ml-2 waves-effect waves-light" (click)="formRef.doSubmit()">Generate Matrix</button>
  </mat-dialog-actions>
</agc-container>
