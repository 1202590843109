<agc-container dialog [loading]="loading">
  <h1 mat-dialog-title>
    <span>{{ (data.matrix) ? 'Update' : 'Add New' }} Matrix</span>
  </h1>

  <mat-dialog-content class="mat-typography">
    <agc-form [formGroup]="form" (submit)="submit()">
      <div class="row">
        <!-- COLUMN 1 -->
        <div class="col-md-6">
          <agc-form-field>
            <label for="">Type <span class="text-danger">*</span></label>
            <select formControlName="type" class="form-control" required>
              <option value="FLAT">FLAT</option>
              <option value="RANGE">RANGE</option>
              <option value="PERCENTAGE">Percentage</option>
            </select>
            <agc-message when="required">Matrix type is required.</agc-message>
          </agc-form-field>

          <agc-form-field>
            <label for="">Amount</label>
            <input type="number" formControlName="amount" class="form-control">
            <agc-message when="required">Amount is required.</agc-message>
          </agc-form-field>
        </div>

        <!-- COLUMN 2 -->
        <div class="col-md-6">
          <!-- Weight -->
          <div class="row">
            <div class="col-md-6">
              <agc-form-field>
                <label for="">Min Weight</label>
                <input type="number" formControlName="minWeight" class="form-control">
              </agc-form-field>
            </div>
            <div class="col-md-6">
              <agc-form-field>
                <label for="">Max Weight</label>
                <input type="number" formControlName="maxWeight" class="form-control">
              </agc-form-field>
            </div>
          </div>

          <!-- Height -->
          <div class="row">
            <div class="col-md-6">
              <agc-form-field>
                <label for="">Min Height</label>
                <input type="number" formControlName="minHeight" class="form-control">
              </agc-form-field>
            </div>
            <div class="col-md-6">
              <agc-form-field>
                <label for="">Max Height</label>
                <input type="number" formControlName="maxHeight" class="form-control">
              </agc-form-field>
            </div>
          </div>

          <!-- Width -->
          <div class="row">
            <div class="col-md-6">
              <agc-form-field>
                <label for="">Min Width</label>
                <input type="number" formControlName="minWidth" class="form-control">
              </agc-form-field>
            </div>
            <div class="col-md-6">
              <agc-form-field>
                <label for="">Max Width</label>
                <input type="number" formControlName="maxWidth" class="form-control">
              </agc-form-field>
            </div>
          </div>

          <!-- Length -->
          <div class="row">
            <div class="col-md-6">
              <agc-form-field>
                <label for="">Min Length</label>
                <input type="number" formControlName="minLength" class="form-control">
              </agc-form-field>
            </div>
            <div class="col-md-6">
              <agc-form-field>
                <label for="">Max Length</label>
                <input type="number" formControlName="maxLength" class="form-control">
              </agc-form-field>
            </div>
          </div>
        </div>
      </div>
    </agc-form>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button class="btn btn-default btn-md waves-effect waves-light" (click)="close()">Close</button>
    <div class="flex"></div>
    <button class="btn btn-success btn-md ml-2 waves-effect waves-light" (click)="formRef.doSubmit()">{{ (data.matrix) ? 'Update' : 'Add' }} Matrix</button>
  </mat-dialog-actions>
</agc-container>
