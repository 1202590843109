import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { URLHelper } from "../helpers/URLHelper";
import { AGCModuleConfig } from "../interfaces/AGCModuleConfig";
import { Invoice, InvoiceData, InvoicePagination, PaymentData } from "../interfaces/Transaction";

@Injectable()
export class InvoiceService {

  private xInvoice: Observable<Invoice>;
  private xInvoiceSub: BehaviorSubject<Invoice>;

  get invoice() { return this.xInvoice; }

  constructor(
    private http: HttpClient,
    private urlHelper: URLHelper
  ) {
    this.xInvoiceSub = new BehaviorSubject<Invoice>(null as any);
    this.xInvoice = this.xInvoiceSub.asObservable();
  }

  setInvoice(data: Invoice) {
    this.xInvoiceSub.next(data);
  }

  create(data: InvoiceData) {
    const url = this.urlHelper.endpoint(`/invoice`);
    return this.http.post<Invoice>(url, data);
  }

  all(page: number = 1) {
    const params = new URLSearchParams();
    params.append('page', page.toString());
    const url = this.urlHelper.endpoint(`/invoice?${params.toString()}`);
    return this.http.get<InvoicePagination>(url);
  }

  allForUser(userId: number, page: number = 1) {
    const params = new URLSearchParams();
    params.append('page', page.toString());
    const url = this.urlHelper.endpoint(`/users/${userId}/invoice?${params.toString()}`);
    return this.http.get<InvoicePagination>(url);
  }

  find(id: number) {
    const url = this.urlHelper.endpoint(`/invoice/${id}`);
    return this.http.get<Invoice>(url);
  }

  getView(id: number) {
    const url = this.urlHelper.endpoint(`/invoice/${id}/view`);

    return this.http.get<string>(url, {
      responseType: 'text' as 'json'
    });
  }

  findForUser(userId: number, id: number) {
    const url = this.urlHelper.endpoint(`/users/${userId}/invoice/${id}`);
    return this.http.get<Invoice>(url);
  }

}
