import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { AccountService } from "./AccountService";
import { first, map, switchMap } from "rxjs/operators";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private accountService: AccountService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const { accountService } = this;

    return accountService.token.pipe(
      first(),

      switchMap(token => {
        if (token) {
          const setHeaders = { Authorization: `Bearer ${token}` };
          req = req.clone({ setHeaders });
        }

        return next.handle(req);
      })
    );
  }

}
