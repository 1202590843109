import { Component, EventEmitter, Output } from "@angular/core";
import { MessageService } from "../../services/MessageService";
import { TwoFADialogService } from "../../services/dialog/TwoFADialogService";
import { TwoFAService } from "../../services/TwoFAService";

@Component({
  selector: 'agc-2fa-settings',
  templateUrl: '2fa.component.html',
  styleUrls: ['2fa.component.scss']
})
export class TwoFAComponent {

  loading: boolean;
  enabled: boolean;

  @Output() callback: EventEmitter<any>;

  constructor(
    private service: TwoFAService,
    private messageService: MessageService,
    private dialogService: TwoFADialogService
  ) {
    this.loading = false;
    this.enabled = false;

    this.callback = new EventEmitter<any>();

    this.initialize();
  }

  initialize() {
    const { service, messageService } = this;

    this.loading = true;

    service.status().subscribe({
      next: resp => {
        this.enabled = resp.enabled;
      },

      error: error => {
        messageService.handle(error, false);
        this.loading = false;
      },

      complete: () => {
        this.loading = false;
      }
    });
  }

  disable() {
    const { service, messageService } = this;

    messageService.confirm(
      '2FA Security',
      'You are about to disable 2FA security, which is strongly not recommended. Do you really want to do this?',
      'warning'
    ).then(value => {
      if (value !== true) return;

      this.loading = true;

      service.disable().subscribe({
        next: resp => {
          this.initialize();
        },

        error: error => {
          messageService.handle(error, false);
          this.loading = false;
        }
      });
    });
  }

  manage() {
    const { dialogService } = this;

    dialogService.manage().subscribe({
      next: resp => {
        this.callback.next(resp);

        if (resp === true) {
          this.initialize();
        }
      }
    });
  }

}
