import { Inject, Injectable } from '@angular/core';
import { AGCModuleConfig } from '../interfaces/AGCModuleConfig';

type GoogleMapCallback = () => void;

let GoogleMapInitialized = false;
const GoogleMapCallbackList: GoogleMapCallback[] = [];

function AGCInitializeGoogleMap(): void {
  let callback: GoogleMapCallback;

  // @ts-ignore
  // tslint:disable-next-line: no-conditional-assignment
  while (callback = GoogleMapCallbackList.shift()) {
    callback();
  }

  GoogleMapInitialized = true;
}

(window as any).AGCInitializeGoogleMap = AGCInitializeGoogleMap;

@Injectable()
export class GoogleMapService {

  constructor(
    @Inject('AGC') private agc: AGCModuleConfig
  ) {
    const { environment: { googleMap } } = agc;

    const params = new URLSearchParams();
    params.append('key', googleMap.API_KEY);
    params.append('callback', 'AGCInitializeGoogleMap');

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `https://maps.googleapis.com/maps/api/js?${params.toString()}`;
    script.async = true;

    document.body.appendChild(script);
  }

  init(callback: GoogleMapCallback): void {
    if (!GoogleMapInitialized) {
      GoogleMapCallbackList.push(callback);
    } else {
      callback();
    }
  }

}
