import { Component, inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { TopupDialogService } from "../../services/dialog/TopupDialogService";
import { Wallet } from "../../interfaces/Wallet";
import { WalletService } from "../../services/WalletService";
import { MessageService } from "../../services/MessageService";
import { finalize, first, map, of, switchMap, throwError } from "rxjs";

@Component({
  selector: 'app-dialog-wallet',
  templateUrl: 'wallet.dialog.html',
  styleUrls: ['wallet.dialog.scss']
})
export class WalletDialog {

  private readonly service = inject(WalletService);
  private readonly messageService = inject(MessageService);
  private readonly topupDialog = inject(TopupDialogService);
  private readonly dialogRef = inject(MatDialogRef<WalletDialog>);

  get wallets() { return this.service.wallets; }

  get wallet() {
    return this.wallets.pipe(
      map(wallets => wallets.length ? wallets[0] : null)
    );
  }

  public loading = false;

  refresh() {
    const { service, messageService } = this;

    this.loading = true;

    service.get().pipe(
      finalize(() => this.loading = false)
    ).subscribe({
      error: error => messageService.handle(error, false)
    });
  }

  topup() {
    const { topupDialog, wallet } = this;

    wallet.pipe(
      first(),

      switchMap(resp => {
        if (!resp) return of(null);
        return topupDialog.open({ wallet: resp });
      }),
    ).subscribe(value => {
      if (value) this.refresh();
    });
  }

  close() {
    const { dialogRef } = this;
    dialogRef.close();
  }

}
