import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild, booleanAttribute, inject } from "@angular/core";
import { Subscription } from "rxjs";
import { Menu } from "../../interfaces/Menu";
import { Account } from "../../interfaces/Account";
import { ActivationEnd, NavigationStart, Router } from "@angular/router";
import { AccountService } from '../../services/AccountService';
import { LoadingService } from '../../services/LoadingService';
import { MenuService } from '../../services/MenuService';
import { MessageService } from '../../services/MessageService';
import { ShippingDialogService } from '../../services/dialog/ShippingDialogService';
import { filter, finalize, map } from "rxjs/operators";
import { DownloadService } from "../../services/DownloadService";

import { WalletService } from '../../services/WalletService';
import { WalletDialogService } from '../../services/dialog/WalletDialogService';
import { Wallet } from "../../interfaces/Wallet";

const defaultVerticalMenu = {
  desktop: 'expanded', // value should be offcanvas/collapsed/expanded/compact/compact-acc/fullpage/ex-popover/sub-expanded
  tablet: 'collapsed', // value should be offcanvas/collapsed/expanded/compact
  phone: 'offcanvas' // value should be offcanvas/collapsed/expanded/compact
};

const onToggleVerticalMenu = {
  desktop: 'collapsed', // value should be offcanvas/collapsed/expanded/compact
  tablet: 'expanded', // value should be offcanvas/collapsed/expanded/compact
  phone: 'expanded', // value should be offcanvas/collapsed/expanded/compact
};

const verticalMenuEffect = {
  desktop: "shrink",
  tablet: "push",
  phone: "overlay",
};

declare const toggleFullScreen: () => void;

@Component({
  selector: 'agc-home-frame',
  templateUrl: 'home-frame.component.html',
  styleUrls: ['home-frame.component.scss']
})
export class HomeFrameComponent implements AfterViewInit, OnDestroy {

  private readonly router = inject(Router);
  private readonly menuService = inject(MenuService);
  private readonly accountService = inject(AccountService);
  private readonly messageService = inject(MessageService);
  private readonly downloadService = inject(DownloadService);
  private readonly loadingService = inject(LoadingService);
  private readonly shippingDialogService = inject(ShippingDialogService);

  private readonly walletService = inject(WalletService);
  private readonly walletDialogService = inject(WalletDialogService);
  private readonly el = inject(ElementRef<HTMLElement>);

  @Input() name: string = 'Portal';
  @Input() theme: string = 'theme1';

  @Input({ transform: booleanAttribute })
  showDownloadScanner: boolean = false;

  @Input({ transform: booleanAttribute })
  showCreateShipment: boolean = true;

  @Input({ transform: booleanAttribute })
  showCreateShipmentLog: boolean = true;

  @Input({ transform: booleanAttribute })
  showWallet: boolean = true;

  title: string = '';
  subscription: Subscription;

  account?: Account;
  accountSub: Subscription;

  menuList: Menu[] = [];
  private xMenuSub: Subscription;

  showMenu: boolean = false;

  get wallets() { return this.walletService.wallets; }

  get wallet() {
    return this.wallets.pipe(
      map(wallets => wallets.length ? wallets[0] : null)
    );
  }

  // wallet?: Wallet;
  // walletList: Wallet[] = [];

  @ViewChild('pcodedRef') pcodedRef: ElementRef<HTMLElement> = null as any;
  @ViewChild('navRightRef') navRightRef: ElementRef<HTMLElement> = null as any;

  get canShowMore() {
    return (
      this.showDownloadScanner &&
      this.showCreateShipment
    );
  }

  constructor() {
    const { accountService, menuService, router } = this;

    this.updateDeviceSize = this.updateDeviceSize.bind(this);

    this.accountSub = accountService.account.subscribe(account => {
      this.account = account;
    });

    this.xMenuSub = menuService.menuList.subscribe(menuList => {
      this.menuList = menuList;
    });

    this.subscription = router.events.pipe(
      filter(event => event instanceof NavigationStart)
    ).subscribe(() => {
      this.hideMenu();
    });

    this.subscription = router.events.pipe(
      filter(event => event instanceof ActivationEnd),
      map(event => event as ActivationEnd)
    ).subscribe(nav => {
      const { firstChild, data } = nav.snapshot;

      if (firstChild) return;

      this.title = data['title'] || '';
    });
  }

  ngOnDestroy() {
    this.accountSub.unsubscribe();
    this.xMenuSub.unsubscribe();
    this.subscription.unsubscribe();
  }

  activeClass(menu: Menu) {
    let result = 'active';

    if (menu.children?.length) {
      result += ' pcoded-trigger';
    }

    return result;
  }

  toggleNavRight() {
    const { nativeElement: navRight } = this.navRightRef;
    navRight.classList.toggle('nshow');
  }

  logout(e?: Event) {
    if (e) e.preventDefault();

    const { router, accountService, loadingService, messageService } = this;

    loadingService.start();

    accountService.logout().subscribe({
      next: resp => {
        router.navigate(['/']);
      },

      error: messageService.handle,

      complete: () => {
        loadingService.stop();
      }
    });
  }

  handleMenuOnClick() {
    const { nativeElement: pcoded } = this.pcodedRef;

    const totalWidth = window.innerWidth;
    const dt = pcoded.getAttribute('pcoded-device-type');
    // $('#mobile-collapse,.sidebar_toggle a, .pcoded-overlay-box,.menu-toggle a')

    // $(this).parent().find('.menu-icon').toggleClass("is-clicked");
    // var dt = $('#' + oid).attr("pcoded-device-type");

    switch (dt) {
      case 'desktop': {
        const dmc = onToggleVerticalMenu.desktop;
        const dm = defaultVerticalMenu.desktop;
        const dn = pcoded.getAttribute('vertical-nav-type');
        if (dn === dm) {
          pcoded.setAttribute('vertical-nav-type', dmc);
        } else if (dn === dmc) {
          pcoded.setAttribute('vertical-nav-type', dm);
        } else {
          return;
        }
      } break;

      case 'tablet': {
        const tmc = onToggleVerticalMenu.tablet;
        const tm = defaultVerticalMenu.tablet;
        const tn = pcoded.getAttribute('vertical-nav-type');
        if (tn === tm) {
          pcoded.setAttribute('vertical-nav-type', tmc);
        } else if (tn === tmc) {
          pcoded.setAttribute('vertical-nav-type', tm);
        }
      } break;

      case 'phone': {
        const pmc = onToggleVerticalMenu.phone;
        const pm = defaultVerticalMenu.phone;
        const pn = pcoded.getAttribute('vertical-nav-type');
        if (pn === pm) {
          pcoded.setAttribute('vertical-nav-type', pmc);
        } else if (pn === pmc) {
          pcoded.setAttribute('vertical-nav-type', pm);
        }
      } break;
    }

    pcoded.classList.add('pcoded-toggle-animate');
    setTimeout(() => {
      pcoded.classList.remove('pcoded-toggle-animate');
    }, 250);
  }

  hideMenu() {
    this.showMenu = false;

    const { nativeElement: pcoded } = this.pcodedRef;
    const dt = pcoded.getAttribute('pcoded-device-type');
    // $('#mobile-collapse,.sidebar_toggle a, .pcoded-overlay-box,.menu-toggle a')

    // $(this).parent().find('.menu-icon').toggleClass("is-clicked");
    // var dt = $('#' + oid).attr("pcoded-device-type");

    switch (dt) {
      case 'tablet': {
        const tm = defaultVerticalMenu.tablet;
        pcoded.setAttribute('vertical-nav-type', tm);
      } break;

      case 'phone': {
        const pm = defaultVerticalMenu.phone;
        pcoded.setAttribute('vertical-nav-type', pm);
      } break;
    }

    pcoded.classList.add('pcoded-toggle-animate');
    setTimeout(() => {
      pcoded.classList.remove('pcoded-toggle-animate');
    }, 250);
  }

  toggleMenu(e: Event) {
    e.preventDefault();
    this.showMenu = !this.showMenu;
    this.handleMenuOnClick();
    // mobile-collapse
    // $('.pcoded-navbar').toggleClass('show-menu')
  }

  fullScreen(e: Event) {
    e.preventDefault();
    toggleFullScreen();
  }

  ngAfterViewInit() {
    window.setTimeout(() => {
      this.updateDeviceSize();

      window.addEventListener('resize', this.updateDeviceSize);
      window.addEventListener('orientationchange', this.updateDeviceSize);

      if (this.showWallet) this.fetchWallet();
    });
  }

  updateDeviceSize() {
    const { nativeElement } = this.el;
    const { nativeElement: navRight } = this.navRightRef;
    const pcoded = nativeElement.querySelector('#pcoded') as HTMLElement;

    // 992px

    const totalWidth = window.innerWidth;

    if (totalWidth >= 768 && totalWidth <= 992) {
      pcoded.setAttribute('pcoded-device-type', 'tablet');

      let value = defaultVerticalMenu.tablet;
      if (value !== undefined && value !== "") {
        pcoded.setAttribute('vertical-nav-type', value);
      } else {
        pcoded.setAttribute('vertical-nav-type', 'collapsed');
      }

      let ev = verticalMenuEffect.tablet;
      if (ev !== undefined && value !== "") {
        pcoded.setAttribute('vertical-effect', ev);
      } else {
        pcoded.setAttribute('vertical-effect', 'shrink');
      }

    } else if (totalWidth < 768) {
      pcoded.setAttribute('pcoded-device-type', 'phone');

      let value = defaultVerticalMenu.phone;
      if (value !== undefined && value !== "") {
        pcoded.setAttribute('vertical-nav-type', value);
      } else {
        pcoded.setAttribute('vertical-nav-type', 'offcanvas');
      }

      var ev = verticalMenuEffect.phone;
      if (ev !== undefined && value !== "") {
        pcoded.setAttribute('vertical-effect', ev);
      } else {
        pcoded.setAttribute('vertical-effect', 'push');
      }

    } else {
      pcoded.setAttribute('pcoded-device-type', 'desktop');

      var value = defaultVerticalMenu.desktop;
      if (value !== undefined && value !== "") {
        pcoded.setAttribute('vertical-nav-type', value);
      } else {
        pcoded.setAttribute('vertical-nav-type', 'expanded');
      }

      let ev = verticalMenuEffect.desktop;
      if (ev !== undefined && value !== "") {
        pcoded.setAttribute('vertical-effect', ev);
      } else {
        pcoded.setAttribute('vertical-effect', 'shrink');
      }
    }
  }

  downloadScanner(e?: MouseEvent) {
    if (e) e.preventDefault();
    const { downloadService } = this;
    const url = downloadService.scanner('android');
    window.open(url, '_blank');
  }

  addNewShipment() {
    const { shippingDialogService } = this;
    shippingDialogService.add().subscribe();
  }

  addNewShipmentLog() {
    const { shippingDialogService } = this;
    shippingDialogService.log();
  }

  fetchWallet() {
    const { loadingService, walletService, messageService } = this;

    loadingService.start();

    walletService.get().pipe(
      finalize(() => loadingService.stop()),
    ).subscribe({
      // next: resp => {
      //   this.walletList = resp;

      //   if (resp.length) {
      //     this.wallet = resp[0];
      //   }
      // },

      error: error => messageService.handle(error, false)
    });
  }

  openWallet() {
    const { walletDialogService } = this;

    walletDialogService.open().subscribe(() => {
      // Refresh wallet
    });
  }

}
