import { AfterViewInit, Component, Inject, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { AGCFormComponent } from "../../components/form/form.component";
import { Admin, AdminDialogOptions } from "../../interfaces/Admin";
import { Country } from "../../interfaces/Country";
import { AdminService } from "../../services/AdminService";
import { MessageService } from "../../services/MessageService";

@Component({
  selector: 'app-dialog-admins-admin',
  templateUrl: 'admin.dialog.html',
  styleUrls: ['admin.dialog.scss']
})
export class AdminDialog {

  readonly admin: Admin;

  form: FormGroup = new FormGroup({
    firstName: new FormControl(),
    lastName: new FormControl(),
    otherNames: new FormControl(),
    phone: new FormControl(),
    email: new FormControl(),
    username: new FormControl(),
    roleId: new FormControl(),
    password: new FormControl(),
  });

  @ViewChild(AGCFormComponent) formRef?: AGCFormComponent;

  loading: boolean = false;

  country?: Country;

  constructor(
    private adminService: AdminService,
    private messageService: MessageService,
    private dialogRef: MatDialogRef<AdminDialog>,
    @Inject(MAT_DIALOG_DATA) public data: AdminDialogOptions
  ) {
    const admin = this.admin = data.admin || null as any;
    if (admin) this.form.patchValue(admin);

    this.create = this.create.bind(this);
    this.edit = this.edit.bind(this);
  }

  onCountryChange(country: Country) {
    this.country = country;
  }

  submit() {
    const { form, create, edit, messageService, dialogRef } = this;
    const submit = (this.admin) ? edit : create;

    this.loading = true;

    submit(form.value).subscribe({
      next: resp => {
        dialogRef.close(resp);
      },

      error: error => {
        messageService.handle(error, false);
        this.loading = false;
      },

      complete: () => {
        this.loading = false;
      }
    });
  }

  private create(data: Admin) {
    const { adminService } = this;
    return adminService.create(data);
  }

  private edit(data: Admin) {
    const { admin, adminService } = this;
    const id = admin.id as number;
    return adminService.update(id, data);
  }

  cancel() {
    const { dialogRef } = this;
    dialogRef.close();
  }

}
