import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { URLHelper } from "../helpers/URLHelper";
import { Matrix, MatrixAlgorithm, MatrixAlgorithmFormData, MatrixGenerateFormData } from "../interfaces/Matrix";
import { DefaultResponse } from "../interfaces/Response";

@Injectable()
export class MatrixService {

  constructor(
    private http: HttpClient,
    private urlHelper: URLHelper
  ) {  }

  all(fromStateId: number, toStateId?: number) {
    const params = new URLSearchParams();
    params.append('fromStateId', fromStateId.toString());

    if (toStateId) {
      params.append('toStateId', toStateId.toString());
    }

    const url = this.urlHelper.endpoint(`/matrix?${params.toString()}`);
    return this.http.get<Matrix[]>(url);
  }

  getAlgorithm(fromStateId: string | number, toStateId: string | number) {
    const params = new URLSearchParams();
    params.set('fromStateId', fromStateId.toString());
    params.set('toStateId', toStateId.toString());

    const url = this.urlHelper.endpoint(`/matrix/algorithm?${params.toString()}`);
    return this.http.get<MatrixAlgorithm>(url);
  }

  setAlgorithm(data: MatrixAlgorithmFormData) {
    const url = this.urlHelper.endpoint('/matrix/algorithm');
    return this.http.post<MatrixAlgorithm>(url, data);
  }

  generate(data: MatrixGenerateFormData) {
    const url = this.urlHelper.endpoint('/matrix/generate');
    return this.http.post<DefaultResponse>(url, data);
  }

  add(data: Matrix) {
    const url = this.urlHelper.endpoint('/matrix');
    return this.http.post<Matrix>(url, data);
  }

  edit(id: string | number, data: Matrix) {
    const url = this.urlHelper.endpoint(`/matrix/${id}`);
    return this.http.patch<Matrix>(url, data);
  }

  delete(id: string | number) {
    const url = this.urlHelper.endpoint(`/matrix/${id}`);
    return this.http.delete<Matrix>(url);
  }

}
