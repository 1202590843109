import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";

import { RoutingMilestoneDialog } from "../../dialog/routings/milestone/milestone.dialog";
import { RoutingMilestone } from "../../interfaces/Routing";

@Injectable()
export class RoutingMilestoneDialogService {

  constructor(
    private dialog: MatDialog
  ) {  }

  open(milestone: RoutingMilestone = null as any): Observable<RoutingMilestone> {
    const dialog = this.dialog.open(RoutingMilestoneDialog, {
      width: '90vw',
      maxWidth: '700px',
      disableClose: true,
      data: milestone
    });

    return dialog.afterClosed();
  }

}
