import { Pipe, PipeTransform } from "@angular/core";
import { Account } from "../interfaces/Account";

@Pipe({ name: 'fullname' })
export class FullnamePipe implements PipeTransform {

  transform(data?: Account, companyName: boolean = true) {
    if (!data) return '';

    if (companyName === true && data.meta && data.meta.companyName) {
      return data.meta.companyName;
    }

    const names = [data.firstName];
    if (data.otherNames) names.push(data.otherNames);
    names.push(data.lastName);

    return names.join(' ');
  }

}
