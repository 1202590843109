import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { URLHelper } from "../helpers/URLHelper";
import { AGCModuleConfig } from "../interfaces/AGCModuleConfig";
import { Region, RegionPagination } from "../interfaces/Region";

interface CountryRegionsHash {
  [key: string]: Region[];
}

@Injectable()
export class RegionService {

  private xHash: CountryRegionsHash = {};

  private xRegion: Observable<Region>;
  private xRegionSub: BehaviorSubject<Region>;

  get region() { return this.xRegion; }

  constructor(
    private http: HttpClient,
    private urlHelper: URLHelper
  ) {
    this.xRegionSub = new BehaviorSubject<Region>(null as any);
    this.xRegion = this.xRegionSub.asObservable();
  }

  setRegion(value: Region | null) {
    this.xRegionSub.next(value as any);
  }

  all(stateId: number, refresh: boolean = false) {
    const url = this.urlHelper.url(`/regions?stateId=${stateId}`);
    return this.http.get<Region[]>(url);
  }

  search(stateId: number, page: number = 1) {
    const params = new URLSearchParams();
    params.append('stateId', String(stateId));
    params.append('page', page.toString());

    const url = this.urlHelper.endpoint(`/regions?${params.toString()}`);
    return this.http.get<RegionPagination>(url);
  }

  find(id: number) {
    const url = this.urlHelper.endpoint(`/regions/${id}`);
    return this.http.get<Region>(url);
  }

  add(data: Region) {
    const url = this.urlHelper.endpoint(`/regions`);
    return this.http.post<Region>(url, data);
  }

  update(id: number, data: Region) {
    const url = this.urlHelper.endpoint(`/regions/${id}`);
    return this.http.patch<Region>(url, data);
  }

  toggle(id: number) {
    const url = this.urlHelper.endpoint(`/regions/toggle`);
    return this.http.post<Region>(url, { id });
  }

}
