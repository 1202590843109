import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { switchMap } from "rxjs/operators";
import { InvoiceDialog } from "../../dialog/invoice/invoice.dialog";
import { Invoice } from "../../interfaces/Transaction";
import { ShippingService } from "../ShippingService";

@Injectable()
export class InvoiceDialogService {

  constructor(
    private dialog: MatDialog,
    private shippingService: ShippingService
  ) {  }

  open(invoice: Invoice, enableWallet?: boolean): Observable<Invoice> {
    const dialog = this.dialog.open(InvoiceDialog, {
      width: '90vw',
      maxWidth: '700px',
      disableClose: true,
      data: { invoice, enableWallet }
    });

    return dialog.afterClosed();
  }

  openForShipping(shippingId: number) {
    const { shippingService } = this;

    return shippingService.getLastInvoice(shippingId).pipe(
      switchMap(invoice => this.open(invoice))
    );
  }

  openById(invoiceId: number) {
    //
  }

}
