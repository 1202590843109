import { AfterViewInit, Component, Inject, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AGCFormComponent } from "../../../components/form/form.component";

import { Country } from "../../../interfaces/Country";
import { Routing } from "../../../interfaces/Routing";
import { State } from "../../../interfaces/State";
import { MessageService } from "../../../services/MessageService";
import { RoutingService } from "../../../services/RoutingService";

interface DialogData {
  country: Country;
  state: State;
}

@Component({
  selector: 'app-dialog-routings-add',
  templateUrl: 'add.dialog.html',
  styleUrls: ['add.dialog.scss']
})
export class RoutingsAddDialog {

  public form: FormGroup = new FormGroup({
    toCountryCode: new FormControl(),
    toStateId: new FormControl(),
  });

  @ViewChild(AGCFormComponent) formRef?: AGCFormComponent;

  country?: Country;

  loading: boolean = false;

  constructor(
    private routingService: RoutingService,
    private messageService: MessageService,
    private dialogRef: MatDialogRef<RoutingsAddDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  submit() {
    const { form, routingService, messageService, dialogRef } = this;
    const { country, state } = this.data;

    const data = form.value as Routing;
    data.fromCountryCode = country.code as string;
    data.fromStateId = state.id as number;

    this.loading = true;

    routingService.add(data).subscribe({
      next: resp => {
        dialogRef.close(resp);
      },

      error: error => {
        messageService.handle(error, false);
        this.loading = false;
      },

      complete: () => {
        this.loading = false;
      }
    });
  }

  cancel() {
    const { dialogRef } = this;
    dialogRef.close();
  }

}
