import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { AgentsAddDialog } from "../../dialog/agents/add/add.dialog";
import { Agent } from "../../interfaces/Agent";

@Injectable()
export class AgentDialogService {

  constructor(
    private dialog: MatDialog
  ) {  }

  add(data: Agent = null as any): Observable<Agent> {
    const dialog = this.dialog.open(AgentsAddDialog, {
      width: '90vw',
      maxWidth: '800px',
      disableClose: true,
      data
    });

    return dialog.afterClosed();
  }

}
