import { Directive, Input } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from "@angular/forms";

@Directive({
  selector: '[digits]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: DigitsValidationDirective,
      multi: true
    }
  ]
})
export class DigitsValidationDirective implements Validator {

  private xDigits: number = 0;

  get digits() { return this.xDigits; }

  @Input() set digits(value: number) {
    if (typeof (value) !== 'number') value = 0;
    this.xDigits = value;
  }

  constructor() {}

  validate(control: AbstractControl): ValidationErrors {
    const { digits } = this;
    const error = { digits: true };

    const value = String(control.value);
    if (value.length !== digits) return error;

    return null as any;
  }

}
