import { Component } from "@angular/core";

@Component({
  selector: 'agc-frame-content',
  templateUrl: 'content.component.html',
  styleUrls: ['content.component.scss']
})
export class FrameContentComponent {

  constructor() {}

}
