import { Component, Inject, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AGCFormComponent } from "../../../components/form/form.component";
import { ShippingItem } from "../../../interfaces/Shipping";
import { MessageService } from "../../../services/MessageService";


@Component({
  selector: 'app-dialog-shipping-item',
  templateUrl: 'item.dialog.html',
  styleUrls: ['item.dialog.scss']
})
export class ShippingItemDialog {

  form: FormGroup = new FormGroup({
    name: new FormControl(),
    description: new FormControl(),
    note: new FormControl(),
    weight: new FormControl(),
    length: new FormControl(),
    height: new FormControl(),
    width: new FormControl(),
    quantity: new FormControl(1),
  });

  @ViewChild(AGCFormComponent) formRef?: AGCFormComponent;

  constructor(
    private messageService: MessageService,
    private dialogRef: MatDialogRef<ShippingItemDialog>,
    @Inject(MAT_DIALOG_DATA) public data: ShippingItem
  ) {
    const { form } = this;
    if (data) form.patchValue(data);
  }

  submit() {
    const { form, dialogRef } = this;
    dialogRef.close(form.value);
  }

  cancel() {
    const { dialogRef } = this;
    dialogRef.close();
  }

}
