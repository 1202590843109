import { AfterViewInit, Component, Inject, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Business } from "../../interfaces/Business";

import { AGCFormComponent } from "../../components/form/form.component";
import { Country } from "../../interfaces/Country";
import { State } from "../../interfaces/State";
import { BusinessService } from "../../services/BusinessService";
import { MessageService } from "../../services/MessageService";

@Component({
  selector: 'app-dialog-businesses',
  templateUrl: 'businesses.dialog.html',
  styleUrls: ['businesses.dialog.scss']
})
export class BusinessesDialog {

  form: FormGroup = new FormGroup({
    firstName: new FormControl(),
    lastName: new FormControl(),
    otherNames: new FormControl(),
    countryCode: new FormControl(),
    stateId: new FormControl(),
    regionId: new FormControl(),
    phone: new FormControl(),
    username: new FormControl(),
    email: new FormControl(),
    password: new FormControl(),
    meta: new FormGroup({
      companyName: new FormControl()
    })
  });

  @ViewChild(AGCFormComponent) formRef?: AGCFormComponent;

  loading: boolean = false;

  country?: Country;
  state?: State;

  constructor(
    private businessService: BusinessService,
    private messageService: MessageService,
    private dialogRef: MatDialogRef<BusinessesDialog>,
    @Inject(MAT_DIALOG_DATA) public data: Business
  ) {
    const { form } = this;
    if (data) form.patchValue(data);
  }

  submit() {
    const { form, businessService, messageService, dialogRef } = this;

    this.loading = true;

    businessService.create(form.value).subscribe({
      next: resp => {
        dialogRef.close(resp);
      },

      error: error => {
        messageService.handle(error, false);
        this.loading = false;
      },

      complete: () => {
        this.loading = false;
      }
    });
  }

  cancel() {
    const { dialogRef } = this;
    dialogRef.close();
  }

}
