import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Account } from "../../interfaces/Account";
import { AccountService } from "../../services/AccountService";
import { LoadingService } from "../../services/LoadingService";
import { MessageService } from "../../services/MessageService";

@Component({
  selector: 'agc-account-review',
  templateUrl: 'account-review.component.html',
  styleUrls: ['account-review.component.scss']
})
export class AccountReviewComponent {

  @Input() account?: Account;

  @Output() callback: EventEmitter<Account>;

  constructor(
    private accountService: AccountService,
    private messageService: MessageService,
    private loadingService: LoadingService
  ) {
    this.callback = new EventEmitter(null as any);
  }

  approve() {
    const { account, messageService, accountService, loadingService } = this;

    messageService.confirm(
      'Approve Account',
      'Do you really want to APPROVE this account?',
      'warning'
    ).then(value => {
      if (!value) return;

      loadingService.start();

      accountService.approve(account!.id! as any).subscribe({
        next: resp => {
          this.callback.emit(resp);
          loadingService.stop();
        },

        error: error => {
          messageService.handle(error);
        }
      });
    });
  }

  decline() {
    const { account, messageService, accountService, loadingService } = this;

    messageService.confirm(
      'Decline Account',
      'Do you really want to DECLINE this account?',
      'warning'
    ).then(value => {
      if (!value) return;

      loadingService.start();

      accountService.decline(account!.id! as any).subscribe({
        next: resp => {
          this.callback.emit(resp);
          loadingService.start();
        },

        error: error => {
          messageService.handle(error);
        }
      });
    });
  }

}
