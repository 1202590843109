import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { URLHelper } from "../helpers/URLHelper";
import { AGCModuleConfig } from "../interfaces/AGCModuleConfig";
import { Shipping, ShippingPagination } from "../interfaces/Shipping";
import { Invoice } from "../interfaces/Transaction";

@Injectable()
export class ShippingService {

  private xShipping: Observable<Shipping>;
  private xShippingSub: BehaviorSubject<Shipping>;

  get shipping() { return this.xShipping; }

  constructor(
    private http: HttpClient,
    private urlHelper: URLHelper
  ) {
    this.xShippingSub = new BehaviorSubject<Shipping>(null as any);
    this.xShipping = this.xShippingSub.asObservable();
  }

  setShipping(data: Shipping) {
    this.xShippingSub.next(data);
  }

  all(page: number = 1) {
    const params = new URLSearchParams();
    params.append('page', page.toString());
    const url = this.urlHelper.endpoint(`/shipping?${params.toString()}`);
    return this.http.get<ShippingPagination>(url);
  }

  allForUser(userId: number, page: number = 1) {
    const params = new URLSearchParams();
    params.append('page', page.toString());
    const url = this.urlHelper.endpoint(`/users/${userId}/shipping?${params.toString()}`);
    return this.http.get<ShippingPagination>(url);
  }

  allForAgent(agentId: number, page: number = 1) {
    const params = new URLSearchParams();
    params.append('page', page.toString());
    const url = this.urlHelper.endpoint(`/agents/${agentId}/shipping?${params.toString()}`);
    return this.http.get<ShippingPagination>(url);
  }

  allForBusiness(businessId: number, page: number = 1) {
    const params = new URLSearchParams();
    params.append('page', page.toString());
    const url = this.urlHelper.endpoint(`/businesses/${businessId}/shipping?${params.toString()}`);
    return this.http.get<ShippingPagination>(url);
  }

  find(id: number) {
    const url = this.urlHelper.endpoint(`/shipping/${id}`);
    return this.http.get<Shipping>(url);
  }

  findForUser(userId: number, id: number) {
    const url = this.urlHelper.endpoint(`/users/${userId}/shipping/${id}`);
    return this.http.get<Shipping>(url);
  }

  create(data: Shipping) {
    const url = this.urlHelper.endpoint(`/shipping`);
    return this.http.post<Shipping>(url, data);
  }

  getAllInvoices(shippingId: number) {
    const url = this.urlHelper.endpoint(`/shipping/${shippingId}/invoices`);
    return this.http.get<Invoice[]>(url);
  }

  getLastInvoice(shippingId: number) {
    const url = this.urlHelper.endpoint(`/shipping/${shippingId}/invoice`);
    return this.http.get<Invoice>(url);
  }

  getLabels(shippingId: number) {
    const url = this.urlHelper.endpoint(`/shipping/${shippingId}/labels`);
    return this.http.get<Shipping>(url);
  }

}
