<agc-container dialog [loading]="loading">
  <h1 mat-dialog-title>ADD NEW SHIPMENT</h1>

  <mat-dialog-content class="mat-typography">
    <div *ngIf="!user">
      <div class="user-search">
        <form name="SearchForm" (submit)="findUser()">
          <!--  -->
          <h2 class="text-center">User Information</h2>

          <div class="form-group mb-3">
            <select name="field" [(ngModel)]="searchData.field" class="form-control">
              <option [value]="field.value" *ngFor="let field of fieldList">{{ field.label }}</option>
            </select>
          </div>

          <div class="form-group mb-3">
            <input type="text" name="value" [(ngModel)]="searchData.value" [placeholder]="placeholder" class="form-control">
          </div>

          <button type="submit" class="btn btn-primary btn-block">
            <span>Continue &raquo;</span>
          </button>

          <div class="mt-3 text-center">
            <a href="javascript:;" (click)="cancel()">Cancel</a>
          </div>
        </form>
      </div>
    </div>

    <agc-form [formGroup]="form" (submit)="submit()" *ngIf="user">
      <div class="row">
        <div class="col-md-4">
          <h2>Shipper's Details <small>(<strong>User ID:</strong> {{ user.id }})</small></h2>

          <agc-form-field>
            <label for="">Full Name</label>
            <div class="form-control agc-disabled">{{ user | fullname }}</div>
          </agc-form-field>

          <agc-form-field>
            <label for="">Email Address</label>
            <div class="form-control agc-disabled">{{ user.email }}</div>
          </agc-form-field>

          <agc-form-field>
            <label for="">Username</label>
            <div class="form-control agc-disabled">{{ user.username || 'Unavailable' }}</div>
          </agc-form-field>

          <agc-form-field>
            <label for="">Phone</label>
            <div class="form-control agc-disabled">{{ user.phone || 'Unavailable' }}</div>
          </agc-form-field>

          <agc-form-field>
            <label for="">Country <span class="text-danger">*</span></label>
            <agc-country-input [all]="true" formControlName="fromCountryCode" [(data)]="fromCountry" required></agc-country-input>
            <agc-message when="required">Country is required.</agc-message>
          </agc-form-field>

          <agc-form-field>
            <label for="">State/Province <span class="text-danger">*</span></label>
            <agc-state-input [countryCode]="fromCountry?.code" formControlName="fromStateId" [(data)]="fromState" required></agc-state-input>
            <agc-message when="required">State/Province is required.</agc-message>
          </agc-form-field>

          <agc-form-field>
            <label for="">Region/City/LGA <span class="text-danger">*</span></label>
            <agc-region-input [stateId]="fromState?.id" formControlName="fromRegionId" [(data)]="fromRegion" required></agc-region-input>
            <agc-message when="required">Region/City/LGA is required.</agc-message>
          </agc-form-field>

          <agc-form-field>
            <label for="">Address 1 <span class="text-danger">*</span></label>
            <input type="text" formControlName="fromAddress1" class="form-control" required>
            <agc-message when="required">Address 1 is required.</agc-message>
          </agc-form-field>

          <agc-form-field>
            <label for="">Address 2</label>
            <input type="text" formControlName="fromAddress2" class="form-control">
          </agc-form-field>

          <agc-form-field>
            <label for="">Zip/Postal</label>
            <input type="text" formControlName="fromZipPostal" class="form-control">
          </agc-form-field>
        </div>

        <div class="col-md-4">
          <h2>Receiver's Details</h2>

          <agc-form-field>
            <label for="">Country <span class="text-danger">*</span></label>
            <agc-country-input [all]="true" formControlName="toCountryCode" [(data)]="toCountry" required></agc-country-input>
            <agc-message when="required">Destination Country is required.</agc-message>
          </agc-form-field>

          <agc-form-field>
            <label for="">State/Province <span class="text-danger">*</span></label>
            <agc-state-input [countryCode]="toCountry?.code" formControlName="toStateId" [(data)]="toState" required></agc-state-input>
            <agc-message when="required">Destination State/Province is required.</agc-message>
          </agc-form-field>

          <agc-form-field>
            <label for="">Region/City/LGA <span class="text-danger">*</span></label>
            <agc-region-input [stateId]="toState?.id" formControlName="toRegionId" [(data)]="toRegion" required></agc-region-input>
            <agc-message when="required">Region/City/LGA is required.</agc-message>
          </agc-form-field>

          <agc-form-field>
            <label for="">Contact Name <span class="text-danger">*</span></label>
            <input type="text" formControlName="contactName" class="form-control" required>
            <agc-message when="required">Contact Name is required.</agc-message>
          </agc-form-field>

          <agc-form-field>
            <label for="">Contact Email</label>
            <input type="text" formControlName="contactEmail" class="form-control">
          </agc-form-field>

          <agc-form-field>
            <label for="">Contact Phone <span class="text-danger">*</span></label>
            <agc-phone-input [country]="toCountry" formControlName="contactPhone" required phone></agc-phone-input>
            <agc-message when="required">Contact Phone is required.</agc-message>
            <agc-message when="phone">Contact Phone does not appear to be valid.</agc-message>
          </agc-form-field>

          <agc-form-field>
            <label for="">Address 1 <span class="text-danger">*</span></label>
            <input type="text" formControlName="toAddress1" class="form-control" required>
            <agc-message when="required">Address 1 is required.</agc-message>
          </agc-form-field>

          <agc-form-field>
            <label for="">Address 2</label>
            <input type="text" formControlName="toAddress2" class="form-control">
          </agc-form-field>

          <agc-form-field>
            <label for="">Zip/Postal</label>
            <input type="text" formControlName="toZipPostal" class="form-control">
          </agc-form-field>

          <agc-form-field>
            <label for="">Landmark</label>
            <input type="text" formControlName="toLandmark" class="form-control">
          </agc-form-field>
        </div>

        <div class="col-md-4">
          <!--
            <agc-form-field>
              <label for="">Service Type <span class="text-danger">*</span></label>
              <select name="serviceType" formControlName="fromAddress1" class="form-control" required>
                <option value=""></option>
              </select>
              <agc-message when="required">Service Type is required.</agc-message>
            </agc-form-field>
          -->

          <h2>Parcels</h2>

          <div class="item-list mb-3">
            <div class="text-center" *ngIf="!items.length">No parcel added</div>

            <div *ngIf="items.length">
              <agc-parcel [data]="item" *ngFor="let item of items">
                <agc-parcel-icon name="icofont icofont-ui-edit" title="Edit Parcel" (click)="editShippingItem(item)"></agc-parcel-icon>
                <agc-parcel-icon name="icofont icofont-trash" title="Remove Parcel" (click)="deleteShippingItem(item)"></agc-parcel-icon>
              </agc-parcel>
            </div>
          </div>

          <div class="text-center">
            <a href="javascript:;" (click)="addShippingItem()">
              <i class="feather icon-plus"></i>
              <span>Add New Parcel</span>
            </a>
          </div>
        </div>
      </div>
    </agc-form>
  </mat-dialog-content>

  <mat-dialog-actions align="end" *ngIf="user">
    <a href="javascript:;" (click)="changeUser()" *ngIf="data.changeUser !== false">Change User</a>
    <div class="spacer"></div>
    <button class="btn btn-default btn-md waves-effect waves-light" (click)="cancel()">Cancel</button>
    <button cdkFocusInitial class="btn btn-primary btn-md ml-2 waves-effect waves-light" (click)="formRef?.doSubmit()">Submit</button>
  </mat-dialog-actions>
</agc-container>
