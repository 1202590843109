import { AfterContentChecked, AfterContentInit, ChangeDetectorRef, Component, ContentChild, ContentChildren, ElementRef, Input, OnDestroy, QueryList, inject } from "@angular/core";
import { FormControlName, FormGroupDirective } from "@angular/forms";
import { AGCFormComponent } from "../form/form.component";
import { MessageComponent } from "../message/message.component";
import { Subscription } from "rxjs";

interface MessageComponentHash {
  [key: string]: MessageComponent
}

@Component({
  selector: 'agc-form-field',
  templateUrl: 'form-field.component.html',
  styleUrls: ['form-field.component.scss']
})
export class FormFieldComponent implements AfterContentInit, OnDestroy {

  private readonly cdr = inject(ChangeDetectorRef);
  private readonly form = inject(AGCFormComponent);

  @ContentChild(FormControlName) controlName: FormControlName = null as any;
  @ContentChildren(MessageComponent) messages: QueryList<MessageComponent> = null as any;

  private xSub?: Subscription;

  @Input('errors') errors: any;

  private hash: MessageComponentHash = {};

  constructor() {
    this.updateMessages = this.updateMessages.bind(this);
  }

  ngAfterContentInit() {
    const { form, controlName, messages, updateMessages } = this;

    if (!controlName) return;

    messages.forEach(message => {
      this.hash[message.when] = message;
    });

    if (controlName.valueChanges) {
      controlName.valueChanges.subscribe(updateMessages);
    }

    if (controlName.statusChanges) {
      controlName.statusChanges.subscribe(updateMessages);
    }

    this.xSub = form.submitted.subscribe(updateMessages);

    updateMessages();
  }

  get pending() {
    const { controlName } = this;
    return !!controlName?.pending;
  }

  get showError() {
    const { form, controlName } = this;
    return !!(form.isSubmitted || controlName?.dirty || controlName?.touched);
  }

  private updateMessages() {
    const { hash, messages, controlName } = this;
    if (!messages || !controlName) return;

    messages.forEach(message => {
      message.hidden = true;
    });

    if (controlName.invalid) {
      for (let key in controlName.errors) {
        if (!(key in hash)) continue;
        hash[key].hidden = false;
        return;
      }
    }
  }

  ngOnDestroy() {
    this.xSub?.unsubscribe();
  }

}
