import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { DashboardService, DashboardSummary } from "../services/DashboardService";

@Injectable()
export class DashboardResolver implements Resolve<DashboardSummary> {

  constructor(
    private dashboardService: DashboardService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): DashboardSummary | Observable<DashboardSummary> | Promise<DashboardSummary> {
    const { dashboardService } = this;
    return dashboardService.getSummary();
  }

}
