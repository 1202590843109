import { Injectable, inject } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { catchError, Observable, of, switchMap, throwError } from "rxjs";
import { TopupDialog } from "../../dialog/topup/topup.dialog";
import { WalletTopupOptions } from "../../interfaces/Wallet";
import { Invoice } from "../../interfaces/Transaction";
import { InvoiceDialogService } from "./InvoiceDialogService";

@Injectable()
export class TopupDialogService {

  private readonly dialog = inject(MatDialog)
  private readonly invoiceDialog = inject(InvoiceDialogService)

  constructor() {  }

  open(options: WalletTopupOptions): Observable<Invoice | null> {
    const { invoiceDialog } = this;

    return this.openForm(options).pipe(
      switchMap(invoice => {
        if (!invoice) return throwError(() => 'none');
        return invoiceDialog.open(invoice, false);
      }),

      catchError(error => {
        if (error === 'none') return of(null);
        return throwError(() => error);
      })
    );
  }

  private openForm(options: WalletTopupOptions): Observable<Invoice | null> {
    if (!options.amount) options.amount = 0;

    const dialog = this.dialog.open(TopupDialog, {
      width: '90vw',
      maxWidth: '350px',
      disableClose: true,
      data: options,
    });

    return dialog.afterClosed();
  }

}
