import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { from, Observable, of, throwError } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import type { AGCModuleConfig } from "../interfaces/AGCModuleConfig";
import type { Invoice, Transaction } from "../interfaces/Transaction";
import { LoadingService } from "./LoadingService";
import { MessageService } from "./MessageService";
import { TransactionService } from "./TransactionService";

@Injectable()
export class PaymentService {

  constructor(
    private http: HttpClient,
    private loadingService: LoadingService,
    private transactionService: TransactionService,
    @Inject('AGC') private agc: AGCModuleConfig
  ) { }

  pay(invoice: Invoice): Observable<Transaction> {
    const { loadingService, transactionService } = this;

    loadingService.start();

    return transactionService.create(invoice.id as number).pipe(
      switchMap(tranx => {
        console.log(tranx);
        return this.openPaystack(tranx);
      }),

      map(tranx => {
        loadingService.stop();
        return tranx;
      }),

      catchError(error => {
        loadingService.stop();
        return throwError(() => error);
      })
    );
  }

  openPaystack(transaction: Transaction): Observable<Transaction> {
    const { environment: { paystack } } = this.agc;
    const { transactionService } = this;

    const result = new Promise<any>((resolve, reject) => {
      const PaystackPop = (window as any).PaystackPop;

      if (!transaction.account) {
        reject('Invalid transaction data!');
        return;
      }

      const handler = PaystackPop.setup({
        key: paystack.PUBLIC_KEY,
        email: transaction.account.email || `${transaction.account.phone}@agccourier.com`,
        amount: (transaction.amount as number) * 100,
        ref: transaction.reference,
        label: `${transaction.account.firstName} ${transaction.account.lastName}`,

        onClose: () => {
          reject('Transaction cancelled');
        },

        callback: (response: any) => {
          resolve(response);
        }
      });

      handler.openIframe();
    });

    return from(result).pipe(
      switchMap(resp => transactionService.verify(transaction)),

      catchError(error => {
        if (typeof (error) === 'string') {
          return transactionService.verify(transaction);
        } else {
          return throwError(() => error);
        }
      })
    );
  }

}
