import { AfterViewInit, Component, ElementRef, forwardRef, Input, ViewChild } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Country } from "../../interfaces/Country";

@Component({
  selector: 'agc-phone-input',
  templateUrl: 'phone-input.component.html',
  styleUrls: ['phone-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneInputComponent),
      multi: true
    }
  ]
})
export class PhoneInputComponent implements ControlValueAccessor, AfterViewInit {

  phone: string = '';
  prefix: string = '';

  @Input() placeholder: string = '';
  @Input() id: string = '';
  @Input() value: string = '';

  @ViewChild('input') inputRef: ElementRef<HTMLInputElement> = null as any;

  phoneCodeList: string[] = [];

  private xCountry?: Country;

  get country() { return this.xCountry; }

  @Input() set country(value: Country | undefined) {
    this.xCountry = value;
    this.parseCountry();
  }

  private xValueParsed: boolean = false;

  constructor(
    private elementRef: ElementRef<HTMLElement>
  ) {
    const { nativeElement: element } = elementRef;
    element.tabIndex = 1;
  }

  onChange = (value: any) => {};

  writeValue(value: string): void {
    this.value = value;
    this.parseValue();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    // throw new Error("Method not implemented.");
  }

  ngAfterViewInit() {
    const { nativeElement: input } = this.inputRef;
    const { nativeElement: element } = this.elementRef;

    element.addEventListener('focus', (e: FocusEvent) => {
      input.focus();
    });

    input.addEventListener('keypress', (e: KeyboardEvent) => {
      if (isNaN(Number(e.key))) {
        e.preventDefault();
      }

      if (!input.selectionStart && e.key === '0') {
        e.preventDefault();
      }
    });

    input.addEventListener('input', (e: Event) => {
      let value = input.value;
      let trimmed = 0;

      while (value.charAt(0) === '0') {
        value = value.substring(1);
        trimmed++;
      }

      if (trimmed) {
        let start = input.selectionStart || 0;
        start = Math.min(start, value.length);

        input.value = value;
        input.setSelectionRange(start, start);

        this.onPhoneValueChange(value);
      }
    });
  }

  private parseCountry() {
    const { country } = this;

    if (country && country.prefix) {
      this.phoneCodeList = country.prefix.split(/[\s]*,[\s]*/g);
    } else {
      this.phoneCodeList = [];
    }

    const [prefix = ''] = this.phoneCodeList;
    this.prefix = prefix;

    this.parseValue();
    this.updateValue();
  }

  private parseValue() {
    const { value, country, phoneCodeList } = this;

    if (this.xValueParsed) return;

    this.phone = value;

    if (value) for (const prefix of phoneCodeList) {
      if (value.startsWith(prefix)) {
        this.phone = value.substring(prefix.length);
        this.prefix = prefix;
        // Value Parsed -------
        this.xValueParsed = true;
        break;
      }
    }
  }

  private updateValue() {
    const { phone } = this;
    this.onPhoneValueChange(phone);
  }

  onPhoneValueChange(value: string) {
    const { country, prefix } = this;

    let phone = value || null;

    if (value && country) {
      phone = prefix + value;
    }

    this.onChange(phone);
  }

  onPhonePrefixChange(value: string) {
    this.prefix = value;
    this.updateValue();
  }

}
