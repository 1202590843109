import { Component, ElementRef, Input } from "@angular/core";

@Component({
  selector: 'agc-message',
  templateUrl: 'message.component.html',
  styleUrls: ['message.component.scss']
})
export class MessageComponent {

  @Input('when') when: string = '';

  get hidden() {
    const { nativeElement } = this.elem;
    return nativeElement.hasAttribute('hidden');
  }

  set hidden(value: boolean) {
    const { nativeElement } = this.elem;

    if (value) {
      nativeElement.setAttribute('hidden', '');
    } else {
      nativeElement.removeAttribute('hidden');
    }
  }

  constructor(
    private elem: ElementRef<HTMLElement>
  ) {}

}
