import { HttpClient } from "@angular/common/http";
import { Inject, Injectable, inject } from "@angular/core";
import { URLHelper } from "../helpers/URLHelper";
import { tap } from "rxjs";

type MobilePlatform = 'android' | 'ios';
type Platform = MobilePlatform;

@Injectable()
export class DownloadService {

  private readonly http = inject(HttpClient);
  private readonly urlHelper = inject(URLHelper);

  scanner(platform: MobilePlatform = 'android') {
    const { urlHelper } = this;
    return urlHelper.url(`/dl/apps/mobile/scanner/${platform}`);
    // return urlHelper.endpoint(`/dl/apps/mobile/scanner/${platform}`);
  }

}
