import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable, of, throwError } from "rxjs";
import { catchError, first, map, switchMap, take, tap } from "rxjs/operators";
import { Account } from "../interfaces/Account";
import { Menu } from "../interfaces/Menu";
import { AccountService } from "../services/AccountService";
import { MenuService } from "../services/MenuService";
import { MessageService } from "../services/MessageService";
import { RoleService } from "../services/RoleService";

@Injectable()
export class CanActivateHome implements CanActivateChild {

  constructor(
    private router: Router,
    private menuService: MenuService,
    private roleService: RoleService,
    private messageService: MessageService,
    private accountService: AccountService
  ) {}

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    const { router, messageService, accountService, menuService, roleService } = this;

    if (childRoute.firstChild) return true;

    let account: Account = null as any;
    let menuList: Menu[] = [];

    return accountService.refresh().pipe(
      // Get token
      switchMap(token => {
        if (!token) {
          router.navigate(['/']);
          return throwError('You are not logged in.');
        }
        return of(token);
      }),

      // Fetch Current User
      switchMap(token => accountService.me()),
      switchMap(resp => {
        if (!resp) {
          router.navigate(['/']);
          return throwError('No such user.');
        }

        account = resp;

        return of(resp);
      }),

      // Load Menu
      switchMap(resp => menuService.all()),
      tap(resp => menuList = resp),

      // Check Permission
      switchMap(resp => {
        if (['USER'].includes(account.type)) return of(true);
        return roleService.canAccess(state.url, account.role as any);
      }),

      switchMap(canAccess => {
        if (!canAccess) {
          if (menuList.length) router.navigateByUrl(menuList[0].link);
          throw `You do not have permission to access "${state.url}".`;
        }

        return of(canAccess);
      }),

      // Process Errors
      catchError(error => {
        messageService.handle(error, false);
        return of(false);
      })
    );
  }

}
