<agc-container dialog [loading]="loading">
  <h1 mat-dialog-title>
    <span>MANAGE ROUTING</span>

    <small>
      <i class="icofont icofont-curved-double-right"></i>

      <u>{{ data.fromState?.name }}, {{ data.fromCountry?.name }}</u>

      &nbsp;
      <i class="icofont icofont-arrow-right"></i>
      &nbsp;

      <u>{{ data.toState?.name }}, {{ data.toCountry?.name }}</u>
    </small>
  </h1>

  <mat-dialog-content class="mat-typography">
    <div class="text-right mb-2">
      <a href="" (click)="add($event)">
        <i class="icofont icofont-plus"></i> &nbsp;
        <span>Add New Milestone</span>
      </a>
    </div>

    <div class="info mb-3">
      Milestones are various stopping points through the final shipping destination
    </div>

    <div class="milestone-list" cdkDropList (cdkDropListDropped)="drop($event)">
      <div class="text-center" *ngIf="!milestoneList.length">No milestone found</div>

      <div class="milestone" cdkDrag cdkDragLockAxis="y" *ngFor="let milestone of milestoneList">
        <div class="description">{{ milestone.state?.name }}, {{ milestone.country?.name }}</div>
        <!-- <div class="unit-price">Unit Price: {{ milestone.unitPrice | number:'1.2-6' }}</div> -->
        <div class="actions">
          <a href="" (click)="edit(milestone, $event)">
            <i class="icofont icofont-ui-edit" title="Edit Milestone"></i>
          </a>

          <a href="" (click)="remove(milestone, $event)">
            <i class="icofont icofont-trash" title="Remove Milestone"></i>
          </a>
        </div>
        <div class="handle" cdkDragHandle>
          <i class="icofont icofont-drag"></i>
        </div>
      </div>
    </div>

  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button class="btn btn-default btn-md waves-effect waves-light" (click)="cancel()">Cancel</button>
    <button cdkFocusInitial class="btn btn-primary btn-md ml-2 waves-effect waves-light" (click)="submit()">Save</button>
  </mat-dialog-actions>
</agc-container>
