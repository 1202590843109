import { AfterViewInit, Component, ElementRef, forwardRef, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { BehaviorSubject, Subscription } from "rxjs";
import { AGCModuleConfig } from "../../interfaces/AGCModuleConfig";
import { MessageService } from "../../services/MessageService";

const RecaptchaReady = new BehaviorSubject<boolean>(false);

@Component({
  selector: 'agc-recaptcha',
  templateUrl: 'recaptcha.component.html',
  styleUrls: ['recaptcha.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RecaptchaComponent),
      multi: true
    }
  ]
})
export class RecaptchaComponent implements ControlValueAccessor, OnInit, OnDestroy, AfterViewInit {

  value: string = '';

  onChange = (value: string) => {};

  private xSubscription: Subscription = null as any;

  private xWidgetId: any = null;

  @ViewChild('container') containerRef: ElementRef<HTMLDivElement> = null as any;

  constructor(
    private messageService: MessageService,
    @Inject('AGC') private agc: AGCModuleConfig
  ) {
    this.verifiedCallback = this.verifiedCallback.bind(this);
    this.expiredCallback = this.expiredCallback.bind(this);
  }

  ngOnInit() {
    const id = 'agc-recaptcha-script';
    let script = document.querySelector(`script#${id}`);

    if (!script) {
      (window as any)['recaptchaOnloadCallback'] = function () {
        RecaptchaReady.next(true);
      };

      script = document.createElement('script');
      script.setAttribute('src', 'https://www.google.com/recaptcha/api.js?onload=recaptchaOnloadCallback&render=explicit');
      script.setAttribute('async', '');
      script.setAttribute('defer', '');
      script.setAttribute('id', id);
      // ---------------------------
      document.body.appendChild(script);
    }
  }

  ngOnDestroy() {
    if (this.xSubscription) {
      this.xSubscription.unsubscribe();
    }
  }

  ngAfterViewInit() {
    this.xSubscription = RecaptchaReady.subscribe({
      next: value => {
        if (value !== true) return;

        const { grecaptcha } = window as any;
        const { environment } = this.agc;
        const { nativeElement: container } = this.containerRef;

        this.xWidgetId = grecaptcha.render(container, {
          'sitekey': environment.recaptcha.SITE_KEY,
          'callback': this.verifiedCallback,
          'expired-callback': this.expiredCallback
        });
      }
    });
  }

  private verifiedCallback(response: any) {
    this.onChange(response);
  }

  private expiredCallback(response: any) {
    const { messageService } = this;

    messageService.alert(
      'reCAPTCHA',
      'Verification Expired. Check the box again.',
      'error'
    );

    this.onChange('');
  }

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    // throw new Error("Method not implemented.");
  }

  setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }

}
