<agc-container dialog [loading]="loading">
  <h1 mat-dialog-title>
    @if (isActiveAccount | async) {
      <span>Make Payment</span>
    } @else {
      <span>Pay as {{ invoice.account | fullname }}</span>
    }
  </h1>

  <mat-dialog-content class="mat-typography">
    @if (!methodList.length) {
      <div class="text-center">No payment method available</div>
    } @else {
      <div class="methods">
        @for (item of methodList; track $index) {
          <div class="methods-item" [ngClass]="{'active': method === item}" (click)="method = item">
            <div>{{ word(item) }}</div>
          </div>
        }
      </div>
    }

    @if (enableWallet && wallet) {
      <div class="wallet" [ngClass]="{'active': method == 'wallet'}">
        @if (!(isActiveAccount | async)) {
          <h3 class="text-primary">{{ invoice.account | fullname | for }} Wallet</h3>
        }
        <div class="title">Wallet Balance</div>
        <agc-amount [currency]="wallet.currency">{{ wallet.balance | number:'1.2-2' }}</agc-amount>
        <div>&darr;</div>
        @if (wallet.balance >= invoice.total!) {
          <div class="action">
            <a href="javascript:;" (click)="method = 'wallet'">Pay from Wallet</a>
          </div>
        } @else {
          <div class="action">
            <a href="javascript:;" (click)="method = 'wallet'">Top-Up</a>
          </div>
        }
      </div>
    }
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <div class="amount">
      <span>Amount: </span>
      <agc-amount [currency]="currency" class="text-primary">{{ invoice.total | number:'1.2-2' }}</agc-amount>
    </div>

    <button class="btn btn-secondary btn-sm waves-effect waves-light" (click)="close()">Close</button>

    @if (method) {
      <button cdkFocusInitial class="btn btn-primary btn-sm ml-2 waves-effect waves-light" (click)="submit()">Continue &raquo;</button>
    }
  </mat-dialog-actions>
</agc-container>
