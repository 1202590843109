import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Observable, of } from "rxjs";
import { switchMap, tap } from "rxjs/operators";
import { MatrixAlgorithmDialog } from "../../dialog/matrix/algorithm/algorithm.dialog";
import { MatrixAlgorithm, MatrixAlgorithmDialogOptions } from "../../interfaces/Matrix";
import { LoadingService } from "../LoadingService";
import { MatrixService } from "../MatrixService";
import { MessageService } from "../MessageService";

@Injectable()
export class MatrixAlgorithmDialogService {

  constructor(
    private dialog: MatDialog,
    private matrixService: MatrixService,
    private loadingService: LoadingService,
    private messageService: MessageService,
  ) {  }

  open(data: MatrixAlgorithmDialogOptions): Observable<MatrixAlgorithm> {
    const dialog = this.dialog.open(MatrixAlgorithmDialog, {
      width: '90vw',
      maxWidth: '350px',
      disableClose: true,
      data
    });

    return dialog.afterClosed();
  }
}
