import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingService } from './LoadingService';
import { ButtonList } from '../../assets/libs/sweetalert/modules/options/buttons';
import { SwalOptions } from '../../assets/libs/sweetalert/modules/options';
import { SweetAlert } from '../../assets/libs/sweetalert/core';

// import * as _swal from 'sweetalert'
// import { SweetAlert } from 'sweetalert/typings/core';
// import { SwalOptions } from 'sweetalert/typings/modules/options';
// import { ButtonList } from 'sweetalert/typings/modules/options/buttons';

declare const swal: SweetAlert;
declare type MessageIcon = 'error' | 'success' | 'warning' | 'info';


interface ButtonOptions {
  text: string;
  value?: any;
  visible?: boolean;
  className?: string;
  closeModal?: boolean;
}

interface ButtonOptionsHash {
  [key: string]: string | boolean | ButtonOptions;
}

export declare type MessageButton = string | boolean | ButtonOptions;
export declare type MessageButtonList = ButtonOptionsHash | MessageButton[];

@Injectable()
export class MessageService {

  constructor(
    private loadingService: LoadingService
  ) {
    this.alert = this.alert.bind(this);
    this.handle = this.handle.bind(this);
  }

  alert<T = any>(title: string, text: string, icon?: MessageIcon, buttons?: ButtonList): Promise<T> {
    const options: Partial<SwalOptions> = { title, text, icon, buttons };
    return swal(options);
  }

  confirm(title: string, text: string, icon?: MessageIcon) {
    return this.alert<boolean>(title, text, icon, {
      cancel: { text: 'Cancel', value: false, visible: true, closeModal: true, className: 'btn btn-danger' },
      ok: { text: 'OK', value: true, visible: true, closeModal: true, className: 'btn btn-success' }
    });
    // return this.alert(title, text, icon, [
    //   { text: 'Cancel', value: false, visible: true, closeModal: true, className: 'btn btn-danger' },
    //   { text: 'OK', value: true, visible: true, closeModal: true, className: 'btn btn-success' }
    // ]);
  }

  handle(data: any, stopLoading: boolean = true, buttons?: ButtonList) {
    if (stopLoading === true) {
      this.loadingService.stop();
    }

    const message = this.parseError(data);

    return this.alert('', message, 'error', buttons);
  }

  private parseError(data: any) {
    const defaultMessage = 'An error occurred. Please try again later.';

    console.log(data);

    if (typeof (data) === 'string') return data || defaultMessage;

    if (data instanceof HttpErrorResponse) {
      if (data.status === 422) {
        return data.error.message;
      }

      if ('error' in data) {
        if (typeof(data.error) === 'string') {
          return data.error || defaultMessage;
        }
      }

      if (data.message) {
        return data.message;
      }
    }

    // console.log(data);

    return defaultMessage;
  }

}
