import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { URLHelper } from "../helpers/URLHelper";
import { Permission, Role } from "../interfaces/Role";
import { Pagination } from "../interfaces/Pagination";
import { MenuService } from "./MenuService";
import { AccountService } from "./AccountService";
import { of } from "rxjs";
import { MenuAttribute } from "../interfaces/Menu";
import { filter, first, map, switchMap, take } from "rxjs/operators";

interface PermittedLinkMap {
  [key: string]: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  private xLinkMap: PermittedLinkMap = {};

  constructor(
    private http: HttpClient,
    private urlHelper: URLHelper,
    private accountService: AccountService,
    private menuService: MenuService
  ) {}

  canAccess(url: string, role: Role) {
    if (!role) return of(false);
    if (!role.isRestricted) return of(true);

    const { menuService, accountService } = this;

    let bestMatch: string = '';
    let bestMatchAttr: MenuAttribute = null as any;

    return menuService.menuList.pipe(
      first(),

      switchMap(resp => {
        if (resp.length) return of(resp);
        return menuService.all();
      }),

      map(resp => {
        for (const link in menuService.map) {
          const attr = menuService.map[link];

          if (url === link) {
            const hasPermission = attr.permission in accountService.permissions;
            return hasPermission;
          }

          const matched = url.indexOf(link) === 0;

          if (matched && link.length > bestMatch.length) {
            bestMatchAttr = attr;
            bestMatch = link;
          }
        }

        if (bestMatchAttr) {
          const hasPermission = bestMatchAttr.permission in accountService.permissions;
          return hasPermission;
        }

        return false;
      })
    );
  }

  all() {
    const url = this.urlHelper.endpoint(`/roles`);
    return this.http.get<Role[]>(url);
  }

  search(page: number = 1) {
    const params = new URLSearchParams();
    params.append('page', page.toString());
    const url = this.urlHelper.endpoint(`/roles/search?${params.toString()}`);
    return this.http.get<Pagination<Role>>(url);
  }

  find(id: number | string) {
    const url = this.urlHelper.endpoint(`/roles/${id}`);
    return this.http.get<Role>(url);
  }

  add(data: Role) {
    const url = this.urlHelper.endpoint(`/roles`);
    return this.http.post<Role>(url, data);
  }

  update(id: number | string, data: Role) {
    const url = this.urlHelper.endpoint(`/roles/${id}`);
    return this.http.patch<Role>(url, data);
  }

  trash(id: number | string) {
    const url = this.urlHelper.endpoint(`/roles/${id}`);
    return this.http.delete<Role>(url);
  }

  permissions() {
    const url = this.urlHelper.endpoint(`/roles/permissions`);
    return this.http.get<Permission[]>(url);
  }

}
