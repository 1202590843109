import { AfterContentInit, ContentChildren, Directive, Input, QueryList } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ErrorComponent } from "../components/error/error.component";

@Directive({
  selector: '[agc-validate]'
})
export class FormValidate implements AfterContentInit {

  @Input('formGroup') formGroup: FormGroup = null as any;
  @ContentChildren(ErrorComponent) errors: QueryList<ErrorComponent> = null as any;

  constructor() {}

  ngAfterContentInit() {
    const { formGroup, errors } = this;

    errors.forEach(error => {
      error.initialize(formGroup);
    });
  }

}
