import { Directive, Input } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from "@angular/forms";

@Directive({
  selector: '[equals]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: EqualsValidationDirective,
      multi: true
    }
  ]
})
export class EqualsValidationDirective implements Validator {

  @Input() equals: string = '';

  constructor() {}

  validate(control: AbstractControl): ValidationErrors {
    const { equals } = this;
    const { parent: form } = control;
    const error = { equals: true };

    if (!equals || !form) return error;

    const ctrl = form.get(equals);
    if (!ctrl || ctrl.value !== control.value) return error;

    return null as any;
  }

}
