import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";
import { Subscription } from "rxjs";
import { Agent, AgentPagination } from "../../interfaces/Agent";
import { ListAction } from "../../interfaces/ListAction";
import { AgentService } from "../../services/AgentService";
import { LoadingService } from "../../services/LoadingService";
import { MessageService } from "../../services/MessageService";

@Component({
  selector: 'agc-agent-list',
  templateUrl: 'agent-list.component.html',
  styleUrls: ['agent-list.component.scss']
})
export class AgentListComponent {

  private xRecords: AgentPagination = null as any;
  get records() { return this.xRecords; }

  @Input() actions: ListAction<Agent>[] = [];

  constructor(
    private cd: ChangeDetectorRef,
    private loadingService: LoadingService,
    private messageService: MessageService,
    private agentService: AgentService
  ) {
    this.fetch();
  }

  // ngAfterViewInit() {
  //   this.fetch();
  // }

  fetch(page: number = 1) {
    const { cd, loadingService, agentService, messageService } = this;

    loadingService.start();

    agentService.search(page).subscribe({
      next: resp => {
        this.xRecords = resp;
      },

      error: messageService.handle,

      complete: () => {
        loadingService.stop();
      }
    });
  }

}
