import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

let count = 0;

@Injectable()
export class LoadingService {

  private xLoading: Observable<boolean>;
  private xLoadingSub: BehaviorSubject<boolean>;

  constructor() {
    this.xLoadingSub = new BehaviorSubject(false as boolean);
    this.xLoading = this.xLoadingSub.asObservable();
  }

  get value() { return this.xLoading; }

  start() {
    count++;
    this.xLoadingSub.next(true);
  }

  stop() {
    count = Math.max(count - 1, 0);

    if (count === 0) {
      this.xLoadingSub.next(false);
    }
  }

}
