import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: 'agc-input-array',
  templateUrl: 'input-array.component.html',
  styleUrls: ['input-array.component.scss']
})
export class InputArrayComponent {

  @Output() readonly add = new EventEmitter();

}
