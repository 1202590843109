import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { StateListDialog } from "../../dialog/states/states.dialog";
import { State, StateListDialogOptions } from "../../interfaces/State";

@Injectable()
export class StateDialogService {

  constructor(
    private dialog: MatDialog
  ) {  }

  open(options: StateListDialogOptions): Observable<State> {
    const dialog = this.dialog.open(StateListDialog, {
      width: '90vw',
      maxWidth: '1000px',
      disableClose: true,
      data: options
    });

    return dialog.afterClosed();
  }

}
