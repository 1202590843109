<div class="alert alert-warning text-center" role="alert" *ngIf="account?.status === 'IN_REVIEW'">
  <h4 class="alert-heading">Account Pending Review!</h4>
  <p>Kindly review this account and give a feedback via the buttons below.</p>

  <hr>

  <div class="text-center">
    <button class="btn btn-danger" (click)="decline()">Decline</button>
    &nbsp;&nbsp;&nbsp;
    <button class="btn btn-success" (click)="approve()">Approve</button>
  </div>
</div>
