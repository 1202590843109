import { Component, Input } from "@angular/core";
import { Currency } from "../../interfaces/Currency";

@Component({
  selector: 'agc-amount',
  templateUrl: 'amount.component.html',
  styleUrls: ['amount.component.scss']
})
export class AmountComponent {

  @Input()
  currency?: Currency;

  @Input()
  currencyCode?: string;

}
