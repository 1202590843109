<agc-container dialog [loading]="loading">
  <h1 mat-dialog-title>MY WALLET</h1>

  <mat-dialog-content class="mat-typography">
    @if (wallet | async; as w) {
      <div class="balance">
        <div class="title">Wallet Balance</div>
        <agc-amount [currency]="w.currency">
          <span>{{ w.balance | number:'1.2-2' }}</span>
        </agc-amount>
      </div>

      <div class="wallet-grid">
        <div class="credited">
          <div class="title">Total Credited</div>
          <agc-amount [currency]="w.currency">
            <span>{{ w.totalCredit| number:'1.2-2' }}</span>
          </agc-amount>
        </div>

        <div class="debited">
          <div class="title">Total Debited</div>
          <agc-amount [currency]="w.currency">
            <span>{{ w.totalDebit| number:'1.2-2' }}</span>
          </agc-amount>
        </div>
      </div>
    }
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button cdkFocusInitial class="btn btn-secondary btn-sm ml-2 waves-effect waves-light" (click)="close()">Close</button>
    <button cdkFocusInitial class="btn btn-primary btn-sm ml-2 waves-effect waves-light" [disabled]="!wallet" (click)="topup()">Top Up Wallet &raquo;</button>
  </mat-dialog-actions>
</agc-container>
