import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { first, map } from "rxjs/operators";
import { AccountService } from "../services/AccountService";

@Injectable()
export class CanActivateAccess implements CanActivateChild {

  constructor(
    private router: Router,
    private accountService: AccountService
  ) {}

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    const { router, accountService } = this;

    return accountService.token.pipe(
      first(),

      map(token => {
        if (!token) return true;
        router.navigate(['/home']);
        return false;
      })
    );
  }

}
