import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { AvailabilityResponse } from "../interfaces/Response";
import { Business, BusinessPagination } from "../interfaces/Business";
import { URLHelper } from "../helpers/URLHelper";

@Injectable({
  providedIn: 'root'
})
export class BusinessService {

  private xBusinessSub: BehaviorSubject<Business>;
  private xBusiness: Observable<Business>;

  get business() { return this.xBusiness; }

  constructor(
    private http: HttpClient,
    private urlHelper: URLHelper
  ) {
    this.xBusinessSub = new BehaviorSubject<Business>(null as any);
    this.xBusiness = this.xBusinessSub.asObservable();
  }

  setValue(value: Business) {
    this.xBusinessSub.next(value);
  }

  setBusiness(data: Business) {
    this.setValue(data);
  }

  all(page: number = 1) {
    const params = new URLSearchParams();
    params.append('page', page.toString());
    const url = this.urlHelper.endpoint(`/businesses?${params.toString()}`);
    return this.http.get<BusinessPagination>(url);
  }

  find(businessId: number) {
    const url = this.urlHelper.endpoint(`/businesses/${businessId}`);
    return this.http.get<Business>(url);
  }

  findBy(field: string, value: any) {
    const params = new URLSearchParams();
    params.set('field', field);
    params.set('value', value);

    const url = this.urlHelper.endpoint(`/businesses/find?${params.toString()}`);
    return this.http.get<Business>(url);
  }

  create(data: Business) {
    const url = this.urlHelper.endpoint(`/businesses`);
    return this.http.post<Business>(url, data);
  }

  update(userId: number, data: Business) {
    const url = this.urlHelper.endpoint(`/businesses/${userId}`);
    return this.http.patch<Business>(url, data);
  }

  changePassword(businessId: number, data: any) {
    const url = this.urlHelper.endpoint(`/businesses/${businessId}/password`);
    return this.http.post<Business>(url, data);
  }

  emailAvailable(email: string, ignore: string = '') {
    const data = { email, ignore };
    const url = this.urlHelper.endpoint(`/businesses/availability/email`);
    return this.http.post<AvailabilityResponse>(url, data);
  }

  phoneAvailable(phone: string, ignore: string = '') {
    const data = { phone, ignore };
    const url = this.urlHelper.endpoint(`/businesses/availability/phone`);
    return this.http.post<AvailabilityResponse>(url, data);
  }

  usernameAvailable(username: string, ignore: string = '') {
    const data = { username, ignore };
    const url = this.urlHelper.endpoint(`/businesses/availability/username`);
    return this.http.post<AvailabilityResponse>(url, data);
  }

}
