import { Component, ElementRef, Input, ViewChild } from "@angular/core";

@Component({
  selector: 'agc-frame',
  templateUrl: 'frame.component.html',
  styleUrls: ['frame.component.scss']
})
export class FrameComponent {

  private xSidebar: boolean = false;
  get sidebar() { return this.xSidebar; }

  @Input() stickyHeaderTop: number = 0;

  constructor() {}

  toggleSidebar() {
    this.xSidebar = !this.xSidebar;
  }

  closeSidebarOnClick(e: MouseEvent) {
    const target = e.target as HTMLElement;
    if (target.tagName !== 'A') return;
    this.xSidebar = false;
  }

  closeSidebarFromOverlay(e: MouseEvent) {
    this.xSidebar = false;
  }

}
