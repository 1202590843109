import { HttpClient } from "@angular/common/http";
import { inject, Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

import { URLHelper } from "../helpers/URLHelper";
import type { AGCModuleConfig } from "../interfaces/AGCModuleConfig";
import type { Transaction, TransactionPagination } from "../interfaces/Transaction";

@Injectable()
export class TransactionService {

  private readonly http = inject(HttpClient);
  private readonly urlHelper = inject(URLHelper);

  private xTransactionSub = new BehaviorSubject<Transaction | null>(null);
  readonly transaction: Observable<Transaction | null>;

  constructor() {
    this.transaction = this.xTransactionSub.asObservable();
  }

  setTransaction(data: Transaction) {
    this.xTransactionSub.next(data);
  }

  all(page: number = 1) {
    const params = new URLSearchParams();
    params.append('page', page.toString());
    const url = this.urlHelper.endpoint(`/transactions?${params.toString()}`);
    return this.http.get<TransactionPagination>(url);
  }

  allForUser(userId: number, page: number = 1) {
    const params = new URLSearchParams();
    params.append('page', page.toString());
    const url = this.urlHelper.endpoint(`/users/${userId}/transactions?${params.toString()}`);
    return this.http.get<TransactionPagination>(url);
  }

  allForAgent(agentId: number, page: number = 1) {
    const params = new URLSearchParams();
    params.append('page', page.toString());
    const url = this.urlHelper.endpoint(`/agents/${agentId}/transactions?${params.toString()}`);
    return this.http.get<TransactionPagination>(url);
  }

  allForBusiness(businessId: number, page: number = 1) {
    const params = new URLSearchParams();
    params.append('page', page.toString());
    const url = this.urlHelper.endpoint(`/businesses/${businessId}/transactions?${params.toString()}`);
    return this.http.get<TransactionPagination>(url);
  }

  find(id: number) {
    const url = this.urlHelper.endpoint(`/transactions/${id}`);
    return this.http.get<Transaction>(url);
  }

  findForUser(userId: number, id: number) {
    const url = this.urlHelper.endpoint(`/users/${userId}/transactions/${id}`);
    return this.http.get<Transaction>(url);
  }

  create(invoiceId: number) {
    const url = this.urlHelper.endpoint(`/transactions`);
    return this.http.post<Transaction>(url, { invoiceId });
  }

  verify(tranx: Transaction) {
    const url = this.urlHelper.endpoint(`/transactions/verify`);
    return this.http.post<Transaction>(url, { id: tranx.id });
  }

}
