<div class="row">
  <div class="col-md-6">
    <agc-container [loading]="loading">
      <div class="card">
        <div class="card-block-big">
          <agc-form [formGroup]="form" (submit)="submit()">
            <agc-form-field>
              <label for="">Bank Name <span class="text-danger">*</span></label>
              <select formControlName="bankRef" class="form-control" (ngModelChange)="onBankChange($event)" required>
                <option value="">-- Select Bank --</option>
                <option [value]="data.code" *ngFor="let data of records?.data">{{ data.name }}</option>
              </select>
              <agc-message when="required">Bank Name is required.</agc-message>
            </agc-form-field>

            <agc-form-field>
              <label for="">Account Number <span class="text-danger">*</span></label>
              <input type="text" formControlName="bankAccountNumber" class="form-control" numeric [digits]="10" required>
              <agc-message when="required">Account Number is required.</agc-message>
              <agc-message when="digits">Account Number must be 10 digits.</agc-message>
            </agc-form-field>

            <div class="form-group" *ngIf="info">
              <label for="">Account Name</label>
              <div class="form-control agc-disabled text-success">{{ info.account_name }}</div>
            </div>

            <div class="row m-t-30">
              <div class="col-md-12">
                <button type="submit" class="btn btn-md btn-block waves-effect waves-light text-center m-b-20" [disabled]="form.invalid" [ngClass]="{'btn-primary': info, 'btn-warning': !info}">
                  {{ (info) ? 'Save Bank Details' : 'Resolve Account Details' }}
                </button>
              </div>
            </div>
          </agc-form>
        </div>
      </div>
    </agc-container>
  </div>

  <div class="col-md-6">
    <!--  -->
  </div>
</div>
