import { inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { WinData } from "../data/WinData";
import { AGC_CONFIG } from "../interfaces/AGCModuleConfig";

type OffsetY = 'top' | 'center' | 'bottom';
type OffsetX = 'left' | 'center' | 'right';

type Options = {
  /**
   * A string, without whitespace, specifying the name of the browsing context the resource is being loaded into.
   * If the name doesn't identify an existing context, a new context is created and given the specified name.
   * The special target keywords, `_self`, `_blank` (default), `_parent`, `_top`, and `_unfencedTop` can also be used.
   * `_unfencedTop` is only relevant to fenced frames.
   *
   * This name can be used as the target attribute of `<a>` or `<form>` elements.
   */
  target?: string;

  /**
   * By default, window.open opens the page in a new tab. If popup is set to true, it requests that a minimal popup window be used.
   * The UI features included in the popup window will be automatically decided by the browser, generally including an address bar
   * only. If popup is present and set to false, a new tab is still opened.
   *
   * There are a few legacy features, which used to control UI features of the opened window. In modern browsers, they only have
   * the effect of requesting a popup. If popup is unspecified, and windowFeatures contains any features (including unrecognized ones)
   * other than `noopener`, `noreferrer`, or `attributionsrc`, the window is also opened as a popup if any of the following conditions apply:
   *
   * - `location` and toolbar are both false or absent
   * - `menubar` is false or absent
   * - `resizable` is false
   * - `scrollbars` is false or absent
   * - `status` is false or absent
   */
  popup?: boolean;

  /**
   * `width` or `innerWidth`
   *
   * Specifies the width of the content area, including scrollbars. The minimum required value is `100`
   */
  width?: number;

  /**
   * `height` or `innerHeight`
   *
   * Specifies the height of the content area, including scrollbars. The minimum required value is `100`
   */
  height?: number;

  /**
   * `left` or `screenX`
   *
   * Specifies the distance in pixels from the left side of the work area as defined by the user's operating
   * system where the new window will be generated.
   */
  left?: number;

  /**
   * `top` or `screenY`
   *
   * Specifies the distance in pixels from the top side of the work area as defined by the user's operating
   * system where the new window will be generated.
   */
  top?: number;

  offsetX?: OffsetX;

  offsetY?: OffsetY;

  /**
   * If this feature is set, the new window will not have access to the originating window via `Window.opener`
   * and returns null.
   *
   * When `noopener` is used, non-empty target names, other than `_top`, `_self`, and `_parent`, are treated like
   * `_blank` in terms of deciding whether to open a new browsing context.
   */
  noopener?: boolean;

  /**
   * If this feature is set, the browser will omit the Referer header, as well as set noopener to true.
   * See `rel="noreferrer"` for more information.
   */
  noreferrer?: boolean;
};

@Injectable()
export class WindowService {

  private readonly config = inject(AGC_CONFIG);

  openTab<T = any>(url: string | URL) {
    return this.open(url, {
      target: '_blank'
    });
  }

  payment<T = any>(url: string | URL) {
    return this.open(url, {
      popup: true,
      width: 500,
      height: 500,
      offsetX: 'center',
      offsetY: 'center',
    });
  }

  open<T = any>(url: string | URL, options?: Options) {
    const { config: { environment: { api } } } = this;

    let result: WinData<T>;

    return new Observable<WinData<T>>(sub => {
      try {
        const features = this.features(options);
        const target = options?.target || '_blank';

        const popup = window.open(url, target, features);

        if (!popup) {
          sub.next();
          sub.complete();
          return;
        }

        const onMessage = (event: MessageEvent<any>) => {
          console.log(event);

          if (api.startsWith(event.origin)) {
            result = WinData.fromData<T>(event.data);
            console.log(result);

            popup.close();
          }
        };

        const resolve = (value?: WinData<T>) => {
          sub.next(value);
          sub.complete();
          clear();
        };

        const reject = (err?: any) => {
          sub.error(err);
          sub.complete();
          clear();
        };

        window.addEventListener('message', onMessage);

        const clear = () => {
          window.removeEventListener('message', onMessage);
        };

        let interval = window.setInterval(() => {
          if (!popup.closed) return;

          window.clearInterval(interval);

          if (!result) {
            return reject('No result received.');
          }

          if (result.error) {
            return reject(result.message);
          }

          resolve(result);
        }, 100);
      } catch (error) {
        sub.error(error);
        sub.complete();
      }
    });
  }

  private features(options?: Options): string {
    let result: string = '';

    if (options) {
      if (options.popup) result += ',popup';

      if (options.width) {
        const width = Math.max(options.width, 500);
        result += `,width=${width}`;

        let left = 0;
        const xwidth = window.outerWidth;

        switch (options.offsetX) {
          case 'left': left = 0; break;

          case 'center': {
            left = (xwidth / 2) - (width / 2);
          } break;

          case 'right': {
            left = xwidth - width;
          } break;

          default: left = options.left || 0;
        }

        result += `,left=${left}`;
      }

      if (options.height) {
        const height = Math.max(options.height, 500);
        result += `,height=${height}`;

        let top = 0;
        const xheight = window.outerHeight;

        switch (options.offsetY) {
          case 'top': top = 0; break;

          case 'center': {
            top = (xheight / 2) - (height / 2);
          } break;

          case 'bottom': {
            top = xheight - height;
          } break;

          default: top = options.top || 0;
        }

        result += `,top=${top}`;
      }

      if (options.noopener) result += ',noopener';
      if (options.noreferrer) result += ',noreferrer';
    }

    if (result) result = result.substring(1);

    return result;
  }

}
