<agc-pagination [data]="records" (callback)="fetch($event)">
  <div class="text-center" *ngIf="!records?.total">No agent found</div>

  <table class="table table-striped table-hover table-text" *ngIf="records?.total">
    <thead>
      <tr class="footable-header">
        <th>ID</th>
        <th>Name</th>
        <th>Username</th>
        <th>Email</th>
        <th>Status</th>
        <th>Created</th>
        <th>Updated</th>
        <th *ngIf="actions?.length"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of records.data">
        <td>{{ data.id }}</td>
        <td>{{ data | fullname }}</td>
        <td>{{ data.username }}</td>
        <td>{{ data.email }}</td>
        <td><agc-status [value]="data.status"></agc-status></td>
        <td>{{ data.createdAt | date:'short' }}</td>
        <td>{{ data.updatedAt | date:'short' }}</td>
        <td class="text-right" *ngIf="actions?.length">
          <a href="javascript:;" class="label" [ngClass]="action.cssClass" (click)="action.callback($event, data)" *ngFor="let action of actions">
            <i [ngClass]="action.icon" *ngIf="action.icon"></i>
            <span *ngIf="action.text">&nbsp; {{ action.text }}</span>
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</agc-pagination>
