import { Pipe, PipeTransform } from "@angular/core";
import { Account } from "../interfaces/Account";

@Pipe({ name: 'for' })
export class ForPipe implements PipeTransform {

  transform(input?: string) {
    if (!input) return '';

    const ending = input.slice(-1);

    input += "'";

    if (ending !== 's') {
      input += 's';
    }

    return input;
  }

}
