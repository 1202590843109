import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { CountryService } from "../services/CountryService";
import { MessageService } from "../services/MessageService";

@Injectable()
export class CanActivateCountry implements CanActivateChild {

  constructor(
    private router: Router,
    private messageService: MessageService,
    private countryService: CountryService
  ) {}

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    const { router, countryService, messageService } = this;

    const { params } = childRoute;

    return countryService.find(params['countryId']).pipe(
      map(data => {
        countryService.setCountry(data);
        return true;
      }),

      catchError(error => {
        messageService.handle(error);
        router.navigate(['/home/countries']);
        return of(false);
      })
    );
  }

}
