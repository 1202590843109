import { Directive, ElementRef } from "@angular/core";

declare const jQuery: any;

@Directive({
  selector: '[agc-mousewheel]'
})
export class MousewheelDirective {

  constructor(private el: ElementRef<HTMLElement>) {
    const { nativeElement: element } = el;

    el.nativeElement.addEventListener('wheel', function (e: WheelEvent) {
      e.preventDefault();

      const { clientHeight, scrollHeight, scrollTop } = element;

      const maxScroll = scrollHeight - clientHeight;
      const value = Math.min(scrollTop + (e.deltaY * 0.5), maxScroll);

      element.scrollTop = Math.max(value, 0);
    });
  }

}
