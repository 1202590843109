import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { URLHelper } from "../helpers/URLHelper";
import { Routing, RoutingMilestone, RoutingPagination } from "../interfaces/Routing";

@Injectable()
export class RoutingService {

  private xRouting: Observable<Routing>;
  private xRoutingSub: BehaviorSubject<Routing>;

  get routing() { return this.xRouting; }

  constructor(
    private http: HttpClient,
    private urlHelper: URLHelper
  ) {
    this.xRoutingSub = new BehaviorSubject<Routing>(null as any);
    this.xRouting = this.xRoutingSub.asObservable();
  }

  setRouting(value: Routing) {
    this.xRoutingSub.next(value);
  }

  search(countryCode: string, stateId: number, page: number = 1) {
    const params = new URLSearchParams();
    params.append('countryCode', countryCode);
    params.append('stateId', stateId.toString());
    params.append('page', page.toString());

    const url = this.urlHelper.endpoint(`/routings?${params.toString()}`);
    return this.http.get<RoutingPagination>(url);
  }

  find(id: number) {
    const url = this.urlHelper.endpoint(`/routings/${id}`);
    return this.http.get<Routing>(url);
  }

  add(data: Routing) {
    const url = this.urlHelper.endpoint(`/routings`);
    return this.http.post<Routing>(url, data);
  }

  update(id: number, data: Routing) {
    const url = this.urlHelper.endpoint(`/routings/${id}`);
    return this.http.patch<Routing>(url, data);
  }

  getMilestones(routingId: number) {
    const url = this.urlHelper.endpoint(`/routings/${routingId}/milestones`);
    return this.http.get<RoutingMilestone[]>(url);
  }

  saveMilestones(routingId: number, milestones: RoutingMilestone[]) {
    const url = this.urlHelper.endpoint(`/routings/${routingId}/milestones`);
    return this.http.post<RoutingMilestone[]>(url, { milestones });
  }

}
