import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, forwardRef, ViewChild } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { GoogleMapService } from "../../services/GoogleMapService";
import { OfficeHour } from "../../interfaces/Office";

type OnValueChange = (value: DataValue) => void;

type DataValue = Pick<OfficeHour, 'startDayNum' | 'stopDayNum' | 'startTime' | 'stopTime'>;

const days = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  7: 'Sunday',
};

const hours = {
  '00:00:00': '12:00 AM',
  '01:00:00': '01:00 AM',
  '02:00:00': '02:00 AM',
  '03:00:00': '03:00 AM',
  '04:00:00': '04:00 AM',
  '05:00:00': '05:00 AM',
  '06:00:00': '06:00 AM',
  '07:00:00': '07:00 AM',
  '08:00:00': '08:00 AM',
  '09:00:00': '09:00 AM',
  '10:00:00': '10:00 AM',
  '11:00:00': '11:00 AM',
  '12:00:00': '12:00 PM',
  '13:00:00': '01:00 PM',
  '14:00:00': '02:00 PM',
  '15:00:00': '03:00 PM',
  '16:00:00': '04:00 PM',
  '17:00:00': '05:00 PM',
  '18:00:00': '06:00 PM',
  '19:00:00': '07:00 PM',
  '20:00:00': '08:00 PM',
  '21:00:00': '09:00 PM',
  '22:00:00': '10:00 PM',
  '23:00:00': '01:00 PM',
};

@Component({
  selector: 'agc-office-hour',
  templateUrl: 'office-hour.component.html',
  styleUrls: ['office-hour.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OfficeHourInputComponent),
      multi: true
    }
  ]
})
export class OfficeHourInputComponent implements ControlValueAccessor, AfterViewInit {

  value: string = '';
  onChange: OnValueChange = null as any;

  get days() { return days; }
  get hours() { return hours; }

  readonly data: DataValue = {
    startDayNum: 0,
    startTime: '',
    stopTime: '',
  };

  updateModelValue() {
    const { data } = this;

    if (!data.startDayNum) return;
    if (!data.startTime) return;
    if (!data.stopTime) return;

    this.onChange(data);
  }

  writeValue(value?: DataValue) {
    const { data } = this;

    if (!value) {
      data.startDayNum = 0;
      data.stopDayNum = undefined;
      data.startTime = '';
      data.stopTime = '';
      return;
    }

    data.startDayNum = value.startDayNum || 0;
    // if (value.stopDayNum) data.stopDayNum = value.stopDayNum || undefined;
    data.stopDayNum = value.stopDayNum || undefined;
    data.startTime = value.startTime || '';
    data.stopTime = value.stopTime || '';
  }

  registerOnChange(fn: OnValueChange) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    //
  }

  ngAfterViewInit() {
    window.setTimeout(() => {
      const { value } = this;
      // this.updateMapView(value);
    });
  }

}
