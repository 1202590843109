import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { URLHelper } from "../helpers/URLHelper";
import { AGCModuleConfig } from "../interfaces/AGCModuleConfig";
import { State, StatePagination } from "../interfaces/State";

interface CountryStatesHash {
  [key: string]: State[];
}

@Injectable()
export class StateService {

  private xHash: CountryStatesHash = {};

  private xState: Observable<State>;
  private xStateSub: BehaviorSubject<State>;

  get state() { return this.xState; }

  constructor(
    private http: HttpClient,
    private urlHelper: URLHelper
  ) {
    this.xStateSub = new BehaviorSubject<State>(null as any);
    this.xState = this.xStateSub.asObservable();
  }

  setState(value: State | null) {
    this.xStateSub.next(value as any);
  }

  all(countryCode: string, refresh: boolean = false) {
    const url = this.urlHelper.url(`/states?countryCode=${countryCode}`);
    return this.http.get<State[]>(url);
  }

  search(countryCode: string, page: number = 1) {
    const params = new URLSearchParams();
    params.append('countryCode', countryCode);
    params.append('page', page.toString());
    const url = this.urlHelper.endpoint(`/states?${params.toString()}`);
    return this.http.get<StatePagination>(url);
  }

  find(id: number) {
    const url = this.urlHelper.endpoint(`/states/${id}`);
    return this.http.get<State>(url);
  }

  add(data: State) {
    const url = this.urlHelper.endpoint(`/states`);
    return this.http.post<State>(url, data);
  }

  update(id: number, data: State) {
    const url = this.urlHelper.endpoint(`/states/${id}`);
    return this.http.patch<State>(url, data);
  }

  toggle(id: number) {
    const url = this.urlHelper.endpoint(`/states/toggle`);
    return this.http.post<State>(url, { id });
  }

}
