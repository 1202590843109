import { AfterViewInit, Component, inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormControl, FormGroup } from "@angular/forms";
import { catchError, finalize, map, Observable, of, switchMap, throwError } from "rxjs";

import { Currency } from "../../interfaces/Currency";
import { Invoice, PaymentData, Transaction } from "../../interfaces/Transaction";
import { WalletService } from "../../services/WalletService";
import { TopupDialogService } from "../../services/dialog/TopupDialogService";
import { TransactionService } from "../../services/TransactionService";
import { LoadingService } from "../../services/LoadingService";
import { MessageService } from "../../services/MessageService";
import { PopupService } from "../../services/PopupService";
import { URLHelper } from "../../helpers/URLHelper";
import { InvoiceService } from "../../services/InvoiceService";
import { Wallet } from "../../interfaces/Wallet";
import { AccountService } from "../../services/AccountService";

type Options = {
  invoice: Invoice;
  enableWallet?: boolean;
};

@Component({
  selector: 'app-dialog-payment',
  templateUrl: 'payment.dialog.html',
  styleUrls: ['payment.dialog.scss']
})
export class PaymentDialog implements AfterViewInit {

  private readonly helper = inject(URLHelper);
  private readonly popup = inject(PopupService);
  private readonly walletService = inject(WalletService);
  private readonly topupDialog = inject(TopupDialogService);
  private readonly accountService = inject(AccountService);
  private readonly invoiceService = inject(InvoiceService);
  private readonly transactionService = inject(TransactionService);
  private readonly loadingService = inject(LoadingService);
  private readonly messageService = inject(MessageService);
  private readonly dialogRef = inject(MatDialogRef<PaymentDialog>);

  private readonly options = inject<Options>(MAT_DIALOG_DATA);

  readonly invoice: Invoice;
  readonly enableWallet: boolean;

  readonly form = new FormGroup({
    currencyCode: new FormControl(''),
    amount: new FormControl(0),
  });

  loading: boolean = false;

  readonly currency: Currency;

  method?: string;
  methodList: string[] = [];

  wallet?: Wallet;
  walletList: Wallet[] = [];

  readonly isActiveAccount: Observable<boolean>;

  constructor() {
    const { options: { invoice, enableWallet }, accountService } = this;

    this.isActiveAccount = accountService.account.pipe(
      map(account => account.id === invoice.accountId)
    );

    this.invoice = invoice;
    this.enableWallet = enableWallet !== false;
    this.currency = invoice.currency;

    switch (invoice.currency.code) {
      case 'NGN': {
        this.methodList.push('paystack');
      } break;
    }
  }

  ngAfterViewInit() {
    window.setTimeout(() => {
      if (this.enableWallet) this.fetchWallet();
    });
  }

  word(method: string): string {
    switch (method) {
      case 'paystack': return 'Pay with Paystack';
      default: return '';
    }
  }

  fetchWallet() {
    const { invoice, walletService, messageService } = this;

    this.loading = true;

    walletService.forAccount(invoice.accountId).pipe(
      finalize(() => this.loading = false)
    ).subscribe({
      next: resp => {
        this.walletList = resp;
        this.wallet = resp.length ? resp[0] : undefined;
      },

      error: error => messageService.handle(error, false)
    });
  }

  topupWallet() {
    const { topupDialog } = this;

    topupDialog.open({
      wallet: this.wallet!
    }).subscribe(resp => {
      if (resp) this.fetchWallet();
    });
  }

  submit() {
    const { method, invoice, helper, popup, loadingService, transactionService, messageService, dialogRef } = this;

    if (!method) return;

    switch (method) {
      case 'wallet': {
        this.payWithWallet();
      } break;

      default: {
        loadingService.start();

        this.payWithDefault().pipe(
          finalize(() => loadingService.stop())
        ).subscribe({
          next: resp => {
            if (!resp) return;
            dialogRef.close(resp);
          },

          error: error => {
            messageService.handle(error, false);
          }
        });
      }
    }
  }

  payWithWallet() {
    const { invoice, loadingService, walletService, messageService, dialogRef } = this;

    loadingService.start();

    walletService.pay(invoice.id!).pipe(
      finalize(() => loadingService.stop()),
    ).subscribe({
      next: async resp => {
        await messageService.alert('', 'Payment Successful!', 'success');
        dialogRef.close(resp);
      },

      error: error => {
        messageService.handle(error, false);
      }
    });
  }

  payWithDefault(): Observable<Transaction | null> {
    const { method, invoice, helper, popup, loadingService, transactionService } = this;

    const url = helper.url(`/pay/${invoice.id}/${method}`);

    return popup.payment(url).pipe(
      switchMap(resp => {
        if (!resp) return throwError(() => 'none');
        return transactionService.find(resp.data);
      }),

      switchMap(tranx => {
        if (tranx.status === 'SUCCESS') return of(tranx);
        return throwError(() => 'Payment Failed');
      }),

      catchError(error => {
        if (error === 'none') return of(null);
        return throwError(() => error);
      })
    );
  }

  close() {
    const { dialogRef } = this;
    dialogRef.close(null);
  }

}
