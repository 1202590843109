import { Component, Input } from "@angular/core";

@Component({
  selector: 'agc-frame-sidebar',
  templateUrl: 'sidebar.component.html',
  styleUrls: ['sidebar.component.scss']
})
export class FrameSidebarComponent {

  private xLoading: boolean = false;

  get loading() { return this.xLoading; }

  @Input() set loading(value: boolean) {
    if (typeof (value) !== 'boolean') value = false;
    this.xLoading = value;
  }

  constructor() {}

}
