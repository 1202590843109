<agc-container dialog [loading]="loading">
  <h1 mat-dialog-title>TOP UP WALLET</h1>

  <mat-dialog-content class="mat-typography">
    <agc-form #formRef [formGroup]="form" (submit)="submit()">
      <agc-form-field>
        <label for="">Amount (<span [innerHTML]="currency.symbol"></span> - Min: {{ min | number }}) <span class="text-danger">*</span></label>
        <input type="number" formControlName="amount" step="1" [min]="min" class="form-control" required>
        <agc-message when="required">Amount is required.</agc-message>
        <agc-message when="min">Amount must not be less than <span [innerHTML]="currency.symbol"></span>{{ min | number }}.</agc-message>
      </agc-form-field>
    </agc-form>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button cdkFocusInitial class="btn btn-secondary btn-sm ml-2 waves-effect waves-light" (click)="close()">Cancel</button>
    <button cdkFocusInitial class="btn btn-primary btn-sm ml-2 waves-effect waves-light" (click)="formRef.doSubmit()">Continue &raquo;</button>
  </mat-dialog-actions>
</agc-container>
