import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, Inject, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { AGCFormComponent } from "../../../components/form/form.component";
import { Routing, RoutingMilestone } from "../../../interfaces/Routing";
import { MessageService } from "../../../services/MessageService";
import { RoutingMilestoneDialogService } from "../../../services/dialog/RoutingMilestoneDialogService";
import { RoutingService } from "../../../services/RoutingService";

@Component({
  selector: 'app-dialog-routings-manage',
  templateUrl: 'manage.dialog.html',
  styleUrls: ['manage.dialog.scss']
})
export class RoutingsManageDialog {

  @ViewChild(AGCFormComponent) formRef: AGCFormComponent = null as any;

  loading: boolean = false;

  milestoneList: RoutingMilestone[] = [];

  constructor(
    private routingService: RoutingService,
    private messageService: MessageService,
    private milestoneDialog: RoutingMilestoneDialogService,
    private dialogRef: MatDialogRef<RoutingsManageDialog>,
    @Inject(MAT_DIALOG_DATA) public data: Routing
  ) {
    this.load();
  }

  drop(event: CdkDragDrop<RoutingMilestone[]>) {
    moveItemInArray(this.milestoneList, event.previousIndex, event.currentIndex);
  }

  add(e: MouseEvent) {
    e.preventDefault();

    const { milestoneDialog } = this;

    milestoneDialog.open().subscribe(data => {
      if (!data) return;
      this.milestoneList.push(data);
    });
  }

  edit(milestone: RoutingMilestone, e: MouseEvent) {
    e.preventDefault();

    const { milestoneDialog } = this;

    milestoneDialog.open(milestone).subscribe(data => {
      if (!data) return;
      Object.assign(milestone, data);
    });
  }

  remove(milestone: RoutingMilestone, e: MouseEvent) {
    e.preventDefault();

    const { milestoneList } = this;

    const index = milestoneList.indexOf(milestone);
    if (index >= 0) milestoneList.splice(index, 1);
  }

  load() {
    const { data, milestoneList, routingService, messageService } = this;

    this.loading = true;

    routingService.getMilestones(<number>data.id).subscribe({
      next: resp => {
        this.milestoneList = resp;
      },

      error: error => {
        messageService.handle(error, false);
        this.loading = false;
      },

      complete: () => {
        this.loading = false;
      }
    });
  }

  submit() {
    const { data, milestoneList, routingService, messageService, dialogRef } = this;

    milestoneList.forEach((value, index) => {
      value.position = index;
    });

    this.loading = true;

    routingService.saveMilestones(<number>data.id, milestoneList).subscribe({
      next: resp => {
        dialogRef.close(data);
      },

      error: error => {
        messageService.handle(error, false);
        this.loading = false;
      },

      complete: () => {
        this.loading = false;
      }
    });
  }

  cancel() {
    const { dialogRef } = this;
    dialogRef.close();
  }

}
