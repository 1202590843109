import { Component, ElementRef, Inject, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MessageService } from "../../../services/MessageService";
import { TwoFAService } from "../../../services/TwoFAService";

interface DialogData {
  aplc: string;
}

@Component({
  selector: 'app-dialog-2fa-authorize',
  templateUrl: 'authorize.dialog.html',
  styleUrls: ['authorize.dialog.scss']
})
export class TwoFAAuthorizeDialog {

  loading: boolean;

  otp: string = '';

  @ViewChild('input') inputRef: ElementRef<HTMLInputElement> = null as any;

  constructor(
    private service: TwoFAService,
    private messageService: MessageService,
    private dialogRef: MatDialogRef<TwoFAAuthorizeDialog>,
    @Inject(MAT_DIALOG_DATA) private data: DialogData
  ) {
    this.loading = false;
  }

  submit() {
    const { otp, data: { aplc }, service, messageService, dialogRef } = this;

    if (!otp) {
      messageService.alert(
        'Two-Factor Authentication',
        'Enter the code from the two-factor app on your mobile device',
        'error'
      );

      return;
    }

    const data = { otp, aplc };

    this.loading = true;

    service.authorize(data).subscribe({
      next: resp => {
        this.loading = false;
        dialogRef.close(true);
      },

      error: error => {
        messageService.handle(error, false);
        this.loading = false;
      }
    });
  }

}
