import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { ShippingItemDialog } from "../../dialog/shipping/item/item.dialog";
import { ShippingItem } from "../../interfaces/Shipping";

@Injectable()
export class ShippingItemDialogService {

  constructor(
    private dialog: MatDialog
  ) {  }

  open(shipping: ShippingItem = null as any): Observable<ShippingItem> {
    const dialog = this.dialog.open(ShippingItemDialog, {
      width: '90vw',
      maxWidth: '700px',
      disableClose: true,
      data: shipping
    });

    return dialog.afterClosed();
  }

}
