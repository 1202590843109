import { Component, ContentChildren, ElementRef, Input, QueryList } from "@angular/core";
import { ShippingItem } from "../../interfaces/Shipping";
import { ParcelIconComponent } from "./icon/icon.component";

@Component({
  selector: 'agc-parcel',
  templateUrl: 'parcel.component.html',
  styleUrls: ['parcel.component.scss']
})
export class ParcelComponent {

  private xData: ShippingItem = null as any;

  get data() { return this.xData; }

  @Input() set data(value: ShippingItem) {
    this.xData = value;
    this.update();
  }

  private xExpand: boolean = false;

  get expand() { return this.xExpand; }

  @ContentChildren(ParcelIconComponent) icons: QueryList<ParcelIconComponent> = new QueryList<ParcelIconComponent>();

  constructor(
    private elem: ElementRef<HTMLElement>
  ) {}

  private update() {
    //
  }

  toggle() {
    this.xExpand = !this.xExpand;
  }

}
