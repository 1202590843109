import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ShippingLabelsDialogOptions } from "../../../interfaces/Shipping";
import { MessageService } from "../../../services/MessageService";


@Component({
  selector: 'app-dialog-shipping-labels',
  templateUrl: 'labels.dialog.html',
  styleUrls: ['labels.dialog.scss']
})
export class ShippingLabelsDialog implements AfterViewInit {

  private xLoading: boolean = false;

  get loading() { return this.xLoading; }

  set loading(value: boolean) {
    this.xLoading = value;
    this.cdr.detectChanges();
  }

  private xTemplate: string = '';

  get template() { return this.xTemplate; }

  @ViewChild('iframe') iframeRef: ElementRef<HTMLIFrameElement> = null as any;

  private xObserver: MutationObserver = null as any;

  constructor(
    private cdr: ChangeDetectorRef,
    private messageService: MessageService,
    private dialogRef: MatDialogRef<ShippingLabelsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: ShippingLabelsDialogOptions
  ) {
    this.xTemplate = data.shipping?.labels as string;
  }

  ngAfterViewInit() {
    const { nativeElement: iframe } = this.iframeRef;
    const { contentDocument: $document } = iframe;

    if ($document) {
      this.xObserver = new MutationObserver((mutationsList, observer) => {
        const height = $document.documentElement.scrollHeight;
        iframe.style.height = `${height}px`;
      });

      this.xObserver.observe($document.documentElement, {
        childList: true,
        attributes: true,
        subtree: true
      });

      $document.documentElement.innerHTML = this.template;
    }
  }

  print() {
    const { nativeElement: iframe } = this.iframeRef;
    const { contentWindow: $window } = iframe;

    $window?.print();
  }

  cancel() {
    const { dialogRef } = this;
    dialogRef.close();
  }

}
