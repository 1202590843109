import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { of, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { State } from "../../interfaces/State";
import { MessageService } from "../../services/MessageService";
import { StateService } from "../../services/StateService";

interface StateHash {
  [key: string]: State;
}

@Component({
  selector: 'agc-state-input',
  templateUrl: 'state-input.component.html',
  styleUrls: ['state-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StateInputComponent),
      multi: true
    }
  ]
})
export class StateInputComponent implements ControlValueAccessor, OnInit, AfterViewInit {

  private xHash: StateHash = {};

  private xStateList: State[] = [];

  get stateList() { return this.xStateList; }

  @Input() placeholder: string = '';
  @Input() id: string = '';
  @Input() value: string = '';

  @Input() data?: State;
  @Output() dataChange: EventEmitter<State>;

  @ViewChild('input') inputRef: ElementRef<HTMLSelectElement> = null as any;

  private xCountryCode?: string;

  get countryCode() { return this.xCountryCode; }

  @Input() set countryCode(value: string | undefined) {
    if (typeof (value) !== 'string') value = '';
    this.xCountryCode = value;
    this.load();
  }

  private xRefresh: boolean = false;

  get refresh() { return this.xRefresh; }

  @Input() set refresh(value: boolean) {
    if (typeof (value) !== 'boolean') value = false;
    this.xRefresh = value;
  }

  private xLoading: boolean = false;

  get loading() { return this.xLoading; }

  private xSub: Subscription  = null as any;

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private stateService: StateService,
    private messageService: MessageService
  ) {
    const { nativeElement: element } = elementRef;
    element.tabIndex = 1;

    this.dataChange = new EventEmitter<State>();
  }

  onChange = (value: number) => {};

  writeValue(value: string): void {
    // this.value = value = value || '';
    this.onStateChange(value, true);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    // throw new Error("Method not implemented.");
  }

  setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }

  ngOnInit() {
    this.load();
  }

  ngAfterViewInit() {
    const { nativeElement: input } = this.inputRef;
    const { nativeElement: element } = this.elementRef;

    element.addEventListener('focus', (e: FocusEvent) => {
      input.focus();
    });
  }

  onStateChange(value: string, write: boolean = false) {
    const { xHash, dataChange } = this;

    if (write === true) {
      this.value = value = value || '';
    }

    const parsedValue = (value in xHash) ? parseFloat(value) : null;
    this.onChange(parsedValue as any);

    if (value in xHash) {
      dataChange.emit(xHash[value]);
    } else {
      dataChange.emit();
    }
  }

  private api() {
    this.xHash = {};

    const { refresh, countryCode, stateService } = this;

    const request = (!countryCode) ?
      of<State[]>([]) :
      stateService.all(countryCode, refresh);

    return request.pipe(
      map(resp => {
        for (const state of resp) {
          this.xHash[state.id as number] = state;
        }

        return resp;
      })
    );
  }

  private load() {
    const { xSub, messageService } = this;

    if (xSub) xSub.unsubscribe();

    this.xLoading = true;

    this.xSub = this.api().subscribe({
      next: resp => {
        const { value } = this;
        this.xStateList = resp;
        this.onStateChange(value, true);
      },

      error: error => {
        messageService.handle(error);
        this.xLoading = false;
        this.xSub = null as any;
      },

      complete: () => {
        this.xLoading = false;
        this.xSub = null as any;
      }
    });
  }

}
