import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { filter, first, map, switchMap, take, tap } from "rxjs/operators";
import { AccountService } from "../services/AccountService";
import { MenuService } from "../services/MenuService";
import { MessageService } from "../services/MessageService";
import { RoleService } from "../services/RoleService";

@Injectable()
export class CanActivatePage implements CanActivateChild {

  constructor(
    private router: Router,
    private roleService: RoleService,
    private accountService: AccountService,
    private menuService: MenuService,
    private messageService: MessageService
  ) {}

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    const { router, roleService, menuService, accountService, messageService } = this;

    if (childRoute.firstChild) return true;

    return accountService.account.pipe(
      first(),

      switchMap(resp => {
        if (resp) return of(resp);
        return accountService.me();
      }),

      switchMap(resp => {
        console.log('CanActivatePage', state.url);
        console.log('Account', resp);
        const role = resp?.role || null as any;
        return roleService.canAccess(state.url, role);
      }),

      tap(value => {
        if (value === false) {
          const message = `You do not have permission to access "${state.url}".`;
          messageService.handle(message, false);
        }
      })
    );
  }

}
