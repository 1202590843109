import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { AvailabilityResponse } from "../interfaces/Response";
import { AGCModuleConfig } from "../interfaces/AGCModuleConfig";
import { Admin, AdminPagination } from "../interfaces/Admin";
import { URLHelper } from "../helpers/URLHelper";

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  private xAdminSub: BehaviorSubject<Admin>;
  private xAdmin: Observable<Admin>;

  get admin() { return this.xAdmin; }

  constructor(
    private http: HttpClient,
    private urlHelper: URLHelper
  ) {
    this.xAdminSub = new BehaviorSubject<Admin>(null as any);
    this.xAdmin = this.xAdminSub.asObservable();
  }

  setValue(value: Admin) {
    this.xAdminSub.next(value);
  }

  setAdmin(data: Admin) {
    this.setValue(data);
  }

  all(page: number = 1) {
    const params = new URLSearchParams();
    params.append('page', page.toString());
    const url = this.urlHelper.url(`/admin?${params.toString()}`);
    return this.http.get<AdminPagination>(url);
  }

  find(userId: number) {
    const url = this.urlHelper.url(`/admin/${userId}`);
    return this.http.get<Admin>(url);
  }

  findBy(field: string, value: any) {
    const params = new URLSearchParams();
    params.set('field', field);
    params.set('value', value);

    const url = this.urlHelper.url(`/admin/find?${params.toString()}`);
    return this.http.get<Admin>(url);
  }

  create(data: Admin) {
    const url = this.urlHelper.url(`/admin`);
    return this.http.post<Admin>(url, data);
  }

  update(userId: number, data: Admin) {
    const url = this.urlHelper.url(`/admin/${userId}`);
    return this.http.patch<Admin>(url, data);
  }

  changePassword(adminId: number, data: any) {
    const url = this.urlHelper.url(`/admin/${adminId}/password`);
    return this.http.post<Admin>(url, data);
  }

  emailAvailable(email: string, ignore: string = '') {
    const data = { email, ignore };
    const url = this.urlHelper.url(`/admin/availability/email`);
    return this.http.post<AvailabilityResponse>(url, data);
  }

  phoneAvailable(phone: string, ignore: string = '') {
    const data = { phone, ignore };
    const url = this.urlHelper.url(`/admin/availability/phone`);
    return this.http.post<AvailabilityResponse>(url, data);
  }

  usernameAvailable(username: string, ignore: string = '') {
    const data = { username, ignore };
    const url = this.urlHelper.url(`/admin/availability/username`);
    return this.http.post<AvailabilityResponse>(url, data);
  }

}
