import { Component, Input } from "@angular/core";

@Component({
  selector: 'agc-switch',
  templateUrl: 'switch.component.html',
  styleUrls: ['switch.component.scss']
})
export class SwitchComponent {

  @Input() value?: boolean = false;

  constructor() {}

}
