import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { URLHelper } from "../helpers/URLHelper";
import { EmailMessage } from "../interfaces/Messaging";

@Injectable()
export class EmailMessageService {

  constructor(
    private helper: URLHelper,
    private http: HttpClient
  ) {  }

  send(data: EmailMessage) {
    const url = this.helper.endpoint(`/messaging/email`);
    return this.http.post<string>(url, data);
  }

}
