import { Component, ElementRef, Inject, ViewChild } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { AGCModuleConfig } from "../../../interfaces/AGCModuleConfig";
import { TwoFAData } from "../../../interfaces/TwoFA";
import { MessageService } from "../../../services/MessageService";
import { TwoFAService } from "../../../services/TwoFAService";

@Component({
  selector: 'app-dialog-2fa-manage',
  templateUrl: 'manage.dialog.html',
  styleUrls: ['manage.dialog.scss']
})
export class TwoFAManageDialog {

  loading: boolean;

  enabled: boolean;

  data: TwoFAData = null as any;
  backupList: string[] = [];

  otp: string = '';

  @ViewChild('input') inputRef: ElementRef<HTMLInputElement> = null as any;

  constructor(
    private service: TwoFAService,
    private messageService: MessageService,
    private dialogRef: MatDialogRef<TwoFAManageDialog>,
    @Inject('AGC') private agc: AGCModuleConfig
  ) {
    this.loading = false;
    this.enabled = false;
    this.initialize();
  }

  initialize() {
    const { service, messageService } = this;

    this.loading = true;

    return service.get().subscribe({
      next: resp => {
        this.enabled = resp.enabled;
        this.data = resp.data || null as any;
      },

      error: error => {
        messageService.handle(error, false);
        this.loading = false;
      },

      complete: () => {
        this.loading = false;
      }
    });
  }

  download(backup: string[]) {
    const { type } = this.agc;

    const text = backup.join("\r\n");
    const file = new Blob([text], { type: 'text/plain' });

    const a = document.createElement('a');
    a.download = `agc-courier-${type}-2fa-backup.txt`;
    a.href = URL.createObjectURL(file);
    a.click();

    setTimeout(() => URL.revokeObjectURL(a.href));
  }

  backup() {
    const { service, messageService } = this;

    messageService.confirm(
      '2FA Backup',
      'Previous backup codes will no longer work when you generate new ones. Do you really want to proceed?',
      'warning'
    ).then(value => {
      if (value !== true) return;

      this.loading = true;

      service.backup().subscribe({
        next: resp => {
          messageService.alert('', 'Backup Codes Successfully Generated!', 'success');
          this.backupList = resp.backup;
        },

        error: error => {
          messageService.handle(error, false);
          this.loading = false;
        },

        complete: () => {
          this.loading = false;
        }
      });
      //
    });
  }

  submit() {
    if (!this.data) return;

    const { otp, service, messageService, dialogRef } = this;

    if (!otp) {
      messageService.alert(
        '2FA Verification',
        'Enter the code from your authenticator app to to proceed.',
        'info'
      ).then(() => {
        const { nativeElement } = this.inputRef;
        if (nativeElement) nativeElement.focus();
      });

      return;
    }

    const { secret, backup } = this.data;

    const data = { otp, secret, backup };

    this.loading = true;

    service.enable(data).subscribe({
      next: resp => {
        messageService.alert('', '2FA Successfully Enabled!', 'success').then(() => {
          dialogRef.close(true);
        });
      },

      error: error => {
        messageService.handle(error, false);
        this.loading = false;
      },

      complete: () => {
        this.loading = false;
      }
    });
  }

}
