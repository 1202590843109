<agc-container dialog [loading]="loading">
  <h1 mat-dialog-title>
    <span>{{ (data) ? 'EDIT' : 'ADD NEW' }} MILESTONE</span>
  </h1>

  <mat-dialog-content class="mat-typography">
    <div class="row">
      <div class="col-md-6">
        <h3>Milestone Details</h3>

        <agc-form [formGroup]="form" (submit)="submit()">
          <agc-form-field>
            <label for="">Country <span class="text-danger">*</span></label>
            <agc-country-input [all]="true" formControlName="countryCode" [(data)]="country" required></agc-country-input>
            <agc-message when="required">Country is required.</agc-message>
          </agc-form-field>

          <agc-form-field>
            <label for="">State/Province <span class="text-danger">*</span></label>
            <agc-state-input [countryCode]="country?.code" formControlName="stateId" [(data)]="state" required></agc-state-input>
            <agc-message when="required">State/Province is required.</agc-message>
          </agc-form-field>

          <agc-form-field>
            <span class="float-right">{{ unitPrice | number:'1.2-2' }}</span>
            <label for="">Unit Price <span class="text-danger">*</span></label>
            <input type="number" formControlName="unitPrice" class="form-control" readonly required>
            <agc-message when="required">Unit Price is required.</agc-message>
          </agc-form-field>
        </agc-form>
      </div>

      <div class="col-md-6 detector">
        <h3>Detect Unit Price <small>(with sample parcel)</small></h3>

        <div class="row">
          <div class="col-sm-6">
            <agc-form-field>
              <label for="">Length (cm)</label>
              <input type="number" [ngModel]="priceForm.length" (ngModelChange)="onPriceChange('length', $event)" class="form-control">
            </agc-form-field>
          </div>
          <div class="col-sm-6">
            <agc-form-field>
              <label for="">Width (cm)</label>
              <input type="number" [ngModel]="priceForm.width" (ngModelChange)="onPriceChange('width', $event)" class="form-control">
            </agc-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <agc-form-field>
              <label for="">Height (cm)</label>
              <input type="number" [ngModel]="priceForm.height" (ngModelChange)="onPriceChange('height', $event)" class="form-control">
            </agc-form-field>
          </div>
          <div class="col-sm-6">
            <agc-form-field>
              <label for="">Weight (kg)</label>
              <input type="number" [ngModel]="priceForm.weight" (ngModelChange)="onPriceChange('weight', $event)" class="form-control">
            </agc-form-field>
          </div>
        </div>

        <agc-form-field>
          <label for="">Price  (&#8358;)</label>
          <input type="number" [ngModel]="priceForm.price" (ngModelChange)="onPriceChange('price', $event)" class="form-control">
        </agc-form-field>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button class="btn btn-default btn-md waves-effect waves-light" (click)="cancel()">Cancel</button>
    <button cdkFocusInitial class="btn btn-primary btn-md ml-2 waves-effect waves-light" (click)="formRef?.doSubmit()">Submit</button>
  </mat-dialog-actions>
</agc-container>
