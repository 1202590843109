import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectorRef, Component, Inject, Injector } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { of, throwError } from "rxjs";
import { catchError, map, share, shareReplay, switchMap, tap } from "rxjs/operators";
import { Country } from "../../interfaces/Country";
import { Matrix, MatrixDialogOptions } from "../../interfaces/Matrix";
import { State } from "../../interfaces/State";
import { CountryService } from "../../services/CountryService";
import { MatrixAlgorithmDialogService } from "../../services/dialog/MatrixAlgorithmDialogService";
import { MatrixDialogService } from "../../services/dialog/MatrixDialogService";
import { MatrixEntryDialogService } from "../../services/dialog/MatrixEntryDialogService";
import { MatrixService } from "../../services/MatrixService";
import { MessageService } from "../../services/MessageService";
import { StateService } from "../../services/StateService";

@Component({
  selector: 'app-dialog-matrix',
  templateUrl: 'matrix.dialog.html',
  styleUrls: ['matrix.dialog.scss']
})
export class MatrixDialog {

  get title() { return this.data.title || 'Cost Matrix'; }
  get state() { return this.data.state; }

  country: Country;

  subtitle: string = '';

  loading: boolean = false;

  loadingMatrix: boolean = false;

  matrixList: Matrix[] = [];

  allStates: State[] = [];
  stateList: State[] = [];

  private xText: string = '';

  get text() { return this.xText; }

  set text(value: string) {
    this.xText = value;
    this.search(value);
  }

  destCountry?: Country;

  destCountryCode: string = '';

  destState?: State;

  private xInit: boolean = false;
  get init() { return this.xInit; }

  constructor(
    private cd: ChangeDetectorRef,
    private injector: Injector,
    private entryDialogService: MatrixEntryDialogService,
    private algorithmDialogService: MatrixAlgorithmDialogService,
    private matrixService: MatrixService,
    private stateService: StateService,
    private countryService: CountryService,
    private messageService: MessageService,
    private dialogRef: MatDialogRef<MatrixDialog>,
    @Inject(MAT_DIALOG_DATA) private data: MatrixDialogOptions
  ) {
    this.destCountryCode = data.state.countryCode as string;
    this.destState = data.state;

    this.country = data.country as Country;

    const { country, state } = this;

    this.subtitle = `${state.name}, ${country.name}`;

    this.initialize();
  }

  private initialize(fetch: boolean = true) {
    of<any>(null).pipe(
      // switchMap(resp => this.getDestinationStates()),

      // switchMap(resp => this.getMatrix())
    ).subscribe({
      complete: () => {
        this.xInit = true;
      }
    });
  }

  onCountryChange(data: Country) {
    const { destCountryCode } = this;

    if (data) {
      this.destCountryCode = data.code;
      this.destCountry = data;

      if (destCountryCode !== data.code) {
        this.destState = null as any;
      }

      console.log('OnCountryChange:', data);

      this.initNewCountry();
    }
  }

  initNewCountry() {
    const { state, destState, destCountry, stateService, messageService } = this;

    const result = of(destCountry).pipe(
      tap(resp => this.loading = true),

      // Load Destination States
      switchMap(resp => {
        return stateService.all(destCountry?.code || '').pipe(
          tap(resp => {
            this.allStates = resp;
            this.text = '';
            this.setDestinationState(destState || state);
            console.log('InitNewCountry:', destState);
          })
        );
      }),

      // Get Matrix List
      switchMap(resp => this.getMatrix()),

      tap(resp => this.loading = false),

      catchError(error => {
        this.loading = false;
        messageService.handle(error, false);
        return throwError(error);
      })
    );

    result.subscribe();

    return result;
  }

  getDestinationStates() {
    const { state, destState, destCountry, stateService, messageService } = this;

    this.loading = true;

    stateService.all(destCountry?.code || '').subscribe({
      next: resp => {
        this.allStates = resp;
        this.loading = false;
        this.text = '';

        this.setDestinationState(destState || state);
      },

      error: error => {
        messageService.handle(error, false);
        this.loading = false;
      }
    });
  }

  setDestinationState(state: State, e?: Event) {
    if (e) e.preventDefault();
    this.destState = state;

    this.matrixList = [];

    // console.log('Set Destination State:', state);

    if (state && e) {
      this.getMatrix();
    }
  }

  isDestinationState(state: State) {
    const { destState } = this;
    if (!destState) return false;
    return destState.id === state.id;
  }

  search(text: string) {
    const { allStates } = this;

    if (!text) {
      this.stateList = allStates;
    } else {
      const lower = text.toLowerCase();

      this.stateList = allStates.filter(state => {
        const name = (state.name as string).toLowerCase();
        return name.indexOf(lower) >= 0;
      });
    }
  }

  loadStates() {
    const { country, stateService, messageService } = this;

    this.loading = true;

    return stateService.all(country.code as string, true).pipe(
      tap(resp => {
        const { text } = this;
        this.allStates = resp;
        this.loading = false;
        this.search(text);
      }),

      catchError(error => {
        messageService.handle(error, false);
        this.loading = false;
        return throwError(error);
      })
    );
  }

  getMatrix() {
    const { state, destState, matrixService, messageService } = this;

    this.loadingMatrix = true;

    const result = matrixService.all(state.id || 0, destState?.id).pipe(
      tap(resp => {
        this.matrixList = resp;
        this.loadingMatrix = false;
      }),

      catchError(error => {
        messageService.handle(error, false);
        this.loadingMatrix = false;
        return throwError(error);
      })
    );

    result.subscribe();

    return result;
  }

  setAlgorithm() {
    const { state, country, destState, destCountry } = this;
    const { algorithmDialogService, messageService } = this;

    const result =  algorithmDialogService.open({
      fromCountry: country,
      fromState: state,
      toCountry: destCountry!,
      toState: destState!
    });

    result.subscribe();

    return result;
  }

  ensureAlgorithm() {
    const { state, country, destState, destCountry } = this;
    const { matrixService, algorithmDialogService, messageService } = this;

    const fromStateId = state.id || 0;
    const toStateId = destState?.id || 0;

    this.loading = true;

    const result = matrixService.getAlgorithm(fromStateId, toStateId).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 404) {
          return algorithmDialogService.open({
            fromCountry: country,
            fromState: state,
            toCountry: destCountry!,
            toState: destState!
          });
        }

        return throwError(error);
      }),

      tap(resp => this.loading = false),

      switchMap(resp => {
        if (!resp) return throwError('You cannot proceed without an algorithm');
        return of(resp);
      }),

      catchError(error => {
        this.loading = false;
        messageService.handle(error, false);
        return throwError(error);
      }),

      shareReplay(1)
    );

    // result.subscribe();

    return result;
  }

  add() {
    const { state, destState, entryDialogService } = this;

    this.ensureAlgorithm().pipe(
      switchMap(resp => {
        return entryDialogService.open({
          fromState: state,
          toState: destState!
        });
      })
    ).subscribe(resp => {
      if (resp) this.getMatrix();
    });
  }

  edit(data: Matrix) {
    const { state, destState, entryDialogService } = this;

    this.ensureAlgorithm().pipe(
      switchMap(resp => {
        return entryDialogService.open({
          fromState: state,
          toState: destState!,
          matrix: data
        });
      })
    ).subscribe(resp => {
      if (resp) Object.assign(data, resp);
    });
  }

  delete(data: Matrix) {
    const { matrixService, messageService } = this;

    messageService.confirm(
      'Delete Matrix',
      'Do you really want to DELETE this matrix? This action is irreversible.',
      'warning'
    ).then(value => {
      if (value === false) return;

      this.loading = true;

      matrixService.delete(data.id as number).subscribe({
        next: resp => {
          const index = this.matrixList.indexOf(data);
          this.matrixList.splice(index, 1);
          this.loading = false;
        },

        error: error => {
          messageService.handle(error, false);
          this.loading = false;
        }
      });
    });
  }

  close() {
    this.dialogRef.close();
  }

}
