import { InjectionToken } from "@angular/core";

export declare type PortalType = 'admin' | 'users' | 'agents' | 'business';

interface ReCaptchaData {
  SITE_KEY: string;
}

interface PaystackData {
  PUBLIC_KEY: string;
}

interface GoogleMapData {
  API_KEY: string;
}

export interface Environment {
  api: string;
  production: boolean;
  recaptcha: ReCaptchaData;
  paystack: PaystackData;
  googleMap: GoogleMapData;
}

export interface AGCModuleConfig {
  type: PortalType;
  environment: Environment;
}

export const AGC_CONFIG = new InjectionToken<AGCModuleConfig>('AGC');
