import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { UsersAddDialog } from "../../dialog/users/add/add.dialog";
import { User } from "../../interfaces/User";

@Injectable()
export class UserDialogService {

  constructor(
    private dialog: MatDialog
  ) {  }

  add(data: User = null as any): Observable<User> {
    const dialog = this.dialog.open(UsersAddDialog, {
      width: '90vw',
      maxWidth: '700px',
      disableClose: true,
      data
    });

    return dialog.afterClosed();
  }

}
