import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, ComponentRef, OnDestroy, ViewChild, ViewContainerRef } from "@angular/core";
import { Subscription } from "rxjs";
import { first } from "rxjs/operators";
import { Account } from "../../interfaces/Account";
import { BankFormComponent } from "../../interfaces/Bank";
import { AGCComponent } from "../../interfaces/Component";
import { AccountService } from "../../services/AccountService";
import { BankwireBankDetailsFormComponent } from "./bankwire-bank-details-form/bankwire-bank-details-form.component";
import { PaystackBankDetailsFormComponent } from "./paystack-bank-details-form/paystack-bank-details-form.component";

interface ComponentHash {
  [key: string]: AGCComponent<BankFormComponent>[];
}

const FormComponentHash: ComponentHash = {
  // Nigeria
  'NG': [PaystackBankDetailsFormComponent]
};

const DefaultFormComponents: AGCComponent<BankFormComponent>[] = [
  // BankwireBankDetailsFormComponent
];

@Component({
  selector: 'agc-bank-details-form',
  templateUrl: 'bank-details-form.component.html',
  styleUrls: ['bank-details-form.component.scss']
})
export class BankDetailsFormComponent implements AfterViewInit, OnDestroy {

  private xSub: Subscription = null as any;

  private componentRefs: ComponentRef<BankFormComponent>[] = [];

  private xAccount: Account = null as any;
  get account() { return this.xAccount; }

  @ViewChild('container', {
    read: ViewContainerRef
  }) container: ViewContainerRef = null as any;

  constructor(
    private cd: ChangeDetectorRef,
    private accountService: AccountService,
    private factoryResolver: ComponentFactoryResolver
  ) {}

  ngOnDestroy() {
    this.xSub?.unsubscribe();
  }

  ngAfterViewInit() {
    const { cd, accountService } = this;

    this.xSub = accountService.account.pipe(
      first()
    ).subscribe(account => {
      this.xAccount = account || null as any;
      const countryCode: string = account?.countryCode || '';
      this.createFormByCountry(countryCode);
    });

    cd.detectChanges();
  }

  clear() {
    const { container, componentRefs } = this;

    while (componentRefs.length) {
      const componentRef = componentRefs.pop() as ComponentRef<BankFormComponent>;
      componentRef.destroy();
    }

    container.clear();
  }

  getComponentsByCountryCode(code: string) {
    if (code in FormComponentHash) {
      return FormComponentHash[code];
    }

    return DefaultFormComponents;
  }

  createFormByCountry(code: string) {
    if (!this.container) return;

    this.clear();

    if (code) {
      const componentList = this.getComponentsByCountryCode(code);

      if (componentList.length) {
        const { factoryResolver, container } = this;

        for (const component of componentList) {
          const factory = factoryResolver.resolveComponentFactory(component);
          const componentRef = container.createComponent(factory);
          this.componentRefs.push(componentRef);
        }
      }
    }
  }

}
