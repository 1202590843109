import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { MessageService } from "../services/MessageService";
import { StateService } from "../services/StateService";

@Injectable()
export class CanActivateState implements CanActivateChild {

  constructor(
    private router: Router,
    private messageService: MessageService,
    private stateService: StateService
  ) {}

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    const { router, stateService, messageService } = this;

    const { params } = childRoute;

    return stateService.find(params['stateId']).pipe(
      map(data => {
        stateService.setState(data);
        return true;
      }),

      catchError(error => {
        messageService.handle(error);
        router.navigate(['../']);
        return of(false);
      })
    );
  }

}
