import { Component, Input } from "@angular/core";

@Component({
  selector: 'agc-status',
  templateUrl: 'status.component.html',
  styleUrls: ['status.component.scss']
})
export class StatusComponent {

  private xValue?: string = '';

  get value() { return this.xValue; }

  @Input() set value(value: string | undefined) {
    this.xValue = (value || '').toUpperCase();
    this.update();
  }

  private xColor: string = '';

  get color() { return this.xColor; }

  constructor() {}

  private update() {
    const { value } = this;

    switch (value) {
      case 'DRAFT':
      case 'IN_REVIEW':
      case 'PENDING': this.xColor = '#e99904'; break;

      case 'DECLINED':
      case 'SUSPENDED':
      case 'FAILED': this.xColor = '#F00'; break;

      case 'ACTIVE':
      case 'SUCCESS': this.xColor = '#090'; break;

      default: this.xColor = ''; break;
    }
  }

}
