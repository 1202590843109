<h1 mat-dialog-title>View Transaction</h1>

<mat-dialog-content class="mat-typography">
  <!--  -->
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button>
</mat-dialog-actions>
