<div class="wrapper form-group form-primary">
  <div class="content">
    <ng-content></ng-content>
    <span class="form-bar"></span>
  </div>

  <div class="pending" *ngIf="pending">
    <span>Just a moment please...</span>
  </div>

  <div class="error" [hidden]="!showError">
    <ng-content select="agc-message"></ng-content>
  </div>
</div>
