import { Directive, Input, Optional } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from "@angular/forms";
import { PhoneInputComponent } from "../components/phone-input/phone-input.component";
import { Country } from "../interfaces/Country";

@Directive({
  selector: '[phone]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: PhoneValidationDirective,
      multi: true
    }
  ]
})
export class PhoneValidationDirective implements Validator {

  constructor(
    @Optional() private input: PhoneInputComponent
  ) {
    if (!input) {
      const message = `'phone' validator can be used only on 'PhoneInputComponent'`;
      throw new Error(message);
    }
  }

  validate(control: AbstractControl): ValidationErrors {
    const { input: { country } } = this;
    const error = { phone: true };

    const value = control.value as string;

    if (!value) return null as any;
    if (!country) return error;

    const prefixList = country.prefix.split(/[,\s]+/g);

    for (const prefix of prefixList) {
      if (!value.startsWith(prefix)) continue;

      const phone = value.substring(prefix.length);
      if (this.matchLength(phone)) return null as any;
    }

    return error;
  }

  private matchLength(phone: string) {
    const { input: { country } } = this;

    return (
      phone.length === country?.landlineLength ||
      phone.length === country?.mobileLength
    );
  }

}
