<agc-container dialog [loading]="loading">
  <h1 mat-dialog-title>
    <span>Set Algorithm</span>
  </h1>

  <mat-dialog-content class="mat-typography">
    <agc-form [formGroup]="form" (submit)="submit()">
      <div class="text-center mb-3">
        <div><strong>{{ data.fromState.name }}, {{ data.fromCountry.name }}</strong></div>
        <div>to</div>
        <div><strong>{{ data.toState.name }}, {{ data.toCountry.name }}</strong></div>
      </div>

      <hr>

      <agc-form-field>
        <label for="">Algorithm <span class="text-danger">*</span></label>
        <select formControlName="algorithm" class="form-control" required>
          <option value="FLAT_RANGE_WE">FLAT_RANGE_WE</option>
        </select>
        <agc-message when="required">Matrix Algorithm is required.</agc-message>
      </agc-form-field>
    </agc-form>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button class="btn btn-default btn-md waves-effect waves-light" (click)="close()">Close</button>
    <div class="flex"></div>
    <button class="btn btn-success btn-md ml-2 waves-effect waves-light" (click)="formRef.doSubmit()">Continue</button>
  </mat-dialog-actions>
</agc-container>
