import { inject, Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { finalize, map, Observable, of, switchMap } from "rxjs";
import { OfficeDialog } from "../../dialog/office/office.dialog";
import { Office, OfficeDialogOptions } from "../../interfaces/Office";
import { LoadingService } from "../LoadingService";
import { MessageService } from "../MessageService";
import { OfficeService } from "../OfficeService";
import { ServiceService } from "../ServiceService";

@Injectable()
export class OfficeDialogService {

  private readonly dialog = inject(MatDialog);
  private readonly service = inject(OfficeService);
  private readonly loadingService = inject(LoadingService);
  private readonly messageService = inject(MessageService);
  private readonly serviceService = inject(ServiceService);

  open(office?: Office): Observable<Office> {
    const { loadingService, serviceService } = this;

    return of(null).pipe(
      switchMap(() => {
        loadingService.start();

        return serviceService.all().pipe(
          finalize(() => loadingService.stop()),
          map(services => ({ office, services } as OfficeDialogOptions)),
        );
      }),

      map(data => this.dialog.open(OfficeDialog, {
        width: '90vw',
        maxWidth: '1150px',
        disableClose: true,
        data
      })),

      switchMap(dlg => dlg.afterClosed())
    );
  }

}
