import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Region } from '../../interfaces/Region';
import { MessageService } from '../../services/MessageService';
import { RegionService } from '../../services/RegionService';

interface RegionHash {
  [key: string]: Region;
}

@Component({
  selector: 'agc-region-input',
  templateUrl: 'region-input.component.html',
  styleUrls: ['region-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RegionInputComponent),
      multi: true
    }
  ]
})
export class RegionInputComponent implements ControlValueAccessor, OnInit, AfterViewInit {

  private xHash: RegionHash = {};

  private xRegionList: Region[] = [];

  get regionList() { return this.xRegionList; }

  @Input() placeholder: string = '';
  @Input() id: string = '';
  @Input() value: string = '';

  @Input() data?: Region;
  @Output() dataChange: EventEmitter<Region>;

  @ViewChild('input') inputRef: ElementRef<HTMLSelectElement> = null as any;

  private xStateId?: number;

  get stateId() { return this.xStateId; }

  @Input() set stateId(value: number | undefined) {
    if (typeof (value) !== 'number') {
      value = null as any;
    }

    this.xStateId = value;
    this.load();
  }

  private xRefresh: boolean = false;

  get refresh(): boolean { return this.xRefresh; }

  @Input() set refresh(value: boolean) {
    if (typeof (value) !== 'boolean') {
      value = false;
    }

    this.xRefresh = value;
  }

  private xLoading: boolean = false;

  get loading() { return this.xLoading; }

  private xSub: Subscription  = null as any;

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private regionService: RegionService,
    private messageService: MessageService
  ) {
    const { nativeElement: element } = elementRef;
    element.tabIndex = 1;

    this.dataChange = new EventEmitter<Region>();
  }

  onChange = (value: number) => {};

  writeValue(value: string): void {
    // this.value = value = value || '';
    this.onRegionChange(value, true);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    // throw new Error("Method not implemented.");
  }

  setDisabledRegion?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }

  ngOnInit() {
    this.load();
  }

  ngAfterViewInit() {
    const { nativeElement: input } = this.inputRef;
    const { nativeElement: element } = this.elementRef;

    element.addEventListener('focus', (e: FocusEvent) => {
      input.focus();
    });
  }

  onRegionChange(value: string, write: boolean = false) {
    const { xHash, dataChange } = this;

    if (write === true) {
      this.value = value = value || '';
    }

    const parsedValue = (value in xHash) ? parseFloat(value) : null;
    this.onChange(parsedValue as any);

    if (value in xHash) {
      dataChange.emit(xHash[value]);
    } else {
      dataChange.emit();
    }
  }

  private api() {
    this.xHash = {};

    const { refresh, stateId, regionService } = this;

    const request = (!stateId) ?
      of<Region[]>([]) :
      regionService.all(stateId, refresh);

    return request.pipe(
      map(resp => {
        for (const state of resp) {
          this.xHash[state.id as number] = state;
        }

        return resp;
      })
    );
  }

  private load() {
    const { xSub, messageService } = this;

    if (xSub) xSub.unsubscribe();

    this.xLoading = true;

    this.xSub = this.api().subscribe({
      next: resp => {
        const { value } = this;
        this.xRegionList = resp;
        this.onRegionChange(value, true);
      },

      error: error => {
        messageService.handle(error);
        this.xLoading = false;
        this.xSub = null as any;
      },

      complete: () => {
        this.xLoading = false;
        this.xSub = null as any;
      }
    });
  }

}
