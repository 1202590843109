import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { AvailabilityResponse } from "../interfaces/Response";
import { Agent, AgentPagination } from "../interfaces/Agent";
import { URLHelper } from "../helpers/URLHelper";

@Injectable({
  providedIn: 'root'
})
export class AgentService {

  private xAgentSub: BehaviorSubject<Agent>;
  private xAgent: Observable<Agent>;

  get agent() { return this.xAgent; }

  constructor(
    private http: HttpClient,
    private urlHelper: URLHelper
  ) {
    this.xAgentSub = new BehaviorSubject<Agent>(null as any);
    this.xAgent = this.xAgentSub.asObservable();
  }

  setValue(value: Agent) {
    this.xAgentSub.next(value);
  }

  setUser(data: Agent) {
    this.setValue(data);
  }

  all(page: number = 1) {
    const params = new URLSearchParams();
    params.append('page', page.toString());
    const url = this.urlHelper.endpoint(`/agents?${params.toString()}`);
    return this.http.get<AgentPagination>(url);
  }

  search(page: number = 1) {
    const params = new URLSearchParams();
    params.append('page', page.toString());
    const url = this.urlHelper.endpoint(`/agents?${params.toString()}`);
    return this.http.get<AgentPagination>(url);
  }

  find(userId: number) {
    const url = this.urlHelper.endpoint(`/agents/${userId}`);
    return this.http.get<Agent>(url);
  }

  findBy(field: string, value: any) {
    const params = new URLSearchParams();
    params.set('field', field);
    params.set('value', value);

    const url = this.urlHelper.endpoint(`/agents/find?${params.toString()}`);
    return this.http.get<Agent>(url);
  }

  create(data: Agent) {
    const url = this.urlHelper.endpoint(`/agents`);
    return this.http.post<Agent>(url, data);
  }

  update(userId: number, data: Agent) {
    const url = this.urlHelper.endpoint(`/agents/${userId}`);
    return this.http.patch<Agent>(url, data);
  }

  changePassword(agentId: number, data: any) {
    const url = this.urlHelper.endpoint(`/agents/${agentId}/password`);
    return this.http.post<Agent>(url, data);
  }

  emailAvailable(email: string, ignore: string = '') {
    const data = { email, ignore };
    const url = this.urlHelper.endpoint(`/agents/availability/email`);
    return this.http.post<AvailabilityResponse>(url, data);
  }

  phoneAvailable(phone: string, ignore: string = '') {
    const data = { phone, ignore };
    const url = this.urlHelper.endpoint(`/agents/availability/phone`);
    return this.http.post<AvailabilityResponse>(url, data);
  }

  usernameAvailable(username: string, ignore: string = '') {
    const data = { username, ignore };
    const url = this.urlHelper.endpoint(`/agents/availability/username`);
    return this.http.post<AvailabilityResponse>(url, data);
  }

}
