import { ChangeDetectorRef, Component, inject, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Shipping, ShippingDialogOptions } from "../../../interfaces/Shipping";
import { Invoice } from "../../../interfaces/Transaction";
import { InvoiceDialogService } from "../../../services/dialog/InvoiceDialogService";
import { MessageService } from "../../../services/MessageService";
import { ShippingService } from "../../../services/ShippingService";
import { InvoiceService } from "../../../services/InvoiceService";
import { finalize } from "rxjs";


type Options = Required<ShippingDialogOptions>;

@Component({
  selector: 'app-dialog-shipping-preview',
  templateUrl: 'preview.dialog.html',
  styleUrls: ['preview.dialog.scss']
})
export class ShippingPreviewDialog {

  private readonly cdr =  inject(ChangeDetectorRef);
  private readonly messageService =  inject(MessageService);
  private readonly shippingService =  inject(ShippingService);

  private readonly invoiceService =  inject(InvoiceService);
  private readonly invoiceDialog =  inject(InvoiceDialogService);

  private readonly dialogRef =  inject(MatDialogRef<ShippingPreviewDialog>);

  readonly data: ShippingDialogOptions = inject(MAT_DIALOG_DATA);

  public loading = false;

  public shipping: Shipping;
  public invoice?: Invoice;

  constructor() {
    const { data: { shipping } } = this;

    this.shipping = shipping!;
    this.invoice = shipping?.invoice;
  }

  createInvoice() {
    const { shipping, invoiceService, messageService, dialogRef } = this;

    const account = shipping.account!;

    this.loading = true;

    invoiceService.create({
      accountId: account.id,
      currencyCode: 'NGN',
      items: [{
        code: 'shipping',
        description: `Payment for Shipping #${shipping.code}`,
        reference: `${shipping.id}`,
        amount: 100,
        quantity: 1
      }]
    }).pipe(
      finalize(() => this.loading = false)
    ).subscribe({
      next: resp => {
        this.invoice = resp;
        this.viewInvoice();
      },

      error: error => messageService.handle(error, false)
    });

    // dialogRef.close(resp);
  }

  viewInvoice() {
    const { invoice, shipping, invoiceDialog, messageService, dialogRef } = this;

    if (!invoice) return;

    invoiceDialog.open(invoice).subscribe({
      next: (invoice: any) => {
        if (!invoice) return;
        dialogRef.close(shipping);
      },

      error: (error: any) => messageService.handle(error, false),
    });
  }

  create() {
    const { shipping, shippingService, messageService, dialogRef } = this;

    this.loading = true;

    shippingService.create(shipping).pipe(
      finalize(() => this.loading = false),
    ).subscribe({
      next: resp => {
        this.shipping = resp;
        this.createInvoice();
      },

      error: error => messageService.handle(error, false)
    });
  }

  submit() {
    const { data, dialogRef } = this;
    dialogRef.close(data.shipping);
  }

  cancel() {
    const { dialogRef } = this;
    dialogRef.close();
  }

}
