import { Directive, Input } from "@angular/core";
import { AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors } from "@angular/forms";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AccountService } from "../services/AccountService";
import { UserService } from "../services/UserService";

@Directive({
  selector: '[userNameAvailable]',
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: UserUsernameAvailableValidationDirective,
      multi: true
    }
  ]
})
export class UserUsernameAvailableValidationDirective implements AsyncValidator {

  @Input('userNameAvailable') prefix: string = '';

  constructor(
    private userService: UserService,
    private accountService: AccountService
  ) { }

  validate(control: AbstractControl): Promise<ValidationErrors> | Observable<ValidationErrors> {
    const { prefix = '', userService, accountService } = this;
    const error = { userNameAvailable: true };

    if (!control.value) return of(null as any);

    return accountService.usernameAvailable(control.value, prefix).pipe(
      map(resp => {
        if (resp.available) return null as any;
        return error;
      }),

      catchError(resp => of(error))
    );
  }

}
