import { AfterContentInit, ContentChildren, Directive, ElementRef, Input, QueryList } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ErrorComponent } from "../components/error/error.component";

@Directive({
  selector: '[numeric]'
})
export class NumberInputDirective {

  constructor(
    private el: ElementRef<HTMLInputElement>
  ) {
    this.validate = this.validate.bind(this);

    const { nativeElement: input } = el;

    if (input.tagName.toUpperCase() !== 'INPUT') {
      throw 'numeric directive can be used only on input element!';
    }

    input.addEventListener('keydown', this.validate);
  }

  private validate(e: KeyboardEvent) {
    const { el: { nativeElement } } = this;

    if (e.key.length > 1) return;

    if (isNaN(e.key as any)) {
      e.preventDefault();
    }
  }

}
