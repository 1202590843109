import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { URLHelper } from "../helpers/URLHelper";
import { AGCModuleConfig } from "../interfaces/AGCModuleConfig";

export interface DashboardChart {
  data?: any[];
  xkey: string;
  ykeys: string[];
  redraw?: boolean;
  hideHover?: string;
  labels: string[];
  lineColors: string[];
}

export interface DashboardSummary {
  customers: number;
  shipments: number;
  transactions: number;
  income: number;
}

@Injectable()
export class DashboardService {

  constructor(
    private http: HttpClient,
    private urlHelper: URLHelper
  ) { }

  getSummary() {
    const url = this.urlHelper.endpoint(`/dashboard`);
    return this.http.get<DashboardSummary>(url);
  }

  getChart() {
    const url = this.urlHelper.endpoint(`/dashboard/chart`);
    return this.http.get<DashboardChart>(url);
  }

}
