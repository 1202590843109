import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { MessageService } from "../services/MessageService";
import { TransactionService } from "../services/TransactionService";

@Injectable()
export class CanActivateTransaction implements CanActivateChild {

  constructor(
    private router: Router,
    private messageService: MessageService,
    private transactionService: TransactionService
  ) {}

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    const { router, transactionService, messageService } = this;

    const { params } = childRoute;

    return transactionService.find(params['transactionId']).pipe(
      map(data => {
        transactionService.setTransaction(data);
        return true;
      }),

      catchError(error => {
        messageService.handle(error);
        router.navigate(['/home/transactions']);
        return of(false);
      })
    );
  }

}
