<div class="header" [ngStyle]="{'top': stickyHeaderTop+'px'}">
  <div class="left">
    <i
      class="icofont"
      [ngClass]="{'icofont-ui-close': sidebar, 'icofont-navigation-menu': !sidebar}"
      (click)="toggleSidebar()"
    ></i>
  </div>
  <div class="right">
    <ng-content select="agc-frame-header"></ng-content>
  </div>
</div>

<div class="frame-body" [ngClass]="{'open': sidebar}">
  <div class="sidebar" (click)="closeSidebarOnClick($event)">
    <ng-content select="agc-frame-sidebar"></ng-content>
  </div>

  <div class="content">
    <ng-content select="agc-frame-content"></ng-content>
  </div>

  <div class="overlay" (click)="closeSidebarFromOverlay($event)"></div>
</div>
