import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { RouterModule } from '@angular/router';

import { AGCFormComponent } from './components/form/form.component';
import { ErrorComponent } from './components/error/error.component';
import { MessageComponent } from './components/message/message.component';
import { FormFieldComponent } from './components/form-field/form-field.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { PaginationInfoComponent } from './components/pagination/info/info.component';
import { PhoneInputComponent } from './components/phone-input/phone-input.component';
import { CountryInputComponent } from './components/country-input/country-input.component';
import { StateInputComponent } from './components/state-input/state-input.component';
import { RegionInputComponent } from './components/region-input/region-input.component';
import { SwitchComponent } from './components/switch/switch.component';
import { FrameComponent } from './components/frame/frame.component';
import { FrameHeaderComponent } from './components/frame/header/header.component';
import { FrameSidebarComponent } from './components/frame/sidebar/sidebar.component';
import { FrameContentComponent } from './components/frame/content/content.component';
import { PreloaderComponent } from './components/preloader/preloader.component';
import { ContainerComponent } from './components/container/container.component';
import { ParcelComponent } from './components/parcel/parcel.component';
import { ParcelIconComponent } from './components/parcel/icon/icon.component';
import { StatusComponent } from './components/status/status.component';
import { MorrisChartComponent } from './components/morris-chart/morris-chart.component';
import { RecaptchaComponent } from './components/recaptcha/recaptcha.component';
import { TwoFAComponent } from './components/2fa/2fa.component';
import { CountryListComponent } from './components/country-list/country-list.component';
import { AgentListComponent } from './components/agent-list/agent-list.component';
import { AccountListComponent } from './components/account-list/account-list.component';
import { AccountReviewComponent } from './components/account-review/account-review.component';
import { RecipientsInputComponent } from './components/recipients-input/recipients-input.component';
import { SMSInputComponent } from './components/sms-input/sms-input.component';
import { HomeFrameComponent } from './components/home-frame/home-frame.component';
import { EditorComponent } from './components/editor/editor.component';
import { MapInputComponent } from './components/map-input/map-input.component';
import { AmountComponent } from './components/amount/amount.component';
import { OfficeHourInputComponent } from './components/office-hour/office-hour.component';
import { InputArrayComponent } from './components/input-array/input-array.component';
import { InputArrayItemComponent } from './components/input-array/item/item.component';
// Bank Details
import { BankDetailsFormComponent } from './components/bank-details-form/bank-details-form.component';
import { PaystackBankDetailsFormComponent } from './components/bank-details-form/paystack-bank-details-form/paystack-bank-details-form.component';
import { BankwireBankDetailsFormComponent } from './components/bank-details-form/bankwire-bank-details-form/bankwire-bank-details-form.component';

import { MousewheelDirective } from './directives/MousewheelDirective';
import { PcodedHasMenu } from './directives/PcodedHasMenu';
import { FormValidate } from './directives/FormValidate';
import { NumberInputDirective } from './directives/NumberInputDirective';

import { EqualsValidationDirective } from './validators/EqualsValidationDirective';
import { DigitsValidationDirective } from './validators/DigitsValidationDirective';
import { PhoneValidationDirective } from './validators/PhoneValidationDirective';
import { UsernameValidationDirective } from './validators/UsernameValidationDirective';
import { AccountEmailAvailableValidationDirective } from './validators/AccountEmailAvailableValidationDirective';
import { AccountPhoneAvailableValidationDirective } from './validators/AccountPhoneAvailableValidationDirective';
import { AccountUsernameAvailableValidationDirective } from './validators/AccountUsernameAvailableValidationDirective';
// ******** Deprecated ********
import { AgentEmailAvailableValidationDirective } from './validators/AgentEmailAvailableValidationDirective';
import { AgentUsernameAvailableValidationDirective } from './validators/AgentUsernameAvailableValidationDirective';
import { UserEmailAvailableValidationDirective } from './validators/UserEmailAvailableValidationDirective';
import { UserUsernameAvailableValidationDirective } from './validators/UserUsernameAvailableValidationDirective';

import { ForPipe } from './pipes/ForPipe';
import { FullnamePipe } from './pipes/FullnamePipe';
import { MatrixAmountPipe } from './pipes/MatrixAmountPipe';

import { URLHelper } from './helpers/URLHelper';

import { AccountService } from './services/AccountService';
import { LoadingService } from './services/LoadingService';
import { MessageService } from './services/MessageService';
import { TokenInterceptor } from './services/TokenInterceptor';
import { ErrorInterceptor } from './services/ErrorInterceptor';
import { TransactionService } from './services/TransactionService';
import { ShippingService } from './services/ShippingService';
import { CountryService } from './services/CountryService';
import { StateService } from './services/StateService';
import { RegionService } from './services/RegionService';
import { UserService } from './services/UserService';
import { AgentService } from './services/AgentService';
import { AdminService } from './services/AdminService';
import { BusinessService } from './services/BusinessService';
import { InvoiceService } from './services/InvoiceService';
import { PaymentService } from './services/PaymentService';
import { DashboardService } from './services/DashboardService';
import { ShippingDialogService } from './services/dialog/ShippingDialogService';
import { TwoFAService } from './services/TwoFAService';
import { AccountActionService } from './services/AccountActionService';
import { RoutingService } from './services/RoutingService';
import { GoogleMapService } from './services/GoogleMapService';
import { MatrixService } from './services/MatrixService';
import { MenuService } from './services/MenuService';
import { RoleService } from './services/RoleService';
import { SMSMessageService } from './services/SMSMessageService';
import { EmailMessageService } from './services/EmailMessageService';
import { OfficeService } from './services/OfficeService';
import { WalletService } from './services/WalletService';
import { WalletEntryService } from './services/WalletEntryService';
import { WindowService } from './services/WindowService';
import { PopupService } from './services/PopupService';
import { ServiceService } from './services/ServiceService';
// Dialog
import { TransactionDialogService } from './services/dialog/TransactionDialogService';
import { ShippingItemDialogService } from './services/dialog/ShippingItemDialogService';
import { ShippingPreviewDialogService } from './services/dialog/ShippingPreviewDialogService';
import { InvoiceDialogService } from './services/dialog/InvoiceDialogService';
import { TwoFADialogService } from './services/dialog/TwoFADialogService';
import { RoutingDialogService } from './services/dialog/RoutingDialogService';
import { RoutingMilestoneDialogService } from './services/dialog/RoutingMilestoneDialogService';
import { StateDialogService } from './services/dialog/StateDialogService';
import { MatrixDialogService } from './services/dialog/MatrixDialogService';
import { MatrixEntryDialogService } from './services/dialog/MatrixEntryDialogService';
import { MatrixAlgorithmDialogService } from './services/dialog/MatrixAlgorithmDialogService';
import { AdminDialogService } from './services/dialog/AdminDialogService';
import { RoleDialogService } from './services/dialog/RoleDialogService';
import { AgentDialogService } from './services/dialog/AgentDialogService';
import { BusinessDialogService } from './services/dialog/BusinessDialogService';
import { UserDialogService } from './services/dialog/UserDialogService';
import { OfficeDialogService } from './services/dialog/OfficeDialogService';
import { WalletDialogService } from './services/dialog/WalletDialogService';
import { TopupDialogService } from './services/dialog/TopupDialogService';
import { PaymentDialogService } from './services/dialog/PaymentDialogService';

// Payment
import { PaystackService } from './services/payment/PaystackService';


import { DashboardResolver } from './resolvers/DashboardResolver';
import { CountryListResolver } from './resolvers/CountryListResolver';

import { CanActivateAccess } from './guards/CanActivateAccess';
import { CanActivateMenu } from './guards/CanActivateMenu';
import { CanActivatePage } from './guards/CanActivatePage';
import { CanActivateResetPassword } from './guards/CanActivateResetPassword';
import { CanActivateEmailVerification } from './guards/CanActivateEmailVerification';
import { CanActivateHome } from './guards/CanActivateHome';
import { CanActivateAdmin } from './guards/CanActivateAdmin';
import { CanActivateAgent } from './guards/CanActivateAgent';
import { CanActivateBusiness } from './guards/CanActivateBusiness';
import { CanActivateUser } from './guards/CanActivateUser';
import { CanActivateUserTransaction } from './guards/CanActivateUserTransaction';
import { CanActivateUserShipping } from './guards/CanActivateUserShipping';
import { CanActivateTransaction } from './guards/CanActivateTransaction';
import { CanActivateShipping } from './guards/CanActivateShipping';
import { CanActivateCountry } from './guards/CanActivateCountry';
import { CanActivateState } from './guards/CanActivateState';
import { CanActivateRouting } from './guards/CanActivateRouting';
import { CanActivateRoutingState } from './guards/CanActivateRoutingState';

import { TransactionViewDialog } from './dialog/transactions/view/view.dialog';
import { UsersAddDialog } from './dialog/users/add/add.dialog';
import { ShippingAddDialog } from './dialog/shipping/add/add.dialog';
import { ShippingItemDialog } from './dialog/shipping/item/item.dialog';
import { ShippingPreviewDialog } from './dialog/shipping/preview/preview.dialog';
import { ShippingLabelsDialog } from './dialog/shipping/labels/labels.dialog';
import { InvoiceDialog } from './dialog/invoice/invoice.dialog';
import { TwoFAManageDialog } from './dialog/2fa/manage/manage.dialog';
import { TwoFAAuthorizeDialog } from './dialog/2fa/authorize/authorize.dialog';
import { AdminDialog } from './dialog/admins/admin.dialog';
import { BusinessesDialog } from './dialog/businesses/businesses.dialog';
import { AgentsAddDialog } from './dialog/agents/add/add.dialog';
import { RoutingsAddDialog } from './dialog/routings/add/add.dialog';
import { RoutingsManageDialog } from './dialog/routings/manage/manage.dialog';
import { RoutingMilestoneDialog } from './dialog/routings/milestone/milestone.dialog';
import { StateListDialog } from './dialog/states/states.dialog';
import { MatrixDialog } from './dialog/matrix/matrix.dialog';
import { MatrixEntryDialog } from './dialog/matrix/entry/entry.dialog';
import { MatrixAlgorithmDialog } from './dialog/matrix/algorithm/algorithm.dialog';
import { MatrixGenerateDialog } from './dialog/matrix/generate/generate.dialog';
import { RoleDialog } from './dialog/roles/role.dialog';
import { OfficeDialog } from './dialog/office/office.dialog';
import { WalletDialog } from './dialog/wallet/wallet.dialog';
import { TopupDialog } from './dialog/topup/topup.dialog';
import { PaymentDialog } from './dialog/payment/payment.dialog';

import { AGCModuleConfig, AGC_CONFIG } from './interfaces/AGCModuleConfig';
import { Path } from './helpers/Path';
import { DownloadService } from './services/DownloadService';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatDialogModule,
    DragDropModule
  ],
  declarations: [
    // Components
    AGCFormComponent,
    ErrorComponent,
    MessageComponent,
    FormFieldComponent,
    PaginationComponent,
    PaginationInfoComponent,
    PhoneInputComponent,
    CountryInputComponent,
    StateInputComponent,
    RegionInputComponent,
    SwitchComponent,
    FrameComponent,
    FrameHeaderComponent,
    FrameSidebarComponent,
    FrameContentComponent,
    PreloaderComponent,
    ContainerComponent,
    ParcelComponent,
    ParcelIconComponent,
    StatusComponent,
    MorrisChartComponent,
    RecaptchaComponent,
    TwoFAComponent,
    CountryListComponent,
    AgentListComponent,
    AccountListComponent,
    AccountReviewComponent,
    RecipientsInputComponent,
    SMSInputComponent,
    HomeFrameComponent,
    EditorComponent,
    MapInputComponent,
    BankDetailsFormComponent,
    PaystackBankDetailsFormComponent,
    BankwireBankDetailsFormComponent,
    AmountComponent,
    OfficeHourInputComponent,
    InputArrayComponent,
    InputArrayItemComponent,
    // Directives
    PcodedHasMenu,
    MousewheelDirective,
    FormValidate,
    NumberInputDirective,
    // Validators
    EqualsValidationDirective,
    DigitsValidationDirective,
    PhoneValidationDirective,
    UsernameValidationDirective,
    AccountEmailAvailableValidationDirective,
    AccountUsernameAvailableValidationDirective,
    AccountPhoneAvailableValidationDirective,
    // ******** Deprecated ********
    AgentEmailAvailableValidationDirective,
    AgentUsernameAvailableValidationDirective,
    UserEmailAvailableValidationDirective,
    UserUsernameAvailableValidationDirective,
    // Pipes
    ForPipe,
    FullnamePipe,
    MatrixAmountPipe,
    // Dialogs
    TransactionViewDialog,
    UsersAddDialog,
    ShippingAddDialog,
    ShippingItemDialog,
    ShippingPreviewDialog,
    ShippingLabelsDialog,
    InvoiceDialog,
    TwoFAManageDialog,
    TwoFAAuthorizeDialog,
    AdminDialog,
    BusinessesDialog,
    AgentsAddDialog,
    RoutingsAddDialog,
    RoutingsManageDialog,
    RoutingMilestoneDialog,
    StateListDialog,
    MatrixDialog,
    MatrixEntryDialog,
    MatrixAlgorithmDialog,
    MatrixGenerateDialog,
    RoleDialog,
    OfficeDialog,
    WalletDialog,
    TopupDialog,
    PaymentDialog,
  ],
  exports: [
    // Components
    AGCFormComponent,
    ErrorComponent,
    MessageComponent,
    FormFieldComponent,
    PaginationComponent,
    PaginationInfoComponent,
    PhoneInputComponent,
    CountryInputComponent,
    StateInputComponent,
    RegionInputComponent,
    SwitchComponent,
    FrameComponent,
    FrameHeaderComponent,
    FrameSidebarComponent,
    FrameContentComponent,
    PreloaderComponent,
    ContainerComponent,
    ParcelComponent,
    ParcelIconComponent,
    StatusComponent,
    MorrisChartComponent,
    RecaptchaComponent,
    TwoFAComponent,
    CountryListComponent,
    AgentListComponent,
    AccountListComponent,
    AccountReviewComponent,
    RecipientsInputComponent,
    SMSInputComponent,
    HomeFrameComponent,
    EditorComponent,
    MapInputComponent,
    BankDetailsFormComponent,
    PaystackBankDetailsFormComponent,
    BankwireBankDetailsFormComponent,
    AmountComponent,
    OfficeHourInputComponent,
    InputArrayComponent,
    InputArrayItemComponent,
    // Directives
    PcodedHasMenu,
    MousewheelDirective,
    FormValidate,
    NumberInputDirective,
    // Validators
    EqualsValidationDirective,
    DigitsValidationDirective,
    PhoneValidationDirective,
    UsernameValidationDirective,
    AccountEmailAvailableValidationDirective,
    AccountUsernameAvailableValidationDirective,
    AccountPhoneAvailableValidationDirective,
    // ******** Deprecated ********
    AgentEmailAvailableValidationDirective,
    AgentUsernameAvailableValidationDirective,
    UserEmailAvailableValidationDirective,
    UserUsernameAvailableValidationDirective,
    // Pipes
    ForPipe,
    FullnamePipe,
  ],
})
export class AGCCourierModule {
  public static forRoot(config: AGCModuleConfig): ModuleWithProviders<AGCCourierModule> {
    return {
      ngModule: AGCCourierModule,
      providers: [
        // Helpers
        Path,
        URLHelper,
        // Services

        LoadingService,
        MessageService,
        UserService,
        AgentService,
        AdminService,
        BusinessService,
        AccountService,
        TransactionService,
        ShippingService,
        CountryService,
        StateService,
        RegionService,
        InvoiceService,
        PaymentService,
        DashboardService,
        TwoFAService,
        AccountActionService,
        RoutingService,
        GoogleMapService,
        OfficeService,
        MatrixService,
        MenuService,
        RoleService,
        SMSMessageService,
        EmailMessageService,
        PaystackService,
        DownloadService,
        WalletService,
        WalletEntryService,
        WindowService,
        PopupService,
        ServiceService,

        // Dialog Services
        TransactionDialogService,
        UserDialogService,
        BusinessDialogService,
        ShippingDialogService,
        ShippingItemDialogService,
        ShippingPreviewDialogService,
        InvoiceDialogService,
        TwoFADialogService,
        AdminDialogService,
        AgentDialogService,
        RoutingDialogService,
        RoutingMilestoneDialogService,
        StateDialogService,
        MatrixDialogService,
        MatrixEntryDialogService,
        MatrixAlgorithmDialogService,
        OfficeDialogService,
        RoleDialogService,
        WalletDialogService,
        TopupDialogService,
        PaymentDialogService,

        // Resolvers
        DashboardResolver,
        CountryListResolver,

        // Route Guards
        CanActivateAccess,
        CanActivateMenu,
        CanActivatePage,
        CanActivateResetPassword,
        CanActivateEmailVerification,
        CanActivateHome,
        CanActivateAdmin,
        CanActivateAgent,
        CanActivateBusiness,
        CanActivateUser,
        CanActivateUserTransaction,
        CanActivateUserShipping,
        CanActivateTransaction,
        CanActivateShipping,
        CanActivateCountry,
        CanActivateState,
        CanActivateRouting,
        CanActivateRoutingState,

        // Interceptors
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

        // Configuration
        { provide: 'AGC', useValue: config },
        { provide: AGC_CONFIG, useValue: config }
      ]
    };
  }
}
