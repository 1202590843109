import { Component } from "@angular/core";

@Component({
  selector: 'agc-preloader',
  templateUrl: 'preloader.component.html',
  styleUrls: ['preloader.component.scss']
})
export class PreloaderComponent {

  constructor() {  }

}
