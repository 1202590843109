import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";
import { Subscription } from "rxjs";
import { Account, AccountPagination, AccountSearchData } from "../../interfaces/Account";
import { Agent, AgentPagination } from "../../interfaces/Agent";
import { ListAction, ListActionString } from "../../interfaces/ListAction";
import { AccountService } from "../../services/AccountService";
import { AgentService } from "../../services/AgentService";
import { LoadingService } from "../../services/LoadingService";
import { MessageService } from "../../services/MessageService";

type StringFn = (data: Account) => string;

@Component({
  selector: 'agc-account-list',
  templateUrl: 'account-list.component.html',
  styleUrls: ['account-list.component.scss']
})
export class AccountListComponent implements AfterViewInit {

  private xRecords?: AccountPagination;
  get records() { return this.xRecords; }

  @Input() name: string = '';
  @Input() type: string = '';
  @Input() status: string = '';
  @Input() sort: string = '';
  @Input() actions: ListAction<Account>[] = [];
  @Input() companyName: boolean = false;
  @Input() roles: boolean = false;

  get alias() {
    const { name, type } = this;
    if (name) return name.toLowerCase();
    return type?.toLowerCase();
  }

  constructor(
    private cd: ChangeDetectorRef,
    private loadingService: LoadingService,
    private messageService: MessageService,
    private accountService: AccountService
  ) {  }

  ngAfterViewInit() {
    setTimeout(() => this.fetch(), 0);
    // this.cd.detectChanges();
  }

  fetch(page: number = 1) {
    const { type, status, sort } = this;
    const { cd, loadingService, accountService, messageService } = this;

    loadingService.start();

    const data: AccountSearchData = { page, type, status, sort };

    accountService.search(data).subscribe({
      next: resp => {
        this.xRecords = resp;
      },

      error: messageService.handle,

      complete: () => {
        loadingService.stop();
      }
    });
  }

  str(value?: string | ListActionString<Account>): StringFn {
    return (data: Account) => {
      if (typeof (value) === 'undefined') return '';
      if (typeof (value) === 'string') return value;
      return value(data);
    };
  }

  show(action: ListAction<Account>, data: Account): boolean {
    if (typeof (action.show) === 'undefined') return true;
    return action.show(data);
  }

}
