<agc-pagination [data]="records" (callback)="fetch($event)">
  <div class="text-center" *ngIf="!records?.total">No {{ alias }} found</div>

  <table class="table table-striped table-hover table-text" *ngIf="records?.total">
    <thead>
      <tr class="footable-header">
        <th>ID</th>
        <th>Name</th>
        <th *ngIf="companyName === true">Company</th>
        <th>Username</th>
        <th>Email</th>
        <th>Phone</th>
        <th *ngIf="roles === true">Role</th>
        <th>Status</th>
        <th>Created</th>
        <th>Updated</th>
        <th *ngIf="actions?.length"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of records!.data">
        <td>{{ data.id }}</td>
        <td>{{ data | fullname:false }}</td>
        <td *ngIf="companyName === true">{{ data.meta.companyName || '-' }}</td>
        <td>{{ data.username }}</td>
        <td>{{ data.email }}</td>
        <td>{{ data.phone || '-' }}</td>
        <td *ngIf="roles === true">{{ data.role?.name }}</td>
        <td><agc-status [value]="data.status"></agc-status></td>
        <td>{{ data.createdAt | date:'short' }}</td>
        <td>{{ data.updatedAt | date:'short' }}</td>
        <td class="text-right" *ngIf="actions?.length">
          <ng-container *ngFor="let action of actions">
            <a href="javascript:;" class="label" [ngClass]="str(action.cssClass)(data)" (click)="action.callback($event, data)" *ngIf="show(action, data)">
              <i [ngClass]="str(action.icon)(data)" *ngIf="action.icon"></i>
              <span *ngIf="action.text">&nbsp; {{ str(action.text)(data) }}</span>
            </a>
          </ng-container>
        </td>
      </tr>
    </tbody>
  </table>
</agc-pagination>
