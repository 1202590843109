import { HttpErrorResponse } from "@angular/common/http";
import { AfterViewInit, ChangeDetectorRef, Component, Inject, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Region } from "../../../interfaces/Region";
import { AGCFormComponent } from "../../../components/form/form.component";
import { Country } from "../../../interfaces/Country";
import { Shipping, ShippingDialogOptions, ShippingItem } from "../../../interfaces/Shipping";
import { State } from "../../../interfaces/State";
import { User } from "../../../interfaces/User";
import { MessageService } from "../../../services/MessageService";
import { ShippingItemDialogService } from "../../../services/dialog/ShippingItemDialogService";
import { ShippingPreviewDialogService } from "../../../services/dialog/ShippingPreviewDialogService";
import { ShippingService } from "../../../services/ShippingService";
import { UserDialogService } from "../../../services/dialog/UserDialogService";
import { UserService } from "../../../services/UserService";
import { catchError, finalize, of, switchMap, throwError } from "rxjs";

interface SearchFormData {
  field?: string;
  value?: string;
}

interface SearchField {
  label: string;
  value: string;
  placeholder: string;
}

interface SearchFieldHash {
  [key: string]: SearchField;
}

const SearchFieldList: SearchField[] = [
  { label: 'Email Address', value: 'email', placeholder: 'Enter Email Address' },
  { label: 'Username', value: 'username', placeholder: 'Enter Username' },
  // { label: 'User ID', value: 'id', placeholder: 'Enter User ID' }
];

const SearchFieldMap: SearchFieldHash = SearchFieldList.reduce((carry, item) => {
  carry[item.value] = item;
  return carry;
}, {} as any);

@Component({
  selector: 'app-dialog-shipping-add',
  templateUrl: 'add.dialog.html',
  styleUrls: ['add.dialog.scss']
})
export class ShippingAddDialog {

  form: FormGroup = new FormGroup({
    accountId: new FormControl(),
    contactName: new FormControl(),
    contactEmail: new FormControl(),
    contactPhone: new FormControl(),
    fromCountryCode: new FormControl(),
    fromStateId: new FormControl(),
    fromRegionId: new FormControl(),
    fromZipPostal: new FormControl(),
    fromAddress1: new FormControl(),
    fromAddress2: new FormControl(),
    toCountryCode: new FormControl(),
    toStateId: new FormControl(),
    toRegionId: new FormControl(),
    toZipPostal: new FormControl(),
    toAddress1: new FormControl(),
    toAddress2: new FormControl(),
    toLandmark: new FormControl(),
  });

  items: ShippingItem[] = [];

  @ViewChild(AGCFormComponent) formRef?: AGCFormComponent;

  private xLoading: boolean = false;
  get loading() { return this.xLoading; }
  set loading(value: boolean) {
    this.xLoading = value;
    this.cdr.detectChanges();
  }

  fromCountry?: Country;
  fromState?: State;
  fromRegion?: Region;
  toCountry?: Country;
  toState?: State;
  toRegion?: Region;

  user?: User;

  searchData: SearchFormData = {
    field: 'email'
  };

  get placeholder() {
    const { field = null } = this.searchData;
    if (!field || !(field in SearchFieldMap)) return '';
    return SearchFieldMap[field].placeholder;
  }

  get fieldList() { return SearchFieldList; }

  constructor(
    private cdr: ChangeDetectorRef,
    private userService: UserService,
    private shippingService: ShippingService,
    private userDialogService: UserDialogService,
    private shippingItemDialogService: ShippingItemDialogService,
    private previewDialogService: ShippingPreviewDialogService,
    private messageService: MessageService,
    private dialogRef: MatDialogRef<ShippingAddDialog>,
    @Inject(MAT_DIALOG_DATA) public data: ShippingDialogOptions
  ) {
    const { form } = this;
    // if (data) form.patchValue(data.shipping || {});

    if (data.account) {
      this.setUser(data.account);
    }
  }

  findUser() {
    const { userService, messageService } = this;
    const { field = '', value = '' } = this.searchData;

    this.loading = true;

    userService.findBy(field, value).subscribe({
      next: resp => {
        this.setUser(resp);
      },

      error: error => {
        if (error instanceof HttpErrorResponse && error.status === 404) {
          this.addUser({ [field]: value });
        } else {
          messageService.handle(error, false);
        }

        this.loading = false;
      },

      complete: () => {
        this.loading = false;
      }
    });
  }

  addUser(data: any = null) {
    const { userDialogService } = this;

    userDialogService.add(data).subscribe(user => {
      if (user) this.setUser(user);
    });
  }

  setUser(user: User) {
    this.user = user;

    const data: Shipping = {
      accountId: user.id,
      fromCountryCode: user.countryCode,
      fromStateId: user.stateId,
      fromRegionId: user.regionId,
      fromAddress1: user.address1,
      fromAddress2: user.address2
    };

    this.form.patchValue(data);
  }

  changeUser() {
    this.user = null as any;
  }

  addShippingItem() {
    const { shippingItemDialogService } = this;

    shippingItemDialogService.open().subscribe(resp => {
      if (!resp) return;
      this.items.push(resp);
      this.updatePricing();
    });
  }

  editShippingItem(item: ShippingItem) {
    const { shippingItemDialogService } = this;

    shippingItemDialogService.open(item).subscribe(resp => {
      if (!resp) return;
      Object.assign(item, resp);
      this.updatePricing();
    });
  }

  deleteShippingItem(item: ShippingItem) {
    const index = this.items.indexOf(item);
    this.items.splice(index, 1);
    this.updatePricing();
  }

  updatePricing() {
    const { items } = this;
  }

  submit() {
    const { form, items, previewDialogService, shippingService, messageService, dialogRef } = this;

    if (!items.length) {
      messageService.alert('', 'Please add at least one parcel.', 'error');
      return;
    }

    const data = form.value as Shipping;

    data.fromCountry = this.fromCountry;
    data.fromState = this.fromState;
    data.fromRegion = this.fromRegion;

    data.toCountry = this.toCountry;
    data.toState = this.toState;
    data.toRegion = this.toRegion;

    data.account = this.user;
    data.items = items;

    previewDialogService.open({
      shipping: data,
      // confirmation: true
    }).subscribe(value => {
      if (!value) return;
      dialogRef.close(value);

      // this.loading = true;

      // shippingService.create(data).subscribe({
      //   next: resp => {
      //     dialogRef.close(resp);
      //   },

      //   error: error => {
      //     messageService.handle(error, false);
      //     this.loading = false;
      //   },

      //   complete: () => {
      //     this.loading = false;
      //   }
      // });
    });
  }

  cancel() {
    const { dialogRef } = this;
    dialogRef.close();
  }

}
