import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, forwardRef, ViewChild } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { GoogleMapService } from "../../services/GoogleMapService";

type OnValueChange = (value: string) => void;

interface MapCoords {
  lat: number;
  lng: number;
}

@Component({
  selector: 'agc-map-input',
  templateUrl: 'map-input.component.html',
  styleUrls: ['map-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MapInputComponent),
      multi: true
    }
  ]
})
export class MapInputComponent implements ControlValueAccessor, AfterViewInit {

  private map: any = null;
  private marker: any = null;

  value: string = '';
  onChange: OnValueChange = null as any;

  @ViewChild('map') targetRef: ElementRef<HTMLDivElement> = null as any;

  constructor(
    private cd: ChangeDetectorRef,
    private mapService: GoogleMapService
  ) {  }

  private toCoords(value: string): MapCoords {
    const coords: MapCoords = {} as any;

    try {
      if (!value) throw 'No value found!';

      const [lat, lng] = value.split(',');

      coords.lat = parseFloat(lat);
      coords.lng = parseFloat(lng);

      if (isNaN(coords.lat)) throw 'Invalid latitude';
      if (isNaN(coords.lng)) throw 'Invalid longitude';

      return coords;
    } catch (error) {
      // Do nothing...
    }

    return { lat: 6.521635135849637, lng: 3.3190657581819663 };
  }

  writeValue(value: string) {
    this.value = value;
    this.updateMapView(value);
  }

  registerOnChange(fn: OnValueChange) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    //
  }

  private updateMapView(value: string) {
    const { cd, map, mapService, targetRef } = this;

    const coord = this.toCoords(value);

    if (map) {
      map.setCenter(coord);
      return;
    }

    if (targetRef) {
      const { nativeElement: target } = targetRef;

      mapService.init(() => {
        const { google } = window as any;

        this.map = new google.maps.Map(target, {
          zoom: 5,
          center: coord,
          zoomControl: true,
          mapTypeControl: true,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: true,
        });

        const svgMarker = {
          path: "M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
          fillColor: "#f2a341",
          fillOpacity: 1,
          strokeWeight: 0,
          rotation: 0,
          scale: 2,
          anchor: new google.maps.Point(15, 30),
        };

        this.marker = new google.maps.Marker({
          title: "Location",
          position: coord,
          icon: svgMarker,
          // draggable: true,
          optimized: true,
          map: this.map,
        });

        this.map.addListener("center_changed", () => {
          const center = this.map.getCenter();
          this.marker.setPosition(center);

          const newValue = center.toUrlValue();
          this.onChange(newValue);
          this.value = newValue;

          cd.detectChanges();
        });
      });
    }
  }

  ngAfterViewInit() {
    const { cd, value } = this;
    this.updateMapView(value);
    cd.detectChanges();
  }

}
