import { AfterContentInit, Component, ContentChildren, ElementRef, Input, OnInit, QueryList } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { share } from "rxjs/operators";
import { MessageComponent } from "../message/message.component";

interface MessageComponentHash {
  [key: string]: MessageComponent
}

@Component({
  selector: 'agc-error',
  templateUrl: 'error.component.html',
  styleUrls: ['error.component.scss']
})
export class ErrorComponent implements OnInit, AfterContentInit {

  @Input('name') name: string = '';
  @ContentChildren(MessageComponent) messages: QueryList<MessageComponent> = null as any;

  get show() {
    const { nativeElement } = this.elem;
    return nativeElement.hasAttribute('hidden');
  }

  @Input('show') set show(value: boolean) {
    const { nativeElement } = this.elem;

    if (value === true) {
      nativeElement.setAttribute('hidden', '');
      this.update();
    } else {
      nativeElement.removeAttribute('hidden');
    }
  };

  hash: MessageComponentHash = null as any;

  ctrl: FormControl = null as any;

  constructor(
    private elem: ElementRef<HTMLElement>
  ) {}

  initialize(formGroup: FormGroup) {
    this.hash = {};

    const { name, messages } = this;

    messages.forEach(message => {
      this.hash[message.when] = message;
    });

    const ctrl = this.ctrl = formGroup.get(name) as FormControl;

    if (!ctrl) return;

    // this.show = ctrl.dirty || ctrl.touched;

    ctrl.valueChanges.subscribe(value => {
      this.update();
    });

    this.update();
  }

  private update() {
    const { name, ctrl, messages, hash } = this;

    if (!ctrl || !messages) return;

    messages.forEach(message => {
      message.hidden = true;
    });

    if (ctrl.invalid && (ctrl.dirty || ctrl.touched)) {
      for (let key in ctrl.errors) {
        if (!(key in hash)) continue;
        hash[key].hidden = false;
        return;
      }
    }
  }

  ngOnInit() {
    // console.log('Init:', this.name);
  }

  ngAfterContentInit() {
    // console.log('Content Init:', this.name);
  }

}
