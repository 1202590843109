<div class="top">
  <div class="title" [title]="data.name" (click)="toggle()">{{ data.name }}</div>

  <div class="icons" [hidden]="!icons.length">
    <ng-content select="agc-parcel-icon"></ng-content>
  </div>
</div>

<div class="content" *ngIf="expand">
  <pre>{{ data.description }}</pre>

  <div class="note" *ngIf="data.note">
    <h4>Note</h4>
    <pre>{{ data.note }}</pre>
  </div>
</div>

<div class="bottom">
  <div class="p-col">
    <h5>Length</h5>
    <div>{{ data.length | number }}cm</div>
  </div>
  <div class="p-col">
    <h5>Width</h5>
    <div>{{ data.width | number }}cm</div>
  </div>
  <div class="p-col">
    <h5>Height</h5>
    <div>{{ data.height | number }}cm</div>
  </div>
  <div class="p-col">
    <h5>Weight</h5>
    <div>{{ data.weight | number }}kg</div>
  </div>
  <div class="p-col">
    <h5>Quantity</h5>
    <div>{{ data.quantity | number }}</div>
  </div>
</div>
