import { Component, inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormControl, FormGroup } from "@angular/forms";

import { WalletTopupOptions } from "../../interfaces/Wallet";
import { Currency } from "../../interfaces/Currency";
import { WalletService } from "../../services/WalletService";
import { InvoiceData } from "../../interfaces/Transaction";
import { InvoiceService } from "../../services/InvoiceService";
import { InvoiceDialogService } from "../../services/dialog/InvoiceDialogService";
import { finalize, tap } from "rxjs";
import { MessageService } from "../../services/MessageService";
import { DecimalPipe } from "@angular/common";

type Options = Required<WalletTopupOptions>;

@Component({
  selector: 'app-dialog-topup',
  templateUrl: 'topup.dialog.html',
  styleUrls: ['topup.dialog.scss']
})
export class TopupDialog {

  private readonly invoiceService = inject(InvoiceService);
  private readonly invoiceDialog = inject(InvoiceDialogService);
  private readonly messageService = inject(MessageService);

  private readonly options = inject<Options>(MAT_DIALOG_DATA);
  private readonly dialogRef = inject(MatDialogRef<TopupDialog>);

  private readonly decimal = new DecimalPipe('en-US');

  readonly form = new FormGroup({
    currencyCode: new FormControl(''),
    amount: new FormControl(0),
  });

  loading: boolean = false;

  readonly min: number;
  readonly currency: Currency;

  constructor() {
    const { options: { wallet, amount } } = this;

    this.currency = wallet.currency;

    this.form.patchValue({
      currencyCode: wallet.currencyCode,
      amount: amount,
    });

    switch (wallet.currencyCode) {
      case 'NGN': this.min = 1000; break;
      default: this.min = 5;
    }
  }

  async submit() {
    const { form, options, invoiceService, messageService, dialogRef } = this;
    const { currency, decimal } = this;

    const amount = form.value.amount!;
    const formatted = decimal.transform(amount);

    const proceed = await messageService.confirm(
      'Wallet Topup',
      `You are about to add ${currency.symbol}${formatted} to your wallet. Do you want to proceed?`,
      'info',
    );

    if (!proceed) return;

    this.loading = true;

    invoiceService.create({
      currencyCode: options.wallet.currencyCode,
      items: [{
        code: 'wallet::topup',
        description: 'Wallet Top Up',
        amount: form.value.amount!,
        quantity: 1,
      }]
    }).pipe(
      finalize(() => this.loading = false),
    ).subscribe({
      next: invoice => dialogRef.close(invoice),
      error: error => messageService.handle(error, false),
    });
  }

  close() {
    const { dialogRef } = this;
    dialogRef.close(null);
  }

}
