export class WinData<T = any> {

  constructor(
    public readonly error: boolean,
    public readonly message: string,
    public readonly data?: T
  ) {}

  static fromData<T = any>(data: any): WinData<T> {
    if (typeof (data) !== 'object') data = {
      error: true,
      message: 'Invalid data supplied'
    };

    const error = 'error' in data ? data['error'] : false;
    const message = 'message' in data ? data['message'] : '';
    const content = 'data' in data ? data['data'] : null;

    return new WinData<T>(error, message, content);
  }

}
