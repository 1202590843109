import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { URLHelper } from "../helpers/URLHelper";
import { Office, OfficePagination } from "../interfaces/Office";
import { Search } from "../interfaces/Search";

@Injectable()
export class OfficeService {

  private readonly http = inject(HttpClient);
  private readonly helper = inject(URLHelper);

  all(args?: Search) {
    const { http, helper } = this;
    const url = helper.endpoint(`/offices`, '', args);
    return http.get<OfficePagination>(url);
  }

  find(id: number) {
    const { http, helper } = this;
    const url = helper.endpoint(`/offices/${id}`);
    return http.get<Office>(url);
  }

  add(data: Office) {
    const { http, helper } = this;
    const url = helper.endpoint(`/offices`);
    return http.post<Office>(url, data);
  }

  edit(id: number, data: Office) {
    const { http, helper } = this;
    const url = helper.endpoint(`/offices/${id}`);
    return http.patch<Office>(url, data);
  }

  delete(id: number) {
    const { http, helper } = this;
    const url = helper.endpoint(`/offices/${id}`);
    return http.delete<Office>(url);
  }

}
