import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, ReplaySubject, Observable, of } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { AvailabilityResponse, DefaultResponse, LoginResponse, PasswordResetResponse, PostLoginResponse, SignupResponse } from "../interfaces/Response";
import { Account, AccountPagination, AccountSearchData, LoginData, SignupData } from "../interfaces/Account";
import { URLHelper } from "../helpers/URLHelper";
import { AccountActionService } from "./AccountActionService";
import { LoadingService } from "./LoadingService";
import { PermissionMap } from "../interfaces/Role";
import { MenuService } from "./MenuService";

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  readonly account: Observable<Account>;
  private xAccountSub: ReplaySubject<Account>;

  readonly token: Observable<string>;
  private xTokenSub: ReplaySubject<string>;

  private xPermissions: PermissionMap = {};
  get permissions() { return this.xPermissions; }

  constructor(
    private http: HttpClient,
    private urlHelper: URLHelper,
    private menuService: MenuService,
    private loadingService: LoadingService,
    private actionService: AccountActionService,
  ) {
    this.xAccountSub = new ReplaySubject(1);
    this.account = this.xAccountSub.asObservable();

    this.xTokenSub = new ReplaySubject(1);
    this.token = this.xTokenSub.asObservable();

    this.refresh = this.refresh.bind(this);

    window.addEventListener('storage', this.refresh);

    this.refresh();
  }

  private updatePermissions(account?: Account) {
    this.xPermissions = {};

    if (account && account.role) {
      for(let entry of account.role.permissions) {
        this.xPermissions[entry.alias] = entry;
      }
    }
  }

  refresh() {
    const token: string = localStorage.getItem('token') || null as any;
    this.xTokenSub.next(token);
    return of(token);
  }

  setToken(token?: string) {
    if (token) {
      localStorage.setItem('token', token);
    } else {
      localStorage.removeItem('token');
    }

    this.xTokenSub.next(token!);
  }

  clearToken() {
    this.setToken();
  }

  clear() {
    const { menuService } = this;

    this.setAccount();
    this.clearToken();

    menuService.clear();
  }

  setAccount(account?: Account | null) {
    this.updatePermissions(account as any);
    this.xAccountSub.next(account as any);
  }

  me<T extends Account = Account>() {
    const url = this.urlHelper.endpoint('/me');

    return this.http.get<T>(url).pipe(
      tap(resp => this.setAccount(resp))
    );
  }

  signup(data: SignupData) {
    const url = this.urlHelper.endpoint('/signup');
    return this.http.post<SignupResponse>(url, data);
  }

  login(data: LoginData) {
    const { actionService, loadingService } = this;
    const url = this.urlHelper.endpoint('/login');

    let info: LoginResponse = null as any;

    return this.http.post<LoginResponse>(url, data).pipe(
      map(resp => info = resp),

      switchMap(resp => {
        loadingService.stop();
        return actionService.run('login', resp);
      }),

      switchMap(resp => {
        loadingService.start();
        return this.postLogin(info.aplc);
      })
    );
  }

  postLogin(aplc: string) {
    const url = this.urlHelper.endpoint('/authorize');
    return this.http.post<PostLoginResponse>(url, { aplc }).pipe(
      map(resp => { this.setToken(resp.token); return resp; })
    );
  }

  logout() {
    const url = this.urlHelper.endpoint('/logout');
    return this.http.post<LoginResponse>(url, {}).pipe(
      map(resp => {
        this.clear();
        return resp;
      })
    );
  }

  update<T extends Account = Account>(data: T) {
    const url = this.urlHelper.endpoint('/update');
    return this.http.patch<T>(url, data);
  }

  changePassword<T extends Account = Account>(data: any) {
    const url = this.urlHelper.endpoint('/password');
    return this.http.post<T>(url, data);
  }

  forgotPassword(data: any) {
    const url = this.urlHelper.endpoint('/password/forgot');
    return this.http.post<DefaultResponse>(url, data);
  }

  resetPassword(data: any) {
    const url = this.urlHelper.endpoint('/password/reset');
    return this.http.post<DefaultResponse>(url, data);
  }

  getPasswordReset(token: string) {
    const url = this.urlHelper.endpoint(`/password/${token}`);
    return this.http.get<PasswordResetResponse>(url);
  }

  verifyEmail<T extends Account = Account>(token: string) {
    const url = this.urlHelper.endpoint('/verify/email');
    return this.http.post<T>(url, { token });
  }

  all(data: AccountSearchData) {
    const params = new URLSearchParams();

    for (const key in data) {
      params.append(key, data[key]);
    }

    const url = this.urlHelper.endpoint(`/accounts?${params.toString()}`);
    return this.http.get<AccountPagination>(url);
  }

  search(data: AccountSearchData) {
    const params = new URLSearchParams();

    for (const key in data) {
      params.append(key, data[key]);
    }

    const url = this.urlHelper.endpoint(`/accounts?${params.toString()}`);
    return this.http.get<AccountPagination>(url);
  }

  emailAvailable(email: string, prefix: string = '') {
    const data = { email };
    const url = this.urlHelper.endpoint('/availability/email', prefix);
    return this.http.post<AvailabilityResponse>(url, data);
  }

  usernameAvailable(username: string, prefix: string = '') {
    const data = { username };
    const url = this.urlHelper.endpoint('/availability/username', prefix);
    return this.http.post<AvailabilityResponse>(url, data);
  }

  phoneAvailable(phone: string, prefix: string = '') {
    const data = { phone };
    const url = this.urlHelper.endpoint('/availability/phone', prefix);
    return this.http.post<AvailabilityResponse>(url, data);
  }

  approve<T extends Account = Account>(id: number | string) {
    const url = this.urlHelper.endpoint('/status/approve');
    return this.http.patch<T>(url, { id });
  }

  decline<T extends Account = Account>(id: number | string) {
    const url = this.urlHelper.endpoint('/status/decline');
    return this.http.patch<T>(url, { id });
  }

  suspend<T extends Account = Account>(id: number | string) {
    const url = this.urlHelper.endpoint(`/accounts/${id}/suspend`);
    return this.http.patch<T>(url, {});
  }

  unsuspend<T extends Account = Account>(id: number | string) {
    const url = this.urlHelper.endpoint(`/accounts/${id}/unsuspend`);
    return this.http.patch<T>(url, {});
  }

  trash<T extends Account = Account>(id: number | string) {
    const url = this.urlHelper.endpoint(`/accounts/${id}`);
    return this.http.delete<T>(url);
  }

}
