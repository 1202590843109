import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { TransactionViewDialog } from "../../dialog/transactions/view/view.dialog";
import { TransactionService } from "../TransactionService";

@Injectable()
export class TransactionDialogService {

  constructor(
    private dialog: MatDialog,
    private transactionService: TransactionService
  ) {  }

  add() {
    // const dialog = this.dialog.open();
  }

  open() {
    const dialog = this.dialog.open(TransactionViewDialog);
  }

}
