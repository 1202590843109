import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AccountService } from "../services/AccountService";
import { MessageService } from "../services/MessageService";

@Injectable()
export class CanActivateEmailVerification implements CanActivate {

  constructor(
    private router: Router,
    private accountService: AccountService,
    private messageService: MessageService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    const { router, accountService, messageService } = this;

    const { token } = route.params;
    if (!token) return false;

    return accountService.verifyEmail(token).pipe(
      map(resp => {
        accountService.setAccount(resp);
        return true;
      }),

      catchError(error => {
        messageService.handle(error);
        router.navigateByUrl('/');
        return of(false);
      })
    );
  }

}
