<agc-container dialog [loading]="loading">
  <h1 mat-dialog-title>Two-Factor Authentication</h1>

  <mat-dialog-content class="mat-typography">
    <div class="form-group">
      <label for="">Two-factor authentication code</label>
      <input type="text" #input [(ngModel)]="otp" class="form-control" placeholder="2FA code">
    </div>

    <p class="desc">Enter the code from the two-factor app on your mobile device. If you've lost your device, you may enter one of your recovery codes.</p>

    <div class="mt-4">
      <label for="twofaRemember">
        <input type="checkbox" name="remember" id="twofaRemember">
        <span>&nbsp;&nbsp;&nbsp;</span>
        <span class="desc">Remember this device for 30 days.</span>
      </label>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button class="btn btn-success" (click)="submit()">Verify Code</button>
  </mat-dialog-actions>
</agc-container>
