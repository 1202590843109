import { AfterViewInit, Component, Inject, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { AGCFormComponent } from "../../../components/form/form.component";
import { Agent } from "../../../interfaces/Agent";
import { Country } from "../../../interfaces/Country";
import { State } from "../../../interfaces/State";
import { AgentService } from "../../../services/AgentService";
import { MessageService } from "../../../services/MessageService";

@Component({
  selector: 'app-dialog-agents-add',
  templateUrl: 'add.dialog.html',
  styleUrls: ['add.dialog.scss']
})
export class AgentsAddDialog {

  form: FormGroup = new FormGroup({
    firstName: new FormControl(),
    lastName: new FormControl(),
    otherNames: new FormControl(),
    countryCode: new FormControl(),
    stateId: new FormControl(),
    regionId: new FormControl(),
    address1: new FormControl(),
    address2: new FormControl(),
    coords: new FormControl(),
    phone: new FormControl(),
    username: new FormControl(),
    email: new FormControl(),
    password: new FormControl(),
    meta: new FormGroup({
      companyName: new FormControl()
    })
  });

  @ViewChild(AGCFormComponent) formRef?: AGCFormComponent;

  loading: boolean = false;

  country?: Country;
  state?: State;

  constructor(
    private agentService: AgentService,
    private messageService: MessageService,
    private dialogRef: MatDialogRef<AgentsAddDialog>,
    @Inject(MAT_DIALOG_DATA) public data: Agent
  ) {
    const { form } = this;
    if (data) form.patchValue(data);
  }

  submit() {
    const { form, agentService, messageService, dialogRef } = this;

    this.loading = true;

    agentService.create(form.value).subscribe({
      next: resp => {
        dialogRef.close(resp);
      },

      error: error => {
        messageService.handle(error, false);
        this.loading = false;
      },

      complete: () => {
        this.loading = false;
      }
    });
  }

  cancel() {
    const { dialogRef } = this;
    dialogRef.close();
  }

}
