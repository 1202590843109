import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { User, UserPagination } from "../interfaces/User";
import { BehaviorSubject, Observable, Subscriber } from "rxjs";
import { AvailabilityResponse } from "../interfaces/Response";
import { AGCModuleConfig } from "../interfaces/AGCModuleConfig";
import { URLHelper } from "../helpers/URLHelper";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private xUserSub: BehaviorSubject<User>;
  private xUser: Observable<User>;

  get user() { return this.xUser; }

  constructor(
    private http: HttpClient,
    private urlHelper: URLHelper
  ) {
    this.xUserSub = new BehaviorSubject<User>(null as any);
    this.xUser = this.xUserSub.asObservable();
  }

  setValue(value: User | null) {
    this.xUserSub.next(value as User);
  }

  setUser(data: User | null) {
    this.setValue(data);
  }

  all(page: number = 1) {
    const params = new URLSearchParams();
    params.append('page', page.toString());
    const url = this.urlHelper.endpoint(`/users?${params.toString()}`);
    return this.http.get<UserPagination>(url);
  }

  find(userId: number) {
    const url = this.urlHelper.endpoint(`/users/${userId}`);
    return this.http.get<User>(url);
  }

  findBy(field: string, value: any) {
    const params = new URLSearchParams();
    params.set('field', field);
    params.set('value', value);

    const url = this.urlHelper.endpoint(`/users/find?${params.toString()}`);
    return this.http.get<User>(url);
  }

  create(data: User) {
    const url = this.urlHelper.endpoint(`/users`);
    return this.http.post<User>(url, data);
  }

  update(userId: number, data: User) {
    const url = this.urlHelper.endpoint(`/users/${userId}`);
    return this.http.patch<User>(url, data);
  }

  changePassword(userId: number, data: any) {
    const url = this.urlHelper.endpoint(`/users/${userId}/password`);
    return this.http.post<User>(url, data);
  }

  emailAvailable(email: string, ignore: string = '') {
    const data = { email, ignore };
    const url = this.urlHelper.endpoint(`/availability/email`);
    return this.http.post<AvailabilityResponse>(url, data);
  }

  phoneAvailable(phone: string, ignore: string = '') {
    const data = { phone, ignore };
    const url = this.urlHelper.endpoint(`/users/availability/phone`);
    return this.http.post<AvailabilityResponse>(url, data);
  }

  usernameAvailable(username: string, ignore: string = '') {
    const data = { username, ignore };
    const url = this.urlHelper.endpoint(`/users/availability/username`);
    return this.http.post<AvailabilityResponse>(url, data);
  }

}
