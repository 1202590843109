import { Environment } from "@core";

export const environment: Environment = {
  production: false,
  api: 'https://api-staging.agccourier.com',

  recaptcha: {
    SITE_KEY: '6Lc_mpAaAAAAALBrE7dghjThs8yr0ooZMZ7v-D-p'
  },

  paystack: {
    PUBLIC_KEY: 'pk_test_de9488c9eec8f6e947b0b263e99dba5d59d8cf42'
  },

  googleMap: {
    API_KEY: 'AIzaSyDbaDk8ATWImBSTIw7thdXuueWJDLdJptk'
  }
};
